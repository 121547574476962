import { RouteRecordRaw } from 'vue-router'

export const planningRouters: Array<RouteRecordRaw> = [
  {
    path: '/planning',
    name: 'Planning Homepage',
    component: () => import('@/views/Planning/index.vue') 
  },
  {
    path: '/scenarioManagement',
    name: 'Scenario Management',
    component: () => import('@/views/Planning/ScenarioManagement.vue') 
  },
  {
    path: '/glAndBu',
    name: 'guideline & bottom up',
    component: () => import('@/views/Planning/Gl&bu.vue') 
  },
  {
    path: '/guideline',
    name: 'guideline',
    component: () => import('@/views/Planning/Guideline.vue') 
  },
  {
    path: '/bottomUp',
    name: 'bottomUp',
    component: () => import('@/views/Planning/BottomUp.vue') 
  },
  {
    path: '/simulation/guideline',
    name: 'guidelineSimulation',
    component: () => import('@/views/Planning/GuidelineSimulation.vue') 
  },
  {
    path: '/simulation/bottomUp',
    name: 'bottomUpSimulation',
    component: () => import('@/views/Planning/BottomUpSimulation.vue') 
  }
]