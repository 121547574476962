
    import { defineComponent, reactive, onMounted, onBeforeUnmount, watch, nextTick } from 'vue';
    import { useStore } from 'vuex';
    import GC from "@grapecity/spread-sheets";
    import { handlerSheetsData, ssHotTop, BudgetAllocationTableProps } from '@/utils/spreadjs';
    import { getBudgetAllocationData, budgetExportView} from '@/API/budget'

    export default defineComponent({
        name: "budgetAllocation",
        props: {
            siOfferId: {
                type: String,
                default: ''
            },
            pgmId: {
                type: String,
                default: ''
            }
        },
        setup(props) {
            const { state } = useStore();
            //budget allocation data
            const budgetAllocationTableData = reactive<BudgetAllocationTableProps>({
                header: [],
                allocationTableCell: []
            })

            // sheet当前表格的初始化
            let workbook: GC.Spread.Sheets.Workbook;

            let worksheet: GC.Spread.Sheets.Worksheet;

            // 初始第表格
            const ssHotBotInit = () => {
                const spread = new GC.Spread.Sheets.Workbook(document.getElementById('ssHost') as HTMLElement);
                const sheet = spread.getActiveSheet();
                worksheet = sheet;
                workbook = spread;
                ssHotTop(spread, sheet, budgetAllocationTableData);
            }


            //根据offerCustomerId 获取budget分摊数据
            const fetchBudgetAllocationData = (): void => {
                getBudgetAllocationData(props.siOfferId).then(data => {
                    //用于更新budgetReport使用，不关心是否成功
                    budgetExportView(props.siOfferId).then(res => {
                        console.log('更新数据到budgetReport', res);
                    })
                    Object.assign(budgetAllocationTableData, handlerSheetsData(data as BudgetAllocationTableProps));
                    ssHotBotInit();
                })
            }

            //处理侧边栏变化后刷新spread js
            watch(() => state.sideNavCollapsed, (value) => {
                nextTick(() => {
                    workbook.refresh();
                })
            })

            onMounted(() => {
                fetchBudgetAllocationData();
            })

            onBeforeUnmount(() => {
                workbook.destroy();
            })
        }
    })
