import { fetchGet, fetchPost, fetchPut, fetch } from './index';
import { ProgramHomePageData, QueryParams, BrandResponse } from '@/views/Approve/SalesRoleApprove/ProgramHome/types';
import { ApproveProgressResponse, PackageDetailResponse } from '@/views/Approve/MgRoleApprove/PackageDetail/types';
import {
  PendingApproveResponse,
  ApproveStatusResponseItem,
  BatchTrackingPackage,
  BatchTrackingQueryParams,
  ProgramTrackingQueryParams
} from '@/views/Approve/MgRoleApprove/PackageHome/types';
import {
  BuItem,
  TypeClassItem,
  ProgramStatusItem,
  CopyResponse,
  HistoryCommentsProps
} from '@/views/Approve/SalesRoleApprove/ProgramHome/types';
import {
  BuProps,
  MakeItem,
  BrandItem,
  QueryParams as ControllingQueryParams
} from '@/views/Approve/ControllingRoleApprove/ProgramHome/types';
import {
  CreatePackageReq,
  CreatePackageRes,
  SavePackageReq,
  SavePackageRes
} from '@/views/Approve/SalesRoleApprove/CreatePackage/types';
import {
  PackageListRes,
  QueryParams as PackageQueryParams,
  BatchStatus
} from '@/views/Approve/SalesRoleApprove/PackageHome/types';
import { ReleaseBatchProps } from '@/views/Approve/SalesRoleApprove/ReleaseSetting/types';
import {
  ReleaseBatchRes,
  QueryParams as ReleaseQueryParams
} from '@/views/Approve/SalesRoleApprove/ReleasePackageHome/types';
import {
  SalesMeasureRes,
  QueryParams as SalesMeasureQueryParams,
  DetailParams,
  QueryDetailParams,
  DetailAttachmentProgram
} from '@/views/Approve/SalesMeasure/types';
// sales

//提交program
export const submitProgram = (data: { program: { id: string }[] }): Promise<any> => {
  return fetchPost('/pgapi/maintain/submit', data);
};

//拷贝
export const copyProgram = (params = {}): Promise<CopyResponse> => {
  return fetchPost('/pgapi/maintain/copy_program', params);
};

//删除
export const deleteProgram = (data: { program: { id: string }[] }): Promise<any> => {
  return fetchPost('/pgapi/maintain/delete_program', data);
};

//cancel
export const reviseProgram = (data: { programId: string; comment: string }): Promise<any> => {
  return fetchPost('/pgapi/maintain/revise_program', data);
};

//cancel
export const cancelProgram = (data: { programId: string[]; comment: string }): Promise<any> => {
  return fetchPost('/pgapi/maintain/cancel_program', data);
};

//callback
export const callbackProgram = (data: { programId: string[]; comment: string }): Promise<any> => {
  return fetchPost('/pgapi/maintain/callback_program', data);
};

// confirm
export const confirmProgram = (data = {}): Promise<any> => {
  return fetchPost('/pgapi/maintain/sipo_confirm_parameter', data);
};

// 获取offer type
export const getProgramCategory = (): Promise<any> => {
  return fetchGet('/pgapi/program/setting/get_program_type?programTypeKind=Normal');
};

export const getProgramCategoryFleet = (): Promise<any> => {
  return fetchGet('/pgapi/program/setting/get_program_type?programTypeKind=Fleet');
};
// [Sales] 获取历史提交的 Cancel、Callback 说明信息
export const getHistoryComments = (params: {
  params: { programId: string; programStatus: string };
}): Promise<HistoryCommentsProps> => {
  return fetchGet('/pgapi/maintain/get_history_comments', params);
};

export const getLog = (params: { params: { programId: string } }): Promise<string[]> => {
  return fetchGet('/pgapi/maintain/get_log', params);
};

//witchDraw program
export const witchDrawProgram = (data: { program: { id: string }[] }): Promise<any> => {
  return fetchPost('/pgapi/maintain/withdraw_program', data);
};

//获取program hone data
export const getProgramHomePageData = (params: { params: any }): Promise<ProgramHomePageData> => {
  return fetchGet('/pgapi/maintain/overview', params);
};

// 更改add 后的列表接口
export const getReleaseAddList = (params = {}): Promise<any> => {
  return fetchPost('/pgapi/maintain/available_program', params);
};

// sales create package

//打包
export const createPackage = (data: CreatePackageReq): Promise<CreatePackageRes> => {
  return fetchPost('/pgapi/maintain/create_batch', data);
};

export const savePackage = (data: SavePackageReq): Promise<SavePackageRes> => {
  return fetchPost('/pgapi/maintain/save_batch', data);
};

export const submitCreatePackage = (data: { batchId: string }): Promise<string> => {
  return fetchPost('/pgapi/maintain/submit_batch', data);
};

export const getPackageList = (params: { params: PackageQueryParams }): Promise<PackageListRes> => {
  return fetchGet('/pgapi/maintain/list_batch', params);
};
// pgapi/maintain/batch_status
export const maintainBatchStatus = (params: { params: PackageQueryParams }): Promise<PackageListRes> => {
  return fetchGet('/pgapi/maintain/batch_status', params);
};
export const releaseBatchStatus = (params: { params: PackageQueryParams }): Promise<PackageListRes> => {
  return fetchGet('/pgapi/maintain/release_batch_status', params);
};

export const getPackageDetail = (params: { params: { batchId: string } }): Promise<CreatePackageRes> => {
  return fetchGet('/pgapi/maintain/view_batch', params);
};

export const submitPackage = (data: { batchId: string }[]): Promise<{}> => {
  return fetchPost('/pgapi/maintain/submit_batchlist', data);
};

export const discardPackage = (data: { batchId: string }[]): Promise<{}> => {
  return fetchPost('/pgapi/maintain/discard_batch', data);
};

export const withdrawPackage = (data: { batchId: string }[]): Promise<boolean> => {
  return fetchPost('/pgapi/maintain/withdraw_batch', data);
};

//sales release package
export const saveReleaseBatch = (data: ReleaseBatchProps): Promise<ReleaseBatchProps> => {
  return fetchPost('/pgapi/maintain/save_release_batch', data);
};

export const sendReleaseBatch = (data: { programs: any; id: string }): Promise<BatchStatus> => {
  return fetchPost('/pgapi/maintain/send_release_batch', data);
};

// 发送邮件
export const sendReleaseEmail = (params = {}): Promise<ReleaseBatchProps[]> => {
  return fetchGet('/pgapi/program/release/sendEmail', { params });
};

export const getReleaseBatchDetail = (params: { params: { id: string } }): Promise<ReleaseBatchProps[]> => {
  return fetchGet('/pgapi/maintain/view_release_batch', params);
};

export const getReleaseBatchList = (params: { params: ReleaseQueryParams }): Promise<ReleaseBatchRes> => {
  return fetchGet('/pgapi/maintain/list_release_batch', params);
};
// sales measure
export const searchReleaseBatch = (params: { params: SalesMeasureQueryParams }): Promise<SalesMeasureRes> => {
  return fetchGet('/pgapi/sales_measure/search_release_batch', params);
};

// sales measure detail
export const searchReleaseBatchContent = (params: { params: QueryDetailParams }): Promise<DetailAttachmentProgram> => {
  return fetchGet('/pgapi/sales_measure/search_release_batch_content', params);
};

export const createReleaseBatch = (data: { programs: { programId: string }[] }): Promise<ReleaseBatchProps> => {
  return fetchPost('/pgapi/maintain/create_release_batch', data);
};

// 更换接口confirm
export const confirmReleaseBatch = (data = {}): Promise<ReleaseBatchProps> => {
  return fetchPost('/pgapi/maintain/insert_release_batch', data);
};

export const sendReleaseBatchList = (data: { id: string }[]): Promise<boolean> => {
  return fetchPost('/pgapi/maintain/send_release_ids', data);
};

export const controllingModifyStatus = (params = {}): Promise<{ programId: string; status: string }> => {
  return fetchPost('/pgapi/maintain/controlling_modify_status', params);
};

export const discardReleaseBatch = (data: { id: string }[]): Promise<boolean> => {
  return fetchPost('/pgapi/maintain/discard_release_batch', data);
};

//mg
export const getMgPendingApprove = (): Promise<PendingApproveResponse> => {
  return fetchGet('/apapi/program/get_user_pending_packages');
};

export const getMgPackageDetail = (params: { batchId: string }): Promise<PackageDetailResponse> => {
  return fetchGet('/apapi/managementBatch/getPackageDetailByBatchId', params);
};

// export const approvePackage = (data: { batchId: string; approvalSatus: string; content: string }[]): Promise<any> => {
//     return fetchPut('/apapi/approvalPackage/approvalPackage', data);
// }

export const approvePackage = (data: { batchId: string; approvalSatus: string; content: string }[]): Promise<any> => {
  return fetchPut('/apapi/approval_package/process', data);
};

export const getBatchTracking = (params: { params: BatchTrackingQueryParams }): Promise<BatchTrackingPackage[]> => {
  return fetchGet('/apapi/managementBatch/getBatchTracking', params);
};

export const getProgramTracking = (params: { params: ProgramTrackingQueryParams }): Promise<BatchTrackingPackage[]> => {
  return fetchGet('/apapi/managementBatch/getProgramTracking', params);
};

export const getCurrentUserApprovalStatus = (batchId: string): Promise<boolean> => {
  return fetchGet(`/apapi/approvalQuery/findCurrentBatchUserApprovalStatus/${batchId}`);
};

// export const getApprove

//controlling
export const controllingConfirmProgram = (data = {}): Promise<ProgramHomePageData> => {
  return fetchPost('/pgapi/maintain/controlling_confirm', data);
};

export const getControllingProgramData = (params: ControllingQueryParams): Promise<any> => {
  return fetchGet('/pgapi/maintain/controlling_overview', params);
};

// scenrio list data
export const scenrioList = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/scenrio/list', params);
};

//获取审批进度接口通用

//获取审批进度
export const getApproveProgress = (params: { params: { batchId: string } }): Promise<ApproveProgressResponse> => {
  return fetchGet('/apapi/approvalPackage/getApprovalPackageByBatchId', params);
};

// export const findApproveProgressByBu = (params: { params: { bu: string } }): Promise<ApproveProgressResponse> => {
//     return fetchGet('/apapi/approvalPackage/getApprovalPackageByBu', params);
// }

export const findApproveProgressByBu = (params: { params: { bu: string } }): Promise<ApproveProgressResponse> => {
  return fetchGet('/apapi/approval_package/getApprovalPackageByBu', params);
};

//获取审批状态
export const getApproveStatus = (params: { params: { type: string } }): Promise<ApproveStatusResponseItem[]> => {
  return fetchGet('/apapi/approval_package/batch_status_list', params);
};

//获取BU
export const getSubscribedBu = (): Promise<BuItem[]> => {
  // return fetchGet('/usapi/users/findsubscribedbu');
  return fetchGet('/usapi/users/bu/selected');
};
//获取所有一级BU
export const getAllParentBu = (): Promise<BuItem[]> => {
  return fetchGet('/usapi/noAuth/bu/parentBu');
};
//获取所有的Bu
export const getAllBu = (): Promise<BuProps[]> => {
  return fetchGet('/mdapi/bu');
};

//获取program status
export const getProgramStatus = (params = {}): Promise<ProgramStatusItem[]> => {
  return fetchGet('/pgapi/maintain/program_status_list', params);
};

//根据bu获取brand
export const getBrand = (params: {
  params: {
    bu?: string[];
    buCode?: string[];
    includeBrand?: boolean;
    display?: string;
  };
}): Promise<BrandResponse[]> => {
  Object.assign(params.params, { includeBrand: true, display: 'tree' });
  return fetchGet('/pgapi/program/setting/get_brand_by_bu', params);
};

//获取vehicle brand
export const getVehicleBand = (): Promise<BrandItem[]> => {
  return fetchGet('/vapi/get_property_lov?property=brand');
};

//获取vehicle make
export const getVehicleMake = (): Promise<MakeItem[]> => {
  return fetchGet('/vapi/get_property_lov?property=make');
};

//获取vehicle type class
export const getVehicleTypeClass = (): Promise<TypeClassItem[]> => {
  return fetchGet('/vapi/get_property_lov?property=typeClass');
};

//获取programWordling
export const getWording = (programId: string | (string | null)[] | null): Promise<Blob> => {
  return fetch({
    method: 'GET',
    url: '/pgapi/program/wording/preview_pdf',
    responseType: 'blob',
    params: {
      image: true,
      lang: 'en',
      type: 'Internal',
      programId: programId
    }
  });
};
export const getBudget2 = (programId: string | (string | null)[] | null): Promise<Blob> => {
  return fetch({
    method: 'GET',
    url: '/bgapi/budget/exportapproval/v2/' + programId
  });
};
export const getBudget = (programId: string | (string | null)[] | null): Promise<Blob> => {
  return fetch({
    method: 'GET',
    url: '/bgapi/budget/exportapproval/' + programId,
    responseType: 'blob'
    // data: {
    //     'programPeriod': 'programPeriod',
    //     programInfoId: programId,
    // }
  });
};
// pdf preview
export const pdfPreview = (batchId: string): Promise<Blob> => {
  return fetch({
    method: 'GET',
    url: `/pgapi/program/wording/preview_release_batch_pdf`,
    responseType: 'blob',
    params: {
      batchId: batchId
    }
  });
};

//获取收藏条件
export const getSearchValue = (params: { type: string }): Promise<string[]> => {
  return fetchGet('/pgapi/maintain/list_program_collection', { params });
};

//设置搜索条件
export const setSearchValue = (data: { selJson: string; type: string }): Promise<any> => {
  return fetchPost('/pgapi/maintain/save_program_collection', data);
};
export const deleteProgramCollection = (data = {}): Promise<any> => {
  return fetchPost('/pgapi/maintain/delete_program_collection', data);
};

// fa 审批
export const getBatchList = (type: string): Promise<any[]> => {
  return fetchGet('/apapi/approvalQuery/findFinancingBatchList/' + type);
};

// findComments
export const findComments = (params = {}): Promise<{ type: string; comment: string }[]> => {
  return fetchGet('/pgapi/program_approval/findComments', params);
};

// setComments
export const setComments = (params = {}): Promise<{ type: string; comment: string }[]> => {
  return fetchPost('/pgapi/program_approval/setComments', params);
};
export const findFinancingBatchList = (params = {}): Promise<any[]> => {
  return fetchGet(`/apapi/approvalQuery/findFinancingBatchList/OASIS`, params);
};

// 根据bu获取审批list
export const findApprovalListBybu = (params = {}): Promise<string[]> => {
  return fetchGet(`/apapi/program/search_program_approval_chain`, params);
};
// 根据bu获取审批list
export const getApprovalChainPath = (params = {}): Promise<any> => {
  return fetchGet(`/apapi/program/get_approval_chain_path`, params);
};

// bu brand make typeclas 联动接口
export const findBuVehicleList = (params = {}): Promise<any[]> => {
  return fetchGet(`/pgapi/maintain/search_bu_vehicle`, { params });
};

// release 接口部分
import {
  OrganizationProxy,
  EntityProxy,
  UserProxy,
  RegionProxy
} from '@/views/Approve/SalesRoleApprove/ReleaseSetting/Release/types';
// 根据entityType查询所有的entity
export const getentityAll = (): Promise<EntityProxy[]> => {
  return fetchGet(`/pgapi/program/release/getAllLegalEntity`);
};

// 获取region
export const getRegionList = (params = {}): Promise<RegionProxy[]> => {
  return fetchGet(`pgapi/program/release/getRegion`, { params });
};

// 获取user list
export const getUserList = (): Promise<UserProxy> => {
  return fetchGet('/usapi/users/findall?pageIndex=0&pageSize=10000');
};

// 获取batch 历史
export const geAvailBatchList = (pathParams: string, params = {}): Promise<UserProxy> => {
  return fetchGet('/pgapi/program/release/' + pathParams, { params });
};

// 获取列表
export const getReleaseData = (pathParams: string, params = {}): Promise<EntityProxy[]> => {
  return fetchGet(`/pgapi/program/release/` + pathParams, { params });
};

// 根据parentId 查询 Entity
export const findEntityByids = (id: number | string): Promise<EntityProxy[]> => {
  return fetchGet(`/usapi/entity/children/${id}?entityType=organization`);
};

// 添加组
export const releaseAddGroup = (addPath: string, params = {}): Promise<EntityProxy[]> => {
  return fetchPost(`/pgapi/program/release/${addPath}ternalAddGroup`, params);
};

// 添加user
export const releaseAddUser = (addPath: string, params = {}): Promise<EntityProxy[]> => {
  return fetchPost(`/pgapi/program/release/${addPath}ternalAddUser`, params);
};

// copy batch list
export const releaseAddList = (addPath: string, params = {}): Promise<EntityProxy[]> => {
  return fetchPost(`/pgapi/program/release/${addPath}ternalAddList`, params);
};

// 删除
export const releaseDeleteById = (deletePath: string, params = {}): Promise<EntityProxy[]> => {
  return fetchGet('/pgapi/program/release/' + deletePath, { params });
};

// 18948
export const deleteSml = (params = {}): Promise<any> => {
  return fetchGet('/pgapi/program/release/deleteSml', { params });
};

export const viewSml = (params = {}): Promise<any> => {
  return fetchGet('/pgapi/program/release/viewSml', { params });
};

export const generateSml = (params = {}): Promise<any> => {
  return fetchGet('/pgapi/program/release/generateSml', { params });
};

export const isWordingUploadAllBatch = (params = {}): Promise<any> => {
  return fetchPost('/pgapi/program/wording/wording_is_upload_all', params);
};

export const searchCombinability = (params = {}): Promise<any> => {
  return fetchPost('/pgapi/program/combinability/export_combinability', params);
};

export const compareCombinability = (params = {}): Promise<any> => {
  return fetch({
    method: 'GET',
    url: '/pgapi/program/combinability/compare_combinability',
    params: params
  });
};

export const filterCombProgram = (params = {}): Promise<any> => {
  return fetchGet('/pgapi/program/combinability/filter_comb_program', { params });
};

export const searchCombinabilityDealer = (params = {}): Promise<any> => {
  return fetchPost('/pgapi/program/combinability/dealer/export_combinability', params);
};

export const compareCombinabilityDealer = (params = {}): Promise<any> => {
  return fetch({
    method: 'GET',
    url: '/pgapi/program/combinability/dealer/compare_combinability',
    params: params
  });
};

export const filterCombProgramDealer = (params = {}): Promise<any> => {
  return fetchGet('/pgapi/program/combinability/dealer/filter_comb_program', { params });
};

export const sendApprovalRemindNoticeByBatchId = (batchId: string): Promise<any> => {
  return fetchGet('/apapi/approvalPackage/sendApprovalRemindNoticeByBatchId', { params: { batchId } });
};