import { RouteRecordRaw } from 'vue-router';
export const delayCallbackRouters: Array<RouteRecordRaw> = [
  {
    path: '/delayCallback',
    name: 'Delay Callback',
    component: () =>
      import('@/views/DelayCallback/Retail/index.vue'),
  },
  {
    path: '/delayCallback/overdueSearchDeclaration',
    name: 'Overdue Search Declaration',
    component: () =>
      import('@/views/DelayCallback/Retail/overdueSearchDeclaration.vue'),
  },
  {
    path: '/delayCallback/dealerDelayApply',
    name: 'Dealer Delay Apply',
    component: () =>
        import('@/views/DelayCallback/Dealer/dealerDelayApply.vue')
  },
  {
    path: '/delayCallback/regionCheckRetail',
    name: 'Region Check Retail',
    component: () =>
        import('@/views/DelayCallback/Region/checkRetail.vue')
  },
  {
    path: '/delayCallback/regionCheckRetailDetail',
    name: 'Region Check Retail Detail',
    component: () =>
        import('@/views/DelayCallback/Region/checkRetailDetail.vue')
  },
  {
    path: '/delayCallback/SIPOCheckRetail',
    name: 'SIPO Check Retail',
    component: () =>
        import('@/views/DelayCallback/SIPO/checkRetail.vue')
  },
  {
    path: '/delayCallback/SIPOCheckRetailDetail',
    name: 'SIPO Check Retail Detail',
    component: () =>
        import('@/views/DelayCallback/SIPO/checkRetailDetail.vue')
  }
];
