import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "navbar" }
const _hoisted_2 = { id: "mian" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navArr, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "navtag",
            key: item.id
          }, [
            _createVNode(_component_router_link, {
              to: item.path
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title) + " ", 1),
                _createElementVNode("i", {
                  class: _normalizeClass(["font_family tagicon", item.icon])
                }, null, 2)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ])
    ])
  ]))
}