
import { defineComponent, PropType } from 'vue'
// import { useRoute } from 'vue-router'
import { NavbarProps } from './types'
export default defineComponent({
    props: {
      navArr: {
        default: () => [],
        type: Array as PropType<NavbarProps[]>
      }
    },
    name: '',
    setup () {
      return {

      }
    }
 });
