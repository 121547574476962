import { RouteRecordRaw } from 'vue-router'

export const forecastRouters: Array<RouteRecordRaw> = [
  {
    path: '/forecast',
    name: 'Forecast Homepage',
    component: () => import('@/views/Forecast/index.vue')
  },
  {
    path: '/report',
    name: 'Report Structure',
    component: () => import('@/views/Report/index.vue')
  },
  {
    path: '/setforecastpmt',
    name: 'Set PMT Forecast Rule',
    component: () => import('@/views/Forecast/Configuration/SetPMT.vue')
  },
]
