import { onUnmounted } from 'vue'
import mitt from 'mitt';

const emitter: mitt.Emitter = mitt();

// 自定义触发器
const customEmit = (eventName: any, arg?: any) => {
    emitter.emit(eventName, arg)
}

// 自定义接收器
const customOn = (eventName: any, arg?: any) => {
    emitter.on(eventName, arg)
}

const useEventbus = () => {
    onUnmounted(() => {
        emitter.all.clear()
    })
    return {
        customEmit,
        customOn
    }
}
export default useEventbus
