import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font_family icon-shouqi f-r",
  "data-name": "toggle"
}
const _hoisted_2 = {
  key: 1,
  class: "font_family icon-zhankai f-r",
  "data-name": "toggle"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "cursor-p" }
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "cursor-p" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "side-nav-left",
    style: _normalizeStyle({
      'min-width': _ctx.collapsed ? '50px' : '260px',
      background: `url(${require('@/assets/images/side-nav-bg.jpg')})`,
    })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({ 'side-fixed': _ctx.isFixed }),
      style: _normalizeStyle({ width: _ctx.collapsed ? '50px' : '260px' })
    }, [
      _createVNode(_component_a_button, {
        ghost: "",
        onClick: _ctx.toggleCollapsed,
        style: {"margin-bottom":"16px","border":"0"}
      }, {
        default: _withCtx(() => [
          (_ctx.collapsed)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2))
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_menu, {
        openKeys: _ctx.openKeys,
        selectedKeys: _ctx.selectedKeys,
        "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedKeys) = $event)),
        mode: "inline",
        theme: "dark",
        "inline-collapsed": _ctx.collapsed,
        onOpenChange: _ctx.onOpenChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.user.menulist, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.mnuid
            }, [
              (item.children.length === 0)
                ? (_openBlock(), _createBlock(_component_a_menu_item, {
                    key: item.mnuid,
                    class: "level-one-menu"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: item.mnuuri
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("i", {
                            class: _normalizeClass(["font_family cursor-p", item.icon]),
                            style: _normalizeStyle(
                    _ctx.collapsed
                      ? 'margin-right: 34px;font-size: 20px'
                      : 'margin-right:10px'
                  )
                          }, null, 6),
                          _createElementVNode("span", {
                            class: "p-r-30 cursor-p",
                            title: _ctx.$i18n.locale === 'en' ? item.mnunmen : item.mnunmcn
                          }, _toDisplayString(_ctx.$i18n.locale === "en" ? item.mnunmen : item.mnunmcn), 9, _hoisted_3)
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: item.mnuid
                  }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", null, [
                        _createElementVNode("i", {
                          class: _normalizeClass(["font_family cursor-p", item.icon]),
                          style: _normalizeStyle(
                      _ctx.collapsed ? 'margin-right: 34px;' : 'margin-right:10px'
                    )
                        }, null, 6),
                        _createElementVNode("span", {
                          class: "p-r-30 cursor-p",
                          title: _ctx.$i18n.locale === 'en' ? item.mnunmen : item.mnunmcn
                        }, _toDisplayString(_ctx.$i18n.locale === "en" ? item.mnunmen : item.mnunmcn), 9, _hoisted_4)
                      ])
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (childrenNav) => {
                        return (_openBlock(), _createBlock(_component_router_link, {
                          to: childrenNav.mnuuri === '#' ? '' : childrenNav.mnuuri,
                          key: childrenNav.mnuid
                        }, {
                          default: _withCtx(() => [
                            (!childrenNav.children.length)
                              ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                  key: childrenNav.mnuid,
                                  title: 
                      _ctx.$i18n.locale === 'en'
                        ? childrenNav.mnunmen
                        : childrenNav.mnunmcn
                    
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$i18n.locale === "en"
                        ? childrenNav.mnunmen
                        : childrenNav.mnunmcn), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["title"]))
                              : (_openBlock(), _createBlock(_component_a_sub_menu, {
                                  key: childrenNav.mnuid
                                }, {
                                  title: _withCtx(() => [
                                    _createElementVNode("span", null, [
                                      _createElementVNode("span", {
                                        class: "p-r-30 cursor-p",
                                        title: 
                            _ctx.$i18n.locale === 'en'
                              ? childrenNav.mnunmen
                              : childrenNav.mnunmcn
                          
                                      }, _toDisplayString(_ctx.$i18n.locale === "en"
                              ? childrenNav.mnunmen
                              : childrenNav.mnunmcn), 9, _hoisted_6)
                                    ])
                                  ]),
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(childrenNav.children, (thirdChildNav) => {
                                      return (_openBlock(), _createBlock(_component_router_link, {
                                        to: thirdChildNav.mnuuri,
                                        key: thirdChildNav.mnuid
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(), _createBlock(_component_a_menu_item, {
                                            key: thirdChildNav.mnuid,
                                            title: 
                          _ctx.$i18n.locale === 'en'
                            ? thirdChildNav.mnunmen
                            : thirdChildNav.mnunmcn
                        
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$i18n.locale === "en"
                            ? thirdChildNav.mnunmen
                            : thirdChildNav.mnunmcn), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["title"]))
                                        ]),
                                        _: 2
                                      }, 1032, ["to"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1024))
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024))
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["openKeys", "selectedKeys", "inline-collapsed", "onOpenChange"])
    ], 6)
  ], 4))
}