import axios from 'axios'
import { AxiosRequestConfig } from '@/API/types'
export default (options: AxiosRequestConfig) => { 
    const { url, method = 'get', params, fileName: resFileName } = options;
    const config: any = {
        url: url,
        method,
        responseType: "blob",
    };
    if (method === "get") {
        config["params"] = params;
    } else {
        config["data"] = params;
    }
    return new Promise((resolve, reject) => {
        axios(config).then((res: any) => {
            if (res.headers['content-disposition']) {
                const contentDisposition = res.headers['content-disposition'].replace(/"/g, '')
                const suffixIndex = contentDisposition.indexOf('.') + 1
                let type = ''
                const suffix = contentDisposition.substr(suffixIndex)
                switch (suffix) {
                    case 'xlsx':
                        type = 'application/vnd-excel; char-set=UTF-8'
                        break
                    case 'zip':
                        type = 'application/zip; char-set=UTF-8'
                        break
                    case 'pdf':
                        type = 'application/pdf; char-set=UTF-8'
                        break
                    // case 'doc':
                    //     type = 'application/msword; char-set=UTF-8'
                    //     break
                }
                const fileNameIndex = contentDisposition.indexOf('=') + 1
                let fileName = ''
                if (resFileName) {
                    fileName = resFileName + '.' + suffix
                } else {
                    fileName = decodeURIComponent(contentDisposition.substr(fileNameIndex))
                }
                // 文件格式
                const blob = new Blob([res.data], { type })
                const downloadElement = document.createElement('a')
                const href = window.URL.createObjectURL(blob)
                downloadElement.href = href
                // 下载后文件名
                downloadElement.download = !fileName ? Date.now().toString() : fileName
                document.body.appendChild(downloadElement)
                downloadElement.click() 
                document.body.removeChild(downloadElement)
                // 释放掉blob对象
                window.URL.revokeObjectURL(href)
                resolve(res)
            } else {
                if (
                    res.data instanceof Blob &&
                    res.data.type === 'application/json'
                ) {
                    res.data.text().then((res: any) => {
                        reject(JSON.parse(res).message)
                    })
                } else {
                    reject('No File!')
                }
                
            }
        }).catch(function (error: string) {
            console.log(error)
            return reject(error)
        })
    })
}

export function downloadFromStream(response: any) {
  const matches = response.headers['content-disposition'].replace(/"/g, '').match(/filename=([^;]+)/)
  const filename = matches.length === 2 ? decodeURIComponent(decodeURIComponent(matches[1])) : 'download'

  const blob = new Blob([response.data])
  const downloadElement = document.createElement('a')
  const href = window.URL.createObjectURL(blob)
  downloadElement.href = href
  downloadElement.download = filename
  downloadElement.click() 
  window.URL.revokeObjectURL(href)
}

export function downloadFromUrl(url: string): void {
  const downloadElement = document.createElement('a')
  downloadElement.href = url
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
}

export function downloadFromUrls(urls: string[]): void {
  const iframesSet = new Set<HTMLIFrameElement>()
  urls.forEach(url => {
    const downloadElement = document.createElement('iframe')
    downloadElement.src = url
    downloadElement.style.display = 'none'
    document.body.appendChild(downloadElement)
    iframesSet.add(downloadElement)
  })

  // 下载完成后清除iframe
  if (iframesSet) {
    const timer = setInterval(() => {
      for (const iframe of iframesSet) {
        if (iframe.contentDocument?.readyState === 'complete') {
          iframesSet.delete(iframe)
          document.body.removeChild(iframe)
        }
      }
      if (iframesSet.size === 0) {
        clearInterval(timer)
      }
    }, 10000)
  }
}