/*
 * @Author: zhengda.lv
 * @Date: 2021-12-23 10:22:27
 * @LastEditTime: 2021-12-23 14:52:51
 * @LastEditors: zhengda.lv
 * @Description:
 */
import { Module } from 'vuex';

const salesReport: Module<any, any> = {
    namespaced: true,
    state: {
        fcSearchParams: {
            make: '',
            startYear: '',
            closeOrNot: '',
            yearList: [],
        },
        possibleReleaseRowData: [],
    },
    mutations: {
        setFCSearchParams(state, params) {
            for (const key in state.fcSearchParams) {
                state.fcSearchParams[key] = params[key];
            }
            console.log(state.fcSearchParams);
        },
        setPossibleReleaseRowData(state, rowData) {
            state.possibleReleaseRowData = rowData;
            console.log('store', rowData);
        },
    },
};

export default salesReport;
