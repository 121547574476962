import { Module } from 'vuex';
import { GlobalDataProps } from '@/store/index';
import type {
  ThirdPartyDataError,
  CurveSelectionUploadError
} from '@/views/LocalRegistration/type'

export interface LocalRegistrationDataProps {
  thirdPartyDataError: ThirdPartyDataError[];
  curveSelectionUploadError: CurveSelectionUploadError[];
}

export default {
  namespaced: true,
  state: {
    thirdPartyDataError: [],
    curveSelectionUploadError: []
  },
  mutations: {
    updateThirdPartyDataError: (state, data: ThirdPartyDataError[]) => {
      state.thirdPartyDataError = data
    },
    updateCurveSelectionUploadError: (state, data: CurveSelectionUploadError[]) => {
      state.curveSelectionUploadError = data
    }
  }
} as Module<LocalRegistrationDataProps, GlobalDataProps>