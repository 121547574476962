import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/fulfill.svg'
import _imports_1 from '@/assets/images/pending.svg'
import _imports_2 from '@/assets/images/reject.svg'


const _hoisted_1 = { class: "navbar" }
const _hoisted_2 = { id: "mian" }
const _hoisted_3 = { style: {"font-size":"16px","font-weight":"600"} }
const _hoisted_4 = {
  key: 0,
  class: "icon-img"
}
const _hoisted_5 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_6 = {
  key: 1,
  src: _imports_1,
  alt: ""
}
const _hoisted_7 = {
  key: 2,
  src: _imports_2,
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_badge = _resolveComponent("a-badge")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navArr, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "navtag",
            key: item.id
          }, [
            _createVNode(_component_a_badge, {
              count: item.count
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: item.query ? { path: item.path, query: { pgmId: item.query.pgmId } } : item.path,
                  onClick: ($event: any) => (_ctx.routeSelect(item.id)),
                  class: _normalizeClass(item.disabled ? 'a-disabled' : '')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(item.title), 1),
                    (!item.disabled)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                          _createVNode(_component_a_tooltip, null, {
                            title: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.toolTipContent), 1)
                            ]),
                            default: _withCtx(() => [
                              (item.status === 'fulfill')
                                ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                                : (item.status === 'pending')
                                  ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                                  : (_openBlock(), _createElementBlock("img", _hoisted_7))
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["to", "onClick", "class"])
              ]),
              _: 2
            }, 1032, ["count"])
          ]))
        }), 128))
      ])
    ])
  ]))
}