
import { defineComponent, ref, h, PropType } from "vue";
import { Modal, message } from "ant-design-vue";
import { VolumeBase } from "./types";
import { inputsetPeriod, inputsetVehicleView, inputsetCostBase } from "../data";

const volumeBase: VolumeBase[] = [{ value: "WS" }, { value: "RT" }];

export default defineComponent({
  props: {
    visible: {
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },
  emits: ["update:visible", "ok"],
  setup(props, context) {
    const inputPeriod = ref("");
    const inputVehicleView = ref("");
    const inputVolumeBase = ref("");
    const inputCostBase = ref("");
    // 点确定时
    const handleOk = () => {
      if (inputVolumeBase.value == "") {
        message.error("请选择Volume Base");
        return;
      }
      Modal.confirm({
        class: "my-confirm",
        content: h("div", {}, [
          h(
            "i",
            {
              class: "font_family icon-old-Warning",
              style: "font-size: 18px;",
            },
            ""
          ),
          h(
            "h1",
            { style: "font-size: 18px;" },
            "Change  Input Set will clear existing data!Confirm to change?"
          ),
        ]),
        onOk() {
          return new Promise<void>((resolve, reject) => {
            context.emit("ok", inputVolumeBase.value);
            setTimeout(() => {
              context.emit("update:visible", false);
            }, 500);
            resolve();
          }).catch((e) => console.log(e));
        },
        onCancel() {
          console.log("cancel");
        },
      });
    };
    return {
      handleOk,

      inputPeriod,
      inputVehicleView,
      inputVolumeBase,
      inputCostBase,

      inputsetPeriod,
      inputsetVehicleView,
      volumeBase,
      inputsetCostBase,
    };
  },
});
