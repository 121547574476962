
import { defineComponent, ref, reactive, onMounted, nextTick, toRefs, } from 'vue'
import { overviewProps, independentProps, budgetTemplateProps } from './types'
import { SelectProps, ValidProps } from '@/API/types'
import {  manualArr, } from './data'
import { useRoute } from 'vue-router'
import { useStore } from "vuex";
import { BudgettpFindall, OverviewFindall, Savebudgettp, AddbudgettpAttr, CheckExisted } from '@/API/budget'
import deepClone from '@/utils/deepClone'
import useEventbus from "@/hooks/claim/useEventbus"
import { message, Modal } from 'ant-design-vue'
export default defineComponent({
    setup () {
        const route = useRoute()
        const store = useStore()
        const checkDatas = ref<budgetTemplateProps[]>([])
        const volumeBase = ref<string[]>([])
        const offerTypeId = route.params.id 
        // 更改value值
        const changeField = (arr: budgetTemplateProps[],type: string): budgetTemplateProps[] => {
            const updatedArr = deepClone(arr)
            updatedArr.forEach(item => {
                item.attribute.forEach(attr => {
                    if (attr.exclusive) {
                        attr.exclusive.forEach(exclusvie => {
                            if ('manual' in exclusvie) {
                                if (type === 'intoStr') {
                                    if (exclusvie.manual) {
                                        exclusvie.manual = 'Manual'
                                    } else {
                                        exclusvie.manual = 'Auto'
                                    }
                                } else {
                                    if (exclusvie.manual === 'Manual') {
                                        exclusvie.manual = true
                                    } else {
                                        exclusvie.manual = false
                                    }
                                }
                            }  
                        })
                    }
                    if (attr.independent) {
                        if (type === 'intoStr') {
                            if (attr.independent.manual) {
                                attr.independent.manual = 'Manual'
                            } else {
                                attr.independent.manual = 'Auto'
                            }
                        } else {
                            if (attr.independent.manual === 'Manual') {
                                attr.independent.manual = true
                            } else {
                                attr.independent.manual = false
                            }
                        }
                    }
                })
            })
            return updatedArr
        }
        
        // 判断是否可以save (两个单选必须选择)
        const judgeSave = (): boolean => {
            let vbaseBool = false
            let cbaseBool = false
            checkDatas.value.forEach(item => {
                 item.attribute.forEach(attr => {
                      if (attr.exclusive) {
                          attr.exclusive.forEach(exclusvie => {
                              if (typeof exclusvie.manual === 'undefined') {
                                  if (exclusvie.selected) {
                                      vbaseBool = true
                                  }
                              } else {
                                  if (exclusvie.selected) {
                                      cbaseBool = true
                                  }
                              }
                          })
                      }
                 })
            })
            if (vbaseBool && cbaseBool) {
                return true
            } else {
                return false
            }
        }
        
        // 初始化数据
        const initTableData = () => {
            BudgettpFindall(offerTypeId).then((res) => {
                // 表示是否维护过
                store.commit('updateMaintain', res.needMaintain)
                checkDatas.value = changeField(res.budgetTemplate, 'intoStr') 
            })
        }
        // 自定义规则
        const judgeType = async(value: string): Promise<boolean> => {  
            const params = {
                name: value,
                type: 'ATTRIBUTE-NAME'
            }
            const res = await CheckExisted(params)
            return res
        }
        const validateAsync = async(rule: ValidProps, value: string) => {
            if (!value || value.split(' ').join('').length === 0) {
                return Promise.reject('Please input name')
            }
            if ((value && value.length > 100)) {
                return Promise.reject('Length should be 1 to 100')
            }
            
            if (value) {
                const res = await judgeType(value)
                if (res) {
                    return Promise.reject('The Attribute Name already exists')
                }
            }
            return Promise.resolve('success')

        }
        // 定义form
        const formData = reactive({
            labelCol: { span: 8 },
            wrapperCol: { span: 14 },
            form: {
                offerType: [],
                offerTypeId: '',
                attribute: '',
                categoryId: ''
            },
            rules: {
                attribute: [
                    {
                        required: true,
                        validator: validateAsync,
                        trigger: 'blur'
                    },
                ]
            }
        })
        // 获取form元素
        const getFormRef = ref()
        const formRef = (el: HTMLElement | null) => {
            nextTick(() => {
                getFormRef.value = el
            })
        }

        // 快速setting弹窗
        const quickVisble = ref(false)
        const selectQuickData = ref('')
        const offerTypeData = ref<overviewProps[]>([])
        // 展示快速设置弹窗
        const showQuickVisble = () => {
            quickVisble.value = true
            if (offerTypeData.value.length < 1) {
                OverviewFindall().then((res) => {
                    offerTypeData.value = res
                })
            }
            // getFormRef.value.resetFields();
     
        }
        // 下拉选择offer type触发的事件 获取offer type Id
        const changeQuick = (value: string, option: SelectProps) => {
            formData.form.offerTypeId = option.key
        }
        // 确定快速设置的弹窗
        const showConfirm = () => {
            Modal.confirm({
                title: `Task ${formData.form.offerType} settings as reference`,
                centered: true,
                // content: createVNode('div', { style: 'color:red;' }, 'Some descriptions'),
                cancelText: 'No',
                okText: 'Yes',
                onOk() {
                    BudgettpFindall(formData.form.offerTypeId).then((res) => {
                        checkDatas.value = changeField(res.budgetTemplate,'intoStr') 
                    })
                    quickVisble.value = false
                    formData.form.offerType = []
                    
                },
                onCancel() {
                    console.log('Cancel');
                },
                class: 'test',
            });
        }
        // 选中offer type 确定导入设置
        const createQuick = () => {
            if (formData.form.offerTypeId) {
                showConfirm()
            } else {
                message.error("Please select an Offer Type!")
            }
        }
        // 取消导入 offer type设置
        const cancelQuick = () => {
            quickVisble.value = false
            formData.form.offerType = []
            
        }

        // 静态插入属性
        const getAttributeArr = (id: string, arrValue: independentProps) => {
            checkDatas.value.forEach(item => {
                if (item.id === id) {
                    const attr = {
                        independent: arrValue
                    }
                    item.attribute.push(attr)
                }
            })
        } 
        // 添加属性的弹窗
        const visbleAttr = ref(false)
        const handleAddAttr = (msg: budgetTemplateProps, ) => {
            formData.form.categoryId = msg.id
            visbleAttr.value = true
        }
        // 确定添加属性
        const createAttr = () => {
            getFormRef.value.validate().then(() => {
                visbleAttr.value = false
                const params = [{
                    categoryId: formData.form.categoryId,
                    name: formData.form.attribute,
                }]
                let arrValue: independentProps
                AddbudgettpAttr(params, offerTypeId).then((res) => {
                    if (res[0].attribute[0].independent.manual) {
                        res[0].attribute[0].independent.manual = 'Manual'
                    } else {
                        res[0].attribute[0].independent.manual = 'Auto'
                    }
                    arrValue = res[0].attribute[0].independent
                    getAttributeArr(formData.form.categoryId, arrValue)
                    message.success(`${formData.form.attribute} has been created!`)
                    getFormRef.value.resetFields();
                })
                
            })
        }
        // 取消添加属性
        const cancelAttr = () => {
            visbleAttr.value = false
            getFormRef.value.resetFields();
        }

        // 得到单选的数据
        const radioData = (parentId: string,id: string) => {
            for (const item of checkDatas.value) {
                item.attribute.forEach((attr) => {
                    if (attr.exclusive) {
                        attr.exclusive.map((exclus) => {
                            if (item.id === parentId) {
                                exclus.selected = false
                                if (exclus.id === id) {
                                    exclus.selected = true
                                }
                            }
                        })  
                    }
                })
            }
        }
        const onRadio = (parentId: string,id: string) => {
            radioData(parentId,id)
        }
        const { customEmit } = useEventbus()
        
        // save offer 
        const saveOffer = async() => {
            if (judgeSave()){
                const params = changeField(checkDatas.value, 'intoBool')
                await Savebudgettp(params, offerTypeId)
                initTableData()
                customEmit("updatelayout")
            } else {
                message.error("Please select a single option!")
                return false
            }
            
        }

        onMounted(() => {
            initTableData()
        })
        return {
            onRadio,
            volumeBase,
            checkDatas,
            ...toRefs(formData),
            formRef,
            manualArr,
            quickVisble,
            offerTypeData,
            showQuickVisble,
            changeQuick,
            createQuick,
            cancelQuick,
            visbleAttr,
            handleAddAttr,
            createAttr,
            cancelAttr,
            selectQuickData,
            saveOffer,
        }
    }
})
