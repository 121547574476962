import GC from "@grapecity/spread-sheets";
import { message } from "ant-design-vue";

export interface BudgetTableDataCell {
    id: string;
    budgBudgetHeaderId: string;
    budgBudgetRowId: string;
    contents: string;
    formula: string;
    columnNum: number;
    budgBudgetRownum: number | string;
    edited: boolean; //用户更改后需回传给后端计算
    type: string;  //'TYPECLASSSUMMARY' ||  MODELYEARSUMMARY 汇总列且不可编辑  READ 只读  WRITE可编辑
    visible?: boolean; //控制是否显示当前contens内容
    contentsType?: string | undefined; //显示的是百分比还是正数，总共有text  numeric  percent
    trigger?: number[]; //互斥的索引
    align?: string; //对齐方式
    width?: number; //固定宽度
}

export interface AllocationTableDataCell {
    id: string;
    allocationHeaderId: string;
    allocationRowId: string;
    contents: string;
    type: string;
    allocationRownum: number;
    allocationColumnNum: number;
    edited?: boolean; //用户更改后需回传给后端计算
    visible?: boolean; //控制是否显示当前contens内容
    contentsType?: string | undefined; //显示的是百分比还是正数，总共有text  numeric  percent
    trigger?: number[]; //互斥的索引
    align?: string; //对齐方式
    width?: number; //固定宽度
}
export interface BudgetTableHeaderCell {
    id: string;
    budgBudgetId: string;
    name:  string;
    formula: null | string;
    columnNumber: number;
}

export interface BudgetTableProps {
    header: BudgetTableHeaderCell[];
    inputTableCell: BudgetTableDataCell[][];
}


export interface BudgetAllocationTableProps {
    header: BudgetTableHeaderCell[];
    allocationTableCell: AllocationTableDataCell[][];
}

//type  TYPECLASSSUMMARY   MODELYEARSUMMARY 是汇总  READ 是只读
//判断是否是可编辑cell
export const isEditCell = (type: string): boolean => {
    if (type === 'TYPECLASSSUMMARY' || type === 'MODELYEARSUMMARY') {
        return false;
    } else if (type === 'READ') {
        return false;
    } else {
        return true;
    }
}

//判断是否是汇总cell
export const isSummaryCell = (type: string): boolean => {
    if (type === 'TYPECLASSSUMMARY' || type === 'MODELYEARSUMMARY') {
        return true;
    } else {
        return false;
    }
}

// 绑定数据 locked true 表示锁定
export const bindDataTable = ( sheet: GC.Spread.Sheets.Worksheet, data: BudgetTableProps | BudgetAllocationTableProps): void => {
    sheet.suspendPaint();
    const cellData = 'inputTableCell' in data ? data.inputTableCell :  data.allocationTableCell;
    const isAllocation = 'allocationTableCell' in data;
    for (let row = 0; row < cellData.length; row++) {
        //设置row高度
        sheet.setRowHeight(row, 40, GC.Spread.Sheets.SheetArea.viewport);
        for (let col = 0; col < cellData[row].length; col++) {
            //判断是否是budget input 如果是budget input 需要根据字段来确认是否表格锁定
            if (isAllocation) {
                sheet.getCell(row, col).locked(!isEditCell(cellData[row][col].type));
            }

            //设置对齐方式
            if (cellData[row][col].align) {
                sheet.getCell(row, col).hAlign(GC.Spread.Sheets.HorizontalAlign.left);
            }

            //判断是否是汇总列
            if (isSummaryCell(cellData[row][col].type)) {
                sheet.getCell(row, col).backColor('#B0CEEA');
            } else {
                sheet.getCell(row, col).backColor('#EFEFEF');
            }

            //不是分摊我&&可编辑的cell添加不同的颜色， 分摊默认都是#EFEFEF
            if (isEditCell(cellData[row][col].type) && !isAllocation) {
                sheet.getCell(row, col).backColor('#FFFFFF');
            }

            //自动换行
            sheet.getCell(row, col).wordWrap(true);

            //设置有宽度的cell，没有默认'2*'
            sheet.setColumnWidth(col, cellData[row][col].width || '2*', GC.Spread.Sheets.SheetArea.viewport);

            //根据显示类型格式化
            if (cellData[row][col].contentsType === 'percent') {
                sheet.setValue(row, col, cellData[row][col].visible !== false ? cellData[row][col].contents : '');
                //     // 格式化成百分比
                sheet.setFormatter(row, col, "0%", GC.Spread.Sheets.SheetArea.viewport);
                //判断AVG SI% 需要序列化成0.00%
            } else if (cellData[row][col].contentsType === "percentDecimal") {
                sheet.setValue(row, col, cellData[row][col].visible !== false ? cellData[row][col].contents : '');
                sheet.setFormatter(row, col, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
            } else {
                // 格式化成千分符
                sheet.setValue(row, col, cellData[row][col].visible !== false ? cellData[row][col].contents : '')
                //判断col是第一个不序列化为千分符  第一位是日期，如果序列化会有显示问题， 234  是文字序列化后没有显示问题
                col > 0 && sheet.setFormatter(row, col, "#,##0", GC.Spread.Sheets.SheetArea.viewport);
            }
        }
    }
    sheet.resumePaint();
}


export const inputEventToFormula = (sheet: GC.Spread.Sheets.Worksheet, data: BudgetTableProps | BudgetAllocationTableProps): void => {
    sheet.bind(GC.Spread.Sheets.Events.ValueChanged, (e: any, info: any) => {
        // info  包含当前变的cell的位置信息 row（行）  和 col（列）  根据这个就可以找到对应的这条数据;
        //判断输入的类型是percent，则输入的值不能大于1
        const inputCellData = 'inputTableCell' in data ? data.inputTableCell : data.allocationTableCell;
        if (inputCellData[info.row][info.col].contentsType === 'percent' && info.newValue > 1) {
            message.error('请输入正确的数值');
            sheet.setValue(info.row, info.col, info.oldValue);
            // sheet.setFormatter(info.row, info.col, "0%", GC.Spread.Sheets.SheetArea.viewport);
            sheet.suspendEvent();
            sheet.resumeEvent();
            return;
        }
        //当前的row
        const row = inputCellData[info.row];
        row[info.col].contents = info.newValue;
        row[info.col].edited = true;
        //判断如果触发的是禁用cell，输入值完成则需要禁用对应的cell
        if ((row[info.col].trigger as Array<number>).includes(info.col)) {
            const index = (row[info.col].trigger as Array<number>).filter(item => {
                return item != info.col;
            })
            if (info.newValue != info.oldValue && info.newValue > 0) {
                inputCellData[info.row][index[0]].type = 'READ';
            } else {
                inputCellData[info.row][index[0]].type = 'WRITE';
            }
        }
        bindDataTable(sheet, data);
        // 挂起事件，就是暂停和恢复事件，不能，G，H各自改变，会造成死循环触发
        sheet.suspendEvent();
        sheet.resumeEvent();
    });
}

// 设置表格的头部，可以不占用单元格
export const setHeader = (sheet: GC.Spread.Sheets.Worksheet, headerData: BudgetTableHeaderCell[]) => {
    const row = sheet.getRange(0, -1, 1, -1, GC.Spread.Sheets.SheetArea.colHeader);
    const alignLeftKey: string[] = ['Period', "Type Class", "Model", "Model Year"];
    row.wordWrap(true);
    for (let i = 0; i < headerData.length; i++) {
        // sheet.setStyle(1,1, {height: '2*'}, GC.Spread.Sheets.SheetArea.colHeader)
        sheet.setValue(0, i, headerData[i].name, GC.Spread.Sheets.SheetArea.colHeader);
        if (alignLeftKey.includes(headerData[i].name)) {
            sheet.getCell(0, i, GC.Spread.Sheets.SheetArea.colHeader).hAlign(GC.Spread.Sheets.HorizontalAlign.left);
        }
    }
}

export const ssHotTop = (spread: GC.Spread.Sheets.Workbook, sheet: GC.Spread.Sheets.Worksheet, data: BudgetTableProps | BudgetAllocationTableProps, bindEvent = false): void => {
    spread.suspendPaint();
    // 滚动条样式
    spread.options.scrollbarAppearance = GC.Spread.Sheets.ScrollbarAppearance.mobile;
    // sheet是否显示x
    spread.options.tabNavigationVisible = false
    spread.options.tabStripVisible = false
    // 滚动条常用设置
    spread.options.scrollbarMaxAlign = true
    sheet.setRowCount(1, GC.Spread.Sheets.SheetArea.colHeader);
    // 设置头部
    setHeader(sheet, data.header);
    // 设置header的高度
    sheet.setRowHeight(0, 40, GC.Spread.Sheets.SheetArea.colHeader);
    // 设置多少行和列
    sheet.setColumnCount(data.header.length, GC.Spread.Sheets.SheetArea.viewport)
    const cellData = 'inputTableCell' in data ? data.inputTableCell :  data.allocationTableCell;
    sheet.setRowCount(cellData.length, GC.Spread.Sheets.SheetArea.viewport);

    // 设置整个表格不能插入， 删除行列
    // allowInsertRows不允许插入行，allowInsertColumns不允许插入列
    // allowDeleteRows不允许删除行, allowDeleteColumns不允许删除列
    sheet.options.protectionOptions.allowInsertRows = false
    sheet.options.protectionOptions.allowInsertColumns = false
    sheet.options.protectionOptions.allowDeleteRows = false
    sheet.options.protectionOptions.allowDeleteColumns = false

    //禁止拖动填充
    spread.options.allowUserDragFill = false;
    spread.options.allowUserDragDrop = false;
    // 设置隐藏头和列
    sheet.options.rowHeaderVisible = false
    // sheet.options.colHeaderVisible = false
    // 要设置允保护，不允许的才能生效
    sheet.options.isProtected = true
    // 不允许单个格子编辑，是样式控制
    const sheetStyle = sheet.getDefaultStyle();
    //修改并设置表的默认样式locked为false.
    sheetStyle.locked = true;
    //默认对其方式
    sheetStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
    sheetStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    sheet.setDefaultStyle(sheetStyle);
    //禁止缩放
    spread.options.allowUserZoom = false;
    // 设置样式第一行的背景色
    // const style = new GCC.Spread.Sheets.Style();
    // 第一行的背景色
    // style.backColor = 'red';

    //set style to row.第一行的背景色, setStyle容易全局污染，用 getCell/getRange
    // sheet.setStyle(0, -1, style, GCC.Spread.Sheets.SheetArea.viewport);

    // 设置样式第一行的背景色
    const styleUnEdit = new GC.Spread.Sheets.Style();

    // 第一行不能编辑
    styleUnEdit.locked = true;

    // 设置样式第一行的背景色
    const styleEdit = new GC.Spread.Sheets.Style();
    // 第一行不能编辑
    styleEdit.locked = false;

    // 第一列不能编辑
    bindDataTable(sheet, data);
    if (bindEvent) {
        // 通过输入过后，触发公式自动计算
        inputEventToFormula(sheet, data);
    }
    spread.resumePaint();
}

function hasValue<T extends Record<string, any>>(value: string, dataSource: Array<T>, ): boolean {
    return dataSource.some(item => Object.values(item).includes(value));
}

export const handlerSheetsData = (data: BudgetTableProps | BudgetAllocationTableProps): BudgetTableProps | BudgetAllocationTableProps => {
    //header 的汇总的固定key 分摊, 需要通过这个三个字段来确定唯一
    const headerFixedKeys: string[] = ['Period', 'Type Class', 'Model Year'];
    //deader 的汇总固定key的索引 分摊
    const headerFixedKeysIndex: number[] = [];
    //header需要序列化为百分比的索引
    const percentCellIndex: number[] = [];
    //互斥的header cell
    // const mutexHeaderCellKeys: string[] = ['% Penetration', 'WS Pipeline Override', 'RT Pipeline Override'];
    const mutexHeaderCellKeys: string[] = ['% Penetration', 'Estimated Volume'];
    //互斥的header cell index
    const mutexHeaderCellIndex: number[] = [];
    //固定左对齐的key
    const alignLeftKey: string[] = ['Period', "Type Class", "Model", "Model Year"];
    //根据固定的header key 获取对应的索引;
    if ('header' in data) {
        data.header.forEach((item, index) => {
            //获取分摊固定key的索引
            if (headerFixedKeys.includes(item.name)) {
                headerFixedKeysIndex.push(index);
            }
            //判断那个cell需要序列化为百分比
            if (item.name.indexOf('%') > -1) {
                percentCellIndex.push(index);
            }
            //查找互斥key的索引
            if (mutexHeaderCellKeys.includes(item.name)) {
                mutexHeaderCellIndex.push(index);
            }
        })
    }


    const cellData = 'inputTableCell' in data ? data.inputTableCell :  data.allocationTableCell;


    //添加属性visible  是否显示contents字段  添加属性contentsType 显示的类型   添加属性trigger  互斥的对应的索引
    if ('inputTableCell' in data ) {
        data.inputTableCell.forEach(item => {
            item.forEach((cell, index) => {
                //设置默认都显示contents
                cell.visible = true;
                // //添加显示序列化的数据格式 百分比
                // percentCellIndex.includes(index) ? cell.contentsType = 'percent' : cell.contentsType = '';
                // //添加显示序列化的数据格式  百分比小数
                // if (data.header[index].name === 'AVG.SI %') {
                //     cell.contentsType = 'percentDecimal';
                // }
                // //判断trigger的列是否有值
                // if (mutexHeaderCellIndex.includes(index)) {
                //     cell.trigger = mutexHeaderCellIndex;
                // } else {
                //     cell.trigger = [];
                // }
            })
        })
    }

    if ('allocationTableCell' in data ) {
        data.allocationTableCell.forEach(item => {
            item.forEach((cell, index) => {
                //添加显示序列化的数据格式 百分比
                percentCellIndex.includes(index) ? cell.contentsType = 'percent' : cell.contentsType = '';
                //添加显示序列化的数据格式  百分比小数
                if (data.header[index].name === 'AVG.SI %') {
                    cell.contentsType = 'percentDecimal';
                }
            })
        })
    }

    //获取当前row，根据headerFixedKeys  获取到对应的值
    function getRowContents(row: AllocationTableDataCell[], indexList: number[]): string[] {
        const arr: string[] = [];
        indexList.forEach(index => {
            arr.push(row[index].contents);
        })
        return arr;
    }

    //判断是否相等
    function hasRepeat(content: string[][], row: AllocationTableDataCell[]): boolean {
        const contents = getRowContents(row, headerFixedKeysIndex);
        return content.some(item => item.join(',') == contents.join(','));
    }

    //设置row下的cell visible属性
    function setCellVisible(row: AllocationTableDataCell[]) {
        headerFixedKeysIndex.forEach(index => {
            row[index].visible = false;
        })
    }

    //设置对齐方式
    for (const row of cellData) {
        for (let cell = 0; cell < row.length; cell ++) {
            if (alignLeftKey.includes(data.header[cell].name)) {
                row[cell].align = 'left';
            } else {
                row[cell].align = '';
            }
        }
    }


    //处理分摊数据
    if ('allocationTableCell' in data ) {
        const summaryContents: string[][] = [];
        const isModel = hasValue('Model', data.header);

        //处理有Model的情况给model cell 添加宽度
        if (isModel) {
            const index = data.header.findIndex(item => item.name === 'Model');
            for (const row of cellData) {
                row[index].width = 160;
            }
        }

        //处理分摊的汇总行和children
        for (const row of data.allocationTableCell) {
            // 查找所以的汇总行
            if (row[0].type === 'TYPECLASSSUMMARY' || row[0].type === 'MODELYEARSUMMARY') {
                summaryContents.push(getRowContents(row, headerFixedKeysIndex));
            } else {
                //判断非汇总行的 'Period', 'Type Class', 'Model Year' 的值和汇总行的一致的话就不显示；
                if (hasRepeat(summaryContents, row)) {
                    setCellVisible(row);
                }
            }
        }
    }

    //处理input汇总行和children  cellData 第一条数据肯定是汇总行，需求
    if ('inputTableCell' in data) {
        const isModelYear = hasValue('Model Year', data.header);
        const isModel = hasValue('Model', data.header);

        //处理有Model的情况给model cell 添加宽度
        if (isModel) {
            const index = data.header.findIndex(item => item.name === 'Model');
            for (const row of cellData) {
                row[index].width = 160;
            }
        }

        //处理 Period 字段，只会有一个日期并且在每行第一个
        for (const [rowIndex, row] of cellData.entries()) {
            if (rowIndex !== 0){
                row[0].visible = false;
            }
        }

        //处理typeClass保证唯一
        const typeClass: string[] = [];
        const typeClassIndex = data.header.findIndex(item => item.name === 'Type Class');
        for (const row of cellData) {
            if (typeClass.includes(row[typeClassIndex].contents)) {
                row[typeClassIndex].visible = false;
            } else {
                typeClass.push(row[typeClassIndex].contents);
            }
        }

        //没有 Model Year 肯定有  Model 无需处理

        //没有 Model 肯定有 Model Year 无需处理

        // 有 Model  和 Model Year
        if (isModel && isModelYear) {
            const modelYear: string[] = [];
            const index = data.header.findIndex(item => item.name === 'Model Year');
            for (const row of cellData) {
                if (modelYear.includes(row[index].contents)) {
                    row[index].visible = false;
                } else {
                    modelYear.push(row[index].contents);
                }
            }
        }

    }

    return data;
}
