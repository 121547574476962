import { createI18n } from "vue-i18n";
import enLocale from "./lang/en";
import zhLocale from "./lang/zh";

const messages = {
  en: {
    ...enLocale,
  },
  zh: {
    ...zhLocale,
  },
};

const i18n = createI18n({
  locale: sessionStorage.getItem("getLocale") || "en", // set locale
  messages, // set locale messages
  globalInjection: true,
});
export default i18n;
