import { RouteRecordRaw } from "vue-router";
export const delayUsedCarRouters: Array<RouteRecordRaw> = [
  {
    path: "/delayUsedCar/dealer",
    name: "Used Car Dealer",
    component: () => import("@/views/DelayUsedCar/Dealer/index.vue"),
  },
  {
    path: "/delayUsedCar/dealerDetail",
    name: "Used Car Dealer Detail",
    component: () => import("@/views/DelayUsedCar/Dealer/Detail/index.vue"),
  },
  {
    path: "/delayUsedCar/regionUC",
    name: "Used Car Region",
    component: () => import("@/views/DelayUsedCar/Region/index.vue"),
  },
  {
    path: "/delayUsedCar/sipoUC",
    name: "Used Car SIPO",
    component: () => import("@/views/DelayUsedCar/SIPO/index.vue"),
  },
];
