import { ManualProps, BudgetTableTOPProps, BudgetTableHeaderProps } from './types'

export const manualAutoArr: ManualProps[] = [
    {
        id: '1',
        title: 'Manual',
    },
    {
        id: '2',
        title: 'Auto'
    }
]
export const manualArr: ManualProps[] = [
    {
        id: '11',
        title: 'Manual',
    },
    {
        id: '22',
        title: 'Auto'
    }
]

export const offerType: ManualProps[] = [
    {
        id: '1',
        title: 'SSSC'
    },
    {
        id: '2',
        title: 'SSST'
    }
]
export const tradeData: ManualProps[] = [
    {
        id: '1',
        title: 'Trade in & 1A+1B'
    },
    {
        id: '2',
        title: 'Trade in & 2A+2B'
    },
    {
        id: '3',
        title: 'Repurchase & 1A+1B'
    }
]
export const LevelData: ManualProps[] = [
    {
        id: '1',
        title: 'Summary Level'
    },
    {
        id: '2',
        title: 'Detail Level'
    }
]
// budget input 表格的数据
export const budgetTableDataTop: BudgetTableTOPProps[][] = [
    [
        {value: '', formula: '', trigger: [], editable: false, id: '100', type: 'text'}, // 0
        {value: 'E300LSedan (M264)', formula: '', trigger: [], editable: false, id: '101', type: 'text'}, // 1
        {value: 8000, formula: '', trigger: [], editable: false, id: '1', type: 'numeric'}, // 2
        {value: 0.2, formula: '=E1 / C1', trigger: [2, 4], editable: true, id: '2', type: 'percent'}, // 3
        {value: 0, formula: '=C1 * D1', trigger: [2, 3], editable: true, id: '3', type: 'numeric'}, // 4
        {value: 0, formula: '=C1 * D1 + E1', trigger: [2, 3, 4], editable: true, id: '4', type: 'numeric'}, // 5
        {value: 0.2, formula: '', trigger: [], editable: false, id: '103', type: 'percent'}, // 7
        {value: 1000, formula: '', trigger: [], editable: false, id: '104', type: 'numeric'}, // 8
        {value: 0.1, formula: '', trigger: [], editable: false, id: '105', type: 'percent'}, // 9
        {value: 0.1, formula: '', trigger: [], editable: false, id: '106', type: 'percent'}, // 10
        {value: 2000, formula: '', trigger: [], editable: false, id: '107', type: 'numeric'}, // 11
        {value: 0.1, formula: '', trigger: [], editable: false, id: '108', type: 'percent'}, // 12
    ],
    [
        {value: '', formula: '', trigger: [], editable: false, id: '100', type: 'text'}, // 0
        {value: 'E300LSedan luxury (M264)', formula: '', trigger: [], editable: false, id: '101', type: 'text'}, // 1
        {value: 8971, formula: '', trigger: [], editable: false, id: '1010', type: 'numeric'}, // 2
        {value: 0, formula: '=E2 / E2', trigger: [2, 4], editable: true, id: '1011', type: 'percent'}, // 3
        {value: 0, formula: '=C2 * D2', trigger: [2, 3], editable: true, id: '10113', type: 'numeric'}, // 4
        {value: 0, formula: '=C2 * D2 + E2', trigger: [2, 3, 4], editable: true, id: '10114', type: 'numeric'}, // 5
        {value: 0.2, formula: '', trigger: [], editable: false, id: '1012', type: 'percent'}, // 7
        {value: 1000, formula: '', trigger: [], editable: false, id: '1013', type: 'numeric'}, // 8
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1014', type: 'percent'}, // 9
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1015', type: 'percent'}, // 10
        {value: 2000, formula: '', trigger: [], editable: false, id: '1016', type: 'numeric'}, // 11
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1017', type: 'percent'}, // 12
    ],
    [
        {value: '', formula: '', trigger: [], editable: false, id: '100', type: 'text'}, // 0
        {value: 'E300LSedan luxury (M264)', formula: '', trigger: [], editable: false, id: '101', type: 'text'}, // 1
        {value: 1000, formula: '', trigger: [], editable: false, id: '1010', type: 'numeric'}, // 2
        {value: 0, formula: '=C3 / E3', trigger: [2, 4], editable: true, id: '10112', type: 'percent'}, // 3
        {value: 0, formula: '=C3 * D3', trigger: [2, 3], editable: true, id: '10113', type: 'numeric'}, // 4
        {value: 0, formula: '=C3 * D3 + E3', trigger: [2, 3, 4], editable: true, id: '10114', type: 'numeric'}, // 5
        {value: 0.2, formula: '', trigger: [], editable: false, id: '1012', type: 'percent'}, // 7
        {value: 1000, formula: '', trigger: [], editable: false, id: '1013', type: 'numeric'}, // 8
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1014', type: 'percent'}, // 9
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1015', type: 'percent'}, // 10
        {value: 2000, formula: '', trigger: [], editable: false, id: '1016', type: 'numeric'}, // 11
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1017', type: 'percent'}, // 12
    ],
    [
        {value: '', formula: '', trigger: [], editable: false, id: '200', type: 'text'}, // 0
        {value: '', formula: '', trigger: [], editable: false, id: '201', type: 'text'}, // 1
        {value: '', formula: 'SUM(C1:C3)', trigger: [2], editable: false, id: '2010', type: 'numeric'}, // 2
        {value: 0, formula: 'SUM(D1:D3)', trigger: [3], editable: true, id: '20112', type: 'numeric'}, // 3
        {value: 0, formula: '', trigger: [2, 3], editable: true, id: '20113', type: 'numeric'}, // 4
        {value: 0, formula: '', trigger: [2, 3, 4], editable: true, id: '20114', type: 'numeric'}, // 5
        {value: 0, formula: '', trigger: [], editable: false, id: '2012', type: 'percent'}, // 7
        {value: 0, formula: '', trigger: [], editable: false, id: '2013', type: 'numeric'}, // 8
        {value: 0, formula: '', trigger: [], editable: false, id: '2014', type: 'percent'}, // 9
        {value: 0, formula: '', trigger: [], editable: false, id: '2015', type: 'percent'}, // 10
        {value: 0, formula: '', trigger: [], editable: false, id: '2016', type: 'numeric'}, // 11
        {value: 0, formula: '', trigger: [], editable: false, id: '2017', type: 'percent'}, // 12
    ]
]
export const budgetTableDataTop1: BudgetTableTOPProps[][] = [
    [
        {value: '', formula: '', trigger: [], editable: false, id: '100', type: 'text'}, // 0
        {value: 'E300LSedan (M264)', formula: '', trigger: [], editable: false, id: '101', type: 'text'}, // 1
        {value: 9000, formula: '', trigger: [], editable: false, id: '1', type: 'numeric'}, // 2
        {value: 0.2, formula: '=E1 / C1', trigger: [2, 4], editable: true, id: '2', type: 'percent'}, // 3
        {value: 0, formula: '=C1 * D1', trigger: [2, 3], editable: true, id: '3', type: 'numeric'}, // 4
        {value: 0, formula: '=C1 * D1 + E1', trigger: [2, 3, 4], editable: true, id: '4', type: 'numeric'}, // 5
        {value: 0.2, formula: '', trigger: [], editable: false, id: '103', type: 'percent'}, // 7
        {value: 8000, formula: '', trigger: [], editable: false, id: '104', type: 'numeric'}, // 8
        {value: 0.1, formula: '', trigger: [], editable: false, id: '105', type: 'percent'}, // 9
        {value: 0.1, formula: '', trigger: [], editable: false, id: '106', type: 'percent'}, // 10
        {value: 2000, formula: '', trigger: [], editable: false, id: '107', type: 'numeric'}, // 11
        {value: 0.1, formula: '', trigger: [], editable: false, id: '108', type: 'percent'}, // 12
    ],
    [
        {value: '', formula: '', trigger: [], editable: false, id: '100', type: 'text'}, // 0
        {value: 'E300LSedan luxury (M264)', formula: '', trigger: [], editable: false, id: '101', type: 'text'}, // 1
        {value: 8971, formula: '', trigger: [], editable: false, id: '1010', type: 'numeric'}, // 2
        {value: 0, formula: '=E2 / E2', trigger: [2, 4], editable: true, id: '1011', type: 'percent'}, // 3
        {value: 0, formula: '=C2 * D2', trigger: [2, 3], editable: true, id: '10113', type: 'numeric'}, // 4
        {value: 0, formula: '=C2 * D2 + E2', trigger: [2, 3, 4], editable: true, id: '10114', type: 'numeric'}, // 5
        {value: 0.2, formula: '', trigger: [], editable: false, id: '1012', type: 'percent'}, // 7
        {value: 1000, formula: '', trigger: [], editable: false, id: '1013', type: 'numeric'}, // 8
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1014', type: 'percent'}, // 9
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1015', type: 'percent'}, // 10
        {value: 2000, formula: '', trigger: [], editable: false, id: '1016', type: 'numeric'}, // 11
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1017', type: 'percent'}, // 12
    ],
    [
        {value: '', formula: '', trigger: [], editable: false, id: '100', type: 'text'}, // 0
        {value: 'E300LSedan luxury (M264)', formula: '', trigger: [], editable: false, id: '101', type: 'text'}, // 1
        {value: 1000, formula: '', trigger: [], editable: false, id: '1010', type: 'numeric'}, // 2
        {value: 0, formula: '=C3 / E3', trigger: [2, 4], editable: true, id: '10112', type: 'percent'}, // 3
        {value: 0, formula: '=C3 * D3', trigger: [2, 3], editable: true, id: '10113', type: 'numeric'}, // 4
        {value: 0, formula: '=C3 * D3 + E3', trigger: [2, 3, 4], editable: true, id: '10114', type: 'numeric'}, // 5
        {value: 0.2, formula: '', trigger: [], editable: false, id: '1012', type: 'percent'}, // 7
        {value: 1000, formula: '', trigger: [], editable: false, id: '1013', type: 'numeric'}, // 8
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1014', type: 'percent'}, // 9
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1015', type: 'percent'}, // 10
        {value: 2000, formula: '', trigger: [], editable: false, id: '1016', type: 'numeric'}, // 11
        {value: 0.1, formula: '', trigger: [], editable: false, id: '1017', type: 'percent'}, // 12
    ],
    [
        {value: '', formula: '', trigger: [], editable: false, id: '200', type: 'text'}, // 0
        {value: '', formula: '', trigger: [], editable: false, id: '201', type: 'text'}, // 1
        {value: '', formula: 'SUM(C1:C3)', trigger: [2], editable: false, id: '2010', type: 'numeric'}, // 2
        {value: 0, formula: 'SUM(D1:D3)', trigger: [3], editable: true, id: '20112', type: 'numeric'}, // 3
        {value: 0, formula: '', trigger: [2, 3], editable: true, id: '20113', type: 'numeric'}, // 4
        {value: 0, formula: '', trigger: [2, 3, 4], editable: true, id: '20114', type: 'numeric'}, // 5
        {value: 0, formula: '', trigger: [], editable: false, id: '2012', type: 'percent'}, // 7
        {value: 0, formula: '', trigger: [], editable: false, id: '2013', type: 'numeric'}, // 8
        {value: 0, formula: '', trigger: [], editable: false, id: '2014', type: 'percent'}, // 9
        {value: 0, formula: '', trigger: [], editable: false, id: '2015', type: 'percent'}, // 10
        {value: 0, formula: '', trigger: [], editable: false, id: '2016', type: 'numeric'}, // 11
        {value: 0, formula: '', trigger: [], editable: false, id: '2017', type: 'percent'}, // 12
    ]
]
export const tableHeader: BudgetTableHeaderProps[] = [
    {id: '1', name: 'Period', type: 'text'},
    {id: '2', name: 'Vehicle', type: 'text'},
    {id: '3', name: 'RT Pipeline', type: 'numeric'},
    {id: '4', name: '% Penetration', type: 'percent'},
    {id: '5', name: 'Estimated Volume', type: 'numeric'},
    {id: '6', name: 'Average Cost /unit', type: 'numeric'},
    {id: '7', name: '% MSRP w/o VAT', type: 'percent'},
    {id: '8', name: 'MSRP', type: 'numeric'},
    {id: '9', name: '% TAP Impact', type: 'percent'},
    {id: '10', name: 'AVG.SI %', type: 'percent'},
    {id: '11', name: 'Budget Estimation', type: 'numeric'},
    {id: '12', name: 'Avg. SI', type: 'percent'}
 ]
export const inputsetPeriod: ManualProps[] = [
    {
        id: '1',
        title: 'Period'
    },
    {
        id: '2',
        title: 'Month'
    }
]
export const inputsetVehicleView: ManualProps[] = [
    {
        id: '1',
        title: 'Type Class'
    },
    {
        id: '2',
        title: 'Model'
    }
]
export const inputsetVolumeBase: ManualProps[] = [
    {
        id: '1',
        title: 'WS'
    },
    {
        id: '2',
        title: 'RT'
    }
]
export const inputsetCostBase: ManualProps[] = [
    {
        id: '1',
        title: 'Cost/unit'
    },
    {
        id: '2',
        title: '%MSRP w/o VAT'
    }
]
// budget input 表格的数据

export const salesList = [
    {
        salesnm: 'Normal Sales',
        salesid: 1
    },
    {
        salesnm: 'Fleet Sales',
        salesid: 2
    },
]
export const incaentiveList = [
    {
        incentiveCategoryName: 'Customer Incentive',
        incentiveCategoryId: "aa157348-5ceb-48b0-b410-5d3a11e23b1b"
        // incentiveCategoryId: "0a671404-2730-4b33-9532-cd722928d81e"
    },
    {
        incentiveCategoryName: 'Dealer Incentive',
        incentiveCategoryId: "2b788f1f-cf59-4a54-b7fa-25b5afc6a49c"
        // incentiveCategoryId: "67ff8bd2-5be2-4a80-9e12-2a3c92ca769f"
    }
]

export const othersData = [
    {
        header: 'BMBS Initiated Fleet',
        key: '1',
        fieldOptions: [
            {
                value: 'RT Pipeline',
                icon: 'icon-edit2',
            },
            {
                value: 'Fleet Volume',
                icon: 'icon-shezhi',
            },
            {
                value: '% Fleet Share',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Fixed Margin',
                icon: 'icon-edit2',
            },
            {
                value: '% Customer Incentive (X%)',
                icon: 'icon-edit2',
            },
            {
                value: '% Standard F/S Discount',
                icon: 'icon-shezhi',
            },
            {
                value: '% Strategic Fleet Discount',
                icon: 'icon-shezhi',
            },
            {
                value: '% F/S Incentive_original',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Adjustment',
                icon: 'icon-edit2',
            },
            {
                value: '% F/S Incentive_revised',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Final F/S Discount',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'F/S Incentive Amount w/o VAT',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Additional Discount to dealer',
                icon: 'icon-shezhi',
            },
            {
                value: 'Additional Discount to dealer w/o VAT',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'Budget Estimation',
                icon: 'icon-GeneratePayoutRound1',
            },
        ],
        logicOptions: [
            {
                display: '% Fleet Share :',
                formula: 'Fleet Volume ÷ RT Pipeline'
            },
            {
                display: '% F/S Incentive_original :',
                formula: '% Fixed Margin + % Customer Incentive + % Standard F/S Discount + % Strategic Fleet Discount'
            },
            {
                display: '% F/S Incentive_revised :',
                formula: '% F/S Incentive_original + % Adjustment'
            },
            {
                display: '% Final F/S Discount :',
                formula: '% F/S Incentive_revised - % Fixed Margin'
            },
            {
                display: 'F/S Incentive Amount w/o VAT :',
                formula: '% Final F/S Discount × MSRP w/o VAT'
            },
            {
                display: 'Additional Discount to dealer w/o VAT :',
                formula: '% Additional Discount to dealer × MSRP w/o VAT'
            },
            {
                display: 'Budget Estimation :',
                formula: 'Fleet Volume × (F/S Incentive Amount w/o VAT + Additional Discount to dealer w/o VAT)'
            },
            {
                display: 'Avg. SI  :',
                formula: 'F/S Incentive Amount w/o VAT + Additional Discount to dealer w/o VAT'
            },
            {
                display: 'Avg. SI%  :',
                formula: 'Avg. SI × MSRP w/o VAT'
            },

        ]
    },
    {
        header: 'BMBS Initiated Fleet - Special',
        key: '2',
        fieldOptions: [
            {
                value: 'RT Pipeline',
                icon: 'icon-edit2',
            },
            {
                value: 'Fleet Volume',
                icon: 'icon-shezhi',
            },
            {
                value: '% Fleet Share',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Fixed Margin',
                icon: 'icon-edit2',
            },
            {
                value: '% Customer Incentive (X%)',
                icon: 'icon-edit2',
            },
            {
                value: '% Standard F/S Discount',
                icon: 'icon-shezhi',
            },
            {
                value: '% Strategic Fleet Discount',
                icon: 'icon-shezhi',
            },
            {
                value: '% F/S Incentive_original',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Adjustment',
                icon: 'icon-edit2',
            },
            {
                value: '% F/S Incentive_revised',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Final F/S Discount',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'F/S Incentive Amount w/o VAT',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Additional Discount to dealer',
                icon: 'icon-shezhi',
            },
            {
                value: 'Additional Discount to dealer w/o VAT',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'Budget Estimation',
                icon: 'icon-GeneratePayoutRound1',
            },
        ],
        logicOptions: [
            {
                display: '% Fleet Share :',
                formula: 'Fleet Volume ÷ RT Pipeline'
            },
            {
                display: '% F/S Incentive_original :',
                formula: '% Fixed Margin + % Customer Incentive + % Standard F/S Discount + % Strategic Fleet Discount'
            },
            {
                display: '% F/S Incentive_revised :',
                formula: '% F/S Incentive_original + % Adjustment'
            },
            {
                display: '% Final F/S Discount :',
                formula: '% F/S Incentive_revised - % Fixed Margin'
            },
            {
                display: 'F/S Incentive Amount w/o VAT :',
                formula: '% Final F/S Discount × MSRP w/o VAT'
            },
            {
                display: 'Additional Discount to dealer w/o VAT :',
                formula: '% Additional Discount to dealer × MSRP w/o VAT'
            },
            {
                display: 'Budget Estimation :',
                formula: 'Fleet Volume × (F/S Incentive Amount w/o VAT + Additional Discount to dealer w/o VAT)'
            },
            {
                display: 'Avg. SI  :',
                formula: 'F/S Incentive Amount w/o VAT + Additional Discount to dealer w/o VAT'
            },
            {
                display: 'Avg. SI%  :',
                formula: 'Avg. SI × MSRP w/o VAT'
            },

        ]
    },
    {
        header: 'BMBS Overseas Returnee',
        key: '3',
        fieldOptions: [
            {
                value: 'RT Pipeline',
                icon: 'icon-edit2',
            },
            {
                value: 'Fleet Volume',
                icon: 'icon-shezhi',
            },
            {
                value: '% Fleet Share',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Fixed Margin',
                icon: 'icon-edit2',
            },
            {
                value: '% Standard F/S Discount',
                icon: 'icon-edit2',
            },
            {
                value: '% F/S Incentive_revised',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'F/S Incentive Amount w/o VAT',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Additional Discount to dealer',
                icon: 'icon-shezhi',
            },
            {
                value: 'Additional Discount to dealer w/o VAT',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Agency Commission',
                icon: 'icon-shezhi',
            },
            {
                value: 'Agency Commission',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'Budget Estimation',
                icon: 'icon-GeneratePayoutRound1',
            },
        ],
        logicOptions: [
            {
                display: '% Fleet Share :',
                formula: 'Fleet Volume ÷ RT Pipeline'
            },
            {
                display: '% F/S Incentive_revised :',
                formula: '% Standard F/S Discount - % Fixed Margin (if < 0, equal 0)'
            },
            {
                display: 'F/S Incentive Amount w/o VAT :',
                formula: '% F/S Incentive_revised × MSRP w/o VAT '
            },
            {
                display: 'Additional Discount to dealer w/o VAT :',
                formula: '% Additional discount to dealer × MSRP w/o VAT'
            },
            {
                display: 'Agency Commission :',
                formula: '% Agency Commission × MSRP'
            },
            {
                display: 'Budget Estimation :',
                formula: 'Fleet Volume × (F/S Incentive Amount w/o VAT + Additional Discount to dealer w/o VAT + Agency commision)'
            },
            {
                display: 'Avg. SI  :',
                formula: 'F/S Incentive Amount w/o VAT + Additional Discount to dealer w/o VAT + Agency commission'
            },
            {
                display: 'Avg. SI%  :',
                formula: 'Avg. SI × MSRP w/o VAT'
            },

        ]
    },
    {
        header: 'Dealer Initiated Fleet',
        key: '4',
        fieldOptions: [
            {
                value: 'RT Pipeline',
                icon: 'icon-edit2',
            },
            {
                value: 'Fleet Volume',
                icon: 'icon-shezhi',
            },
            {
                value: '% Fleet Share',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Fleet Discount',
                icon: 'icon-shezhi',
            },
            {
                value: 'Discount Budget w/o VAT',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% Avg. Bonus Ratio',
                icon: 'icon-shezhi',
            },
            {
                value: 'Bonus Budget w/o VAT',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'Budget Estimation',
                icon: 'icon-GeneratePayoutRound1',
            },
        ],
        logicOptions: [
            {
                display: '% Fleet Share :',
                formula: 'Fleet Volume ÷ RT Pipeline'
            },
            {
                display: 'Discount Budget w/o VAT :',
                formula: 'Fleet Volume × % Fleet Discount × MSRP w/o VAT'
            },
            {
                display: 'Bonus Budget w/o VAT :',
                formula: 'Fleet Volume × % Avg. Bonus Ratio × MSRP w/o VAT'
            },
            {
                display: 'Budget Estimation :',
                formula: 'Discount Budget w/o VAT + Bonus Budget w/o VAT'
            },
            {
                display: 'Avg. SI  :',
                formula: '(% Fleet Discount + % Avg. Bonus Ratio) × MSRP w/o VAT'
            },
            {
                display: 'Avg. SI%  :',
                formula: '% Fleet Discount + % Avg. Bonus Ratio'
            },
        ]
    },
    {
        header: 'Comapany Car',
        key: '5',
        fieldOptions: [
            {
                value: 'RT Pipeline',
                icon: 'icon-edit2',
            },
            {
                value: 'PT Car',
                icon: 'icon-shezhi',
            },
            {
                value: 'Volume Car',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'Company Car Volume',
                icon: 'icon-shezhi',
            },
            {
                value: '% PT Additional_PY',
                icon: 'icon-edit2',
            },
            {
                value: '% Normal_PY',
                icon: 'icon-edit2',
            },
            {
                value: '% Total_PY',
                icon: 'icon-edit2',
            },
            {
                value: '% PT Additional',
                icon: 'icon-shezhi',
            },
            {
                value: '% Normal Discount',
                icon: 'icon-shezhi',
            },
            {
                value: '% Total Discount',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'MSRP',
                icon: 'icon-edit2',
            },
            {
                value: '% Fixed Margin',
                icon: 'icon-edit2',
            },
            {
                value: 'PT Budget',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'Volume Budget',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'Budget Estimation',
                icon: 'icon-GeneratePayoutRound1',
            },
        ],
        logicOptions: [
            {
                display: 'Volume Car :',
                formula: 'Company Car Volume - PT Car'
            },
            {
                display: '% Total Discount :',
                formula: '% PT Additional + % Normal Discount'
            },
            {
                display: 'PT Budget :',
                formula: '% PT Additional × PT Car * MSRP / (1+VAT%）'
            },
            {
                display: 'Volume Budget :',
                formula: '(% Normal Discount - % Fixed Margin) × Volume Car × MSRP / (1+VAT%)'
            },
            {
                display: 'Budget Estimation :',
                formula: 'PT Budget + Budget Estimation'
            },
            {
                display: 'Avg. SI%  :',
                formula: 'Budget Estimation / RT LLP'
            },
            {
                display: 'AVG.SI % :',
                formula: 'Avg. SI /MSRP × (1+VAT%)'
            },
        ]
    },
    {
        header: 'PMT',
        key: '6',
        fieldOptions: [
            {
                value: 'WS Pipeline',
                icon: 'icon-edit2',
            },
            {
                value: '% Penetration',
                icon: 'icon-shezhi',
            },
            {
                value: 'Estimated Volume',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: 'MSRP',
                icon: 'icon-edit2',
            },
            {
                value: 'Dealer Purchase Price w VAT',
                icon: 'icon-edit2',
            },
            {
                value: '% Interest Rate',
                icon: 'icon-shezhi',
            },
            {
                value: 'Extension Days',
                icon: 'icon-shezhi',
            },
            {
                value: 'Average Cost / unit',
                icon: 'icon-shezhi',
            },
            {
                value: '% MSRP w/o VAT',
                icon: 'icon-GeneratePayoutRound1',
            },
            {
                value: '% TAP Impact',
                icon: 'icon-shezhi',
            },
            {
                value: 'Budget Estimation',
                icon: 'icon-GeneratePayoutRound1',
            },
        ],
        logicOptions: [
            {
                display: 'Average Cost / unit :',
                formula: 'Dealer Purchase Price w VAT × % Interest Rate × Extension Days/360'
            },
            {
                display: 'Estimated Volume :',
                formula: 'WS Pipeline × % Penetration'
            },
            {
                display: 'Total Budget :',
                formula: 'Estimated Volume × Average Cost / unit'
            },
            {
                display: 'Avg. SI%  :',
                formula: 'Average Cost / unit × % Penetration'
            },
            {
                display: 'AVG.SI % :',
                formula: 'Avg. SI ÷ MSRP w/o VAT'
            },
        ]
    }
]
