
    import { defineComponent, onMounted, ref, reactive, toRefs, watch, onBeforeUnmount, nextTick } from 'vue';
    import { TreeSelect } from 'ant-design-vue';
    import GC from "@grapecity/spread-sheets";
    import moment from "moment";
    import { initSpread, transformData, bindDataTable, setCollapsed, setHeader, initOutlineColumn } from './utils';
    import { RowProps, Header, QueryParams } from './types';
    import { getBudgetReportData, getVersion } from '@/API/budget';
    import { getSubscribedBu, getBrand, getProgramStatus, getVehicleMake, scenrioList, findBuVehicleList } from '@/API/approve';
    import useFetch from "@/hooks/useFetch";
    import { BrandItem, BuItem, ProgramStatusItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
    import { ScenarioResultProps } from './types'
    import { useStore } from "vuex";
    import { getGuideLineConfig } from '@/API/budget'
    import { GuideLineConfig } from '@/views/Approve/ControllingRoleApprove/ProgramHome/types'
    import useWait from '@/utils/payment/useWait'
    import { getBuList } from '@/API/approvalChain'
    import { cloneDeep } from 'lodash'

    const SHOW_PARENT = TreeSelect.SHOW_PARENT;
    const SHOW_ALL = TreeSelect.SHOW_ALL;

    interface TreeDataItem {
        value: string;
        key: string;
        title?: string;
        disabled?: boolean;
        children?: TreeDataItem[];
    }

    const qmOptions: TreeDataItem[] = [
        {
            title: 'Q1',
            value: 'Q1',
            key: 'Q1',
            children: [
                {
                    title: '01',
                    value: '01',
                    key: '01',
                },
                {
                    title: '02',
                    value: '02',
                    key: '02',
                },
                {
                    title: '03',
                    value: '03',
                    key: '03',
                },
            ],
        },
        {
            title: 'Q2',
            value: 'Q2',
            key: 'Q2',
            children: [
                {
                    title: '04',
                    value: '04',
                    key: '04',
                },
                {
                    title: '05',
                    value: '05',
                    key: '05',
                },
                {
                    title: '06',
                    value: '06',
                    key: '06',
                },
            ],
        },
        {
            title: 'Q3',
            value: 'Q3',
            key: 'Q3',
            children: [
                {
                    title: '07',
                    value: '07',
                    key: '07',
                },
                {
                    title: '08',
                    value: '08',
                    key: '08',
                },
                {
                    title: '09',
                    value: '09',
                    key: '09',
                },
            ],
        },
        {
            title: 'Q4',
            value: 'Q4',
            key: 'Q4',
            children: [
                {
                    title: '10',
                    value: '10',
                    key: '10',
                },
                {
                    title: '11',
                    value: '11',
                    key: '11',
                },
                {
                    title: '12',
                    value: '12',
                    key: '12',
                },
            ],
        },
    ];

    const header: Header[][] = [
        [
            {
                name: ''
            },
            {
                name: ''
            },
            {
                name: 'MTD'
            },
            {
                name: 'MTD'
            },
            {
                name: 'MTD'
            },
            {
                name: 'MTD'
            },
            {
                name: 'YTD'
            },
            {
                name: 'YTD'
            },
            {
                name: 'YTD'
            },
            {
                name: 'YTD'
            }
        ],
        [
            {
                name: 'Type Class'
            },
            {
                name: 'Model'
            },
            {
                name: '%AVG.SI'
            },
            {
                name: '%Guideline'
            },
            {
                name: '%Deviation'
            },
            {
                name: 'Deviation Absolut Amount'
            },
            {
                name: '%AVG.SI'
            },
            {
                name: '%Guideline'
            },
            {
                name: '%Deviation'
            },
            {
                name: 'Deviation Absolut Amount'
            },
        ],
    ]

    const wait = useWait()
    let inited = false
    let defaultBuIds: string[] = []

    export default defineComponent({
        name: "budgetReport",
        setup() {
            const { state } = useStore();
            const queryParams = reactive<QueryParams>({
                brand: [],
                bu: [],
                check: false,
                kufri: '',
                make: '',
                reportView: 'TypeClass',
                rtOrWs: 'RT',
                yearQm: '',
                submitStatus: [],
                activeStatus: 'Active',
                planningName: '',
            })

            const year = ref<string>(moment().add('month', 1).format('YYYY'));

            const openYear = ref<boolean>(false);

            const getDefaultQm = (): string => {
                return 'Q' + moment().add('month', 1).quarter();
            }

            const qm = ref<string[]>([getDefaultQm()]);

            // sheet当前表格的初始化
            let workbook: GC.Spread.Sheets.Workbook | null;

            let worksheet:  GC.Spread.Sheets.Worksheet | null;

            const reportViewOptions = ref<{ name: string; value: string}[]>([
                {
                    name: 'Type Class',
                    value: 'TypeClass'
                },
                {
                    name: 'Model',
                    value: 'Model'
                }
            ])

            const rtWsOptions = ref<{ name: string; value: string}[]>([
                {
                    name: 'RT',
                    value: 'RT'
                },
                {
                    name: 'WS',
                    value: 'WS'
                }
            ])

            const buTree: TreeDataItem[] = []

            //programStatus options
            const { data: programStatusOptions } = useFetch<ProgramStatusItem[]>({type: 'guideline'}, [], getProgramStatus);
            const reportData = ref<RowProps[]>([]);

            const buOptions = ref()
            const makeOptions = ref()
            //brand options
            const brandOptions = ref();
            const typeClassOptions = ref()
            const intoFull = (target: string) => {
                switch (target) {
                    // case 'buId':
                    //     buOptions.value = []
                    //     break;
                    case 'make':
                        makeOptions.value = []
                        break;
                    case 'brand':
                        brandOptions.value = []
                        break;
                    case 'typeClass':
                        typeClassOptions.value = []
                        break;
                }
            } 

            const initVehicleMake = () => {
                const params = {
                    target: 'make',
                    buId: queryParams.bu,
                    brand: queryParams.brand,
                }
                return findBuVehicleList(params).then(res => {
                    makeOptions.value = res
                    if (!res.some((item: any) => item.name === queryParams.make)) {
                        Object.assign(queryParams, {make: makeOptions.value[0].name});
                    }   
                })
            }

            const initBuTree = async () => {
                const buList = (await getBuList() as any).filter((bu: any) => bu.deleted === 0)
                  .sort((a: any, b: any) => a.parentId < b.parentId ? -1 : 1)
                const buMap = new Map<string, TreeDataItem>()
                for (const bu of buList) {
                    const buItem: TreeDataItem = {
                        value: bu.id.toString(),
                        key: bu.id.toString(),
                        title: bu.nameEn,
                        children: []
                    }
                    buMap.set(bu.id.toString(), buItem)
                    if (bu.parentId === null) {
                        buTree.push(buItem)
                    } else {
                        buMap.get(bu.parentId.toString())?.children?.push(buItem)
                    }
                }
            }

            const _findBuNameById = (buTreeItems: TreeDataItem[], buIds: string[]) => {
              let buNames: string[] = []
              buTreeItems.forEach(buTreeItem => {
                if (buIds.includes(buTreeItem.key) && buTreeItem.title) {
                  buNames.push(buTreeItem.title)
                }
                if (buTreeItem.children?.length) {
                  buNames = buNames.concat(_findBuNameById(buTreeItem.children, buIds))
                }
              })
              return buNames
            }

            const findBuNameById = (buIds: string[]) => {
              return _findBuNameById(buTree, buIds)
            }

            const _findBuTreeDataItemByName = (buTreeItems: TreeDataItem[], name: string): TreeDataItem|null => {
              for (const buTreeItem of buTreeItems) {
                if (buTreeItem.title === name && buTreeItem.key) {
                  return buTreeItem
                }
                if (buTreeItem.children?.length) {
                  const matchTreeDataItem = _findBuTreeDataItemByName(buTreeItem.children, name)
                  if (matchTreeDataItem) {
                    return matchTreeDataItem
                  }
                }
              }
              return null
            }

            const _findChildrenBuIds = (buTreeItem: TreeDataItem) => {
              let buIds: string[] = buTreeItem?.key ? [buTreeItem?.key]: []
              buTreeItem.children?.forEach(b => {
                buIds = buIds.concat(_findChildrenBuIds(b))
              })
              return buIds
            }

            const findBuIdsByParentName = (buTree: TreeDataItem[], name: string) => {
              const buTreeDataItem = _findBuTreeDataItemByName(buTree, name)
              return buTreeDataItem ? _findChildrenBuIds(buTreeDataItem): []
            }

            const buIdsWithChildren = (buTree: TreeDataItem[], ids: string[]) => {
              const allIds: string[] = cloneDeep(ids)
              buTree.forEach(buTreeItem => {
                if (ids.includes(buTreeItem.key)) {
                  allIds.push(..._findChildrenBuIds(buTreeItem))
                }
              })

              return Array.from(new Set(allIds))
            }

            const _filterBuTree = (buTreeItem: TreeDataItem, buIds: Set<string>) => {
              if (!buIds.has(buTreeItem.key)) {
                return undefined
              }
              if (buTreeItem.children?.length) {
                buTreeItem.children = buTreeItem.children.filter(b => _filterBuTree(b, buIds))
              }
              return buTreeItem
            }

            const getBuOptions = (buList: {id: string; name: string}[]) => {
              const buIds = new Set(buList.map(bu => bu.id))
              const buOptions = cloneDeep(buTree)
              const filteredBuOptions: TreeDataItem[] = []
              buOptions.forEach(buOption => {
                const result = _filterBuTree(buOption, buIds)
                if (result) {
                  filteredBuOptions.push(result)
                }
              })
              return filteredBuOptions
            }

            const initVehicleBu = async () => {
                const params = {
                    target: 'buId',
                    make: queryParams.make
                }
                const buList = await findBuVehicleList(params)
                buOptions.value = getBuOptions(buList)
                queryParams.bu = findBuIdsByParentName(buOptions.value, 'MBPC')
                defaultBuIds = queryParams.bu
            } 

            const queryBrand = ref<string[]>([])
            const initVehicleBrand = () => {
                const params = {
                    target: 'brand',
                    buId: queryParams.bu,
                    make: queryParams.make,
                }
                findBuVehicleList(params).then(res => {
                    queryBrand.value = res.map((item: { name: string}) => item.name)
                })
            } 

            // 获取焦点获取数据 
            const handleFocus = (target: string) => {
                const params = {
                    buId: queryParams.bu,
                    brand: queryParams.brand,
                    make: queryParams.make,
                    // typeClass: queryParams.typeClass,
                    target
                }
                intoFull(target)
                nextTick(() => {
                    findBuVehicleList(params).then(res => {
                        switch (target) {
                            case 'buId':
                                buOptions.value = getBuOptions(res)
                                break;
                            case 'make':
                                makeOptions.value = res
                                break;
                            case 'brand':
                                brandOptions.value = res
                                break;
                            case 'typeClass':
                                typeClassOptions.value = res
                                break;
                        }
                    })
                })
            }

            const handlerOpenChange = (status: any): void =>{
                if (status) {
                    openYear.value = true;
                }
            }

            const handlerPanelChange = (value: string): void =>{
                const time = moment(value).format('YYYY');
                year.value = time as string;
                openYear.value = false;
            }

            const ssHotTopInit = (data: RowProps[]): void => {
                const spread = new GC.Spread.Sheets.Workbook(document.getElementById('report') as HTMLElement);
                const sheet = spread.getActiveSheet();
                worksheet = sheet;
                workbook = spread;
                initSpread(spread, sheet, header, data);
                //是否分组列
                if (data.length > 0 && queryParams.reportView === 'TypeClass') {
                    initOutlineColumn(sheet);
                }
                //根据reportView来判断是否折叠
                if (queryParams.reportView === 'TypeClass' && queryParams.check === false) {
                    setCollapsed(worksheet, data, !queryParams.check)
                }
                // 默认展开到typeclass
                if (queryParams.reportView === 'TypeClass') {
                    setCollapsed(worksheet, data, false, 0)
                }
            }

            //因为分组列显示和普通显示切换有问题所以每次都是销毁后重新创建
            const initData = async () => {
                await wait
                //year 有值并且qm没有值，则拼接全年的日期
                const _year = year.value && qm.value.length == 0 ?  ['Q1', 'Q2', 'Q3', 'Q4'].map(item => `${year.value}.${item}`).join(',') : qm.value.map(item => `${year.value}.${item}`).join(',');
                const body = Object.assign({}, queryParams, { yearQm: _year });
                if (queryParams.bu.length) {
                    body.bu = findBuNameById(queryParams.bu)
                }
                body.brand = body.brand.length ? body.brand : queryBrand.value
                getBudgetReportData(body).then(res => {
                    const data = transformData(res, queryParams.reportView);
                    Object.assign(reportData.value, data);
                    workbook && workbook.destroy();
                    workbook = null;
                    worksheet = null;
                    ssHotTopInit(data);
                })
            }
            //根据bu获取version最新版本
            const fetchLastVersion = (make: string) => {
                getVersion(make).then(data => {
                    queryParams.kufri = data;
                    initData()
                })
            }

            const handlerResetClick = () => {
                Object.assign(queryParams, {
                    brand: [],
                    bu: defaultBuIds,
                    check: true,
                    // kufri: '',
                    // make: '',
                    reportView: 'TypeClass',
                    rtOrWs: 'RT',
                    yearQm: '',
                    submitStatus: [],
                    activeStatus: 'Active'
                })
                year.value = moment().add('month', 1).format('YYYY');
                qm.value = [getDefaultQm()];
                initData();
            }

            const fetchFindBrandByBu = async (buId: string[]): Promise<void> => {
                try {
                    const result = await getBrand({ params: { bu: buId } });
                    const brands = result.map(item => {
                        return item.brands;
                    })
                    Object.assign(brandOptions.value, brands.flat());
                } catch (e) {
                    console.log(e);
                }
            }

            const handleChangeBu = () => {
              queryParams.bu = buIdsWithChildren(buTree, queryParams.bu)
            }


            watch(() => queryParams.bu, (async value => {
                if (!inited) {
                  return
                }
                brandOptions.value = [];
                queryParams.brand = [];
                if (value) {
                    initVehicleMake()
                    initVehicleBrand()
                    const bu = findBuNameById(queryParams.bu)
                    //根据BU是AMG修改Report View 为model
                    if (bu.includes("AMG")) {
                        queryParams.reportView = 'Model';
                    }
                }
            }))

            watch(() => queryParams.brand, (value => {
                initVehicleMake()
            }))

            watch(() => queryParams.reportView, (value => {
                if (value === 'Model') {
                    queryParams.check = false;
                }
            }))

            //处理侧边栏变化后刷新spread js
            watch(() => state.sideNavCollapsed, (value) => {
                nextTick(() => {
                    workbook && workbook.refresh();
                })
            })

            let guideLineConfig: Record<string, GuideLineConfig> | undefined = undefined

            watch(() => queryParams.make, (value) => {
                initVehicleBu()
                if (value) {
                    fetchLastVersion(value)
                    queryParams.planningName = guideLineConfig?.[value].planningName
                }
                
            })

            onMounted(async () => {
                // 因为业务需求改变，make和kufri有可以请求数据
                // initData();
                guideLineConfig = await getGuideLineConfig()
                await initBuTree()
                await initVehicleMake()
                await initVehicleBu()
                inited = true
                wait.toRun?.()
            })
            onBeforeUnmount(() => {
                workbook && workbook.destroy();
            })
            return {
                handlerResetClick,
                ...toRefs(queryParams),
                reportData,

                // options
                reportViewOptions,
                rtWsOptions,
                makeOptions,
                buOptions,
                brandOptions,
                programStatusOptions,
                qmOptions,
                SHOW_PARENT,
                SHOW_ALL,
                //year q/m
                year,
                qm,
                openYear,
                handlerOpenChange,
                handlerPanelChange,
                handleChangeBu,
                initData,
                handleFocus,
            }
        }
    })
