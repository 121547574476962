import { RouteRecordRaw } from "vue-router";

export const checkingRouters: Array<RouteRecordRaw> = [
  {
    path: "/checking/checkingPlan",
    name: "checkingPlan",
    component: () => import("@/views/Checking/CheckingPlan/index.vue"),
  },
  {
    path: "/checking/approveCheckingPlan",
    name: "approveCheckingPlan",
    component: () => import("@/views/Checking/ApproveCheckingPlan/index.vue"),
  },
  {
    path: "/sourceDataManagement/AGManagement",
    name: "ag-management",
    component: () => import("@/views/Checking/CheckingManagement/index.vue"),
  },
  {
    path: "/sourceDataManagement/TAManagement",
    name: "ta-management",
    component: () => import("@/views/Checking/CheckingManagement/index.vue"),
  },
  {
    path: "/sourceDataManagement/TDTAManagement",
    name: "tdta-management",
    component: () => import("@/views/Checking/TDTAManagement/index.vue"),
  },
  {
    path: "/checking/SICCOChecking",
    name: "SICCOChecking",
    component: () => import("@/views/Checking/SICCOChecking/index.vue"),
  },
  {
    path: "/checking/SIPOChecking",
    name: "SIPOChecking",
    component: () => import("@/views/Checking/SIPOChecking/index.vue"),
  },
  {
    path: "/checking/SICCOCheckingDetail",
    name: "SICCOCheckingDetail",
    component: () => import("@/views/Checking/SICCOCheckingDetail/index.vue"),
  },
  // {
  //     path: '/spotChecking',
  //     name: 'spotChecking',
  //     component: () => import('@/views/Checking/spotChecking/index.vue'),
  // },
  {
    path: "/spotCheckingControlling",
    name: "spotCheckingControlling",
    component: () =>
      import("@/views/Checking/spotChecking/spotCheckingControlling/index.vue"),
    children: [
      {
        path: "",
        name: "spotCheckOverview",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingControlling/spotCheckOverview/index.vue"
          ),
        meta: {
          title: "Spot Check Overview",
          showInTab: true,
          permission: { path: "/spotCheckingControlling", privcd: "Scrc-01" },
        },
      },
      {
        path: "spotCheckConfirm",
        name: "spotCheckConfirm",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingControlling/spotCheckConfirm/spotCheckConfirmIndex/index.vue"
          ),
        meta: {
          title: "Sample Check Confirm",
          showInTab: true,
          permission: { path: "/spotCheckingControlling", privcd: "Scrc-02" },
        },
      },
      {
        path: "/spotCheckConfirmProgramDealer",
        name: "spotCheckConfirmProgramDealer",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingControlling/spotCheckConfirm/spotCheckProgramDealer/index.vue"
          ),
        meta: {
          showInTab: false,
        },
      },
      {
        path: "/spotCheckConfirmProgramFl",
        name: "spotCheckConfirmProgramFl",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingControlling/spotCheckConfirm/spotCheckProgramFl/index.vue"
          ),
        meta: {
          showInTab: false,
        },
      },
      {
        path: "/spotCheckConfirmDetail",
        name: "spotCheckConfirmDetail",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingControlling/spotCheckConfirm/spotCheckConfirmDetail.vue"
          ),
        meta: {
          showInTab: false,
        },
      },
      {
        path: "dealerVinView",
        name: "dealerVinView",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingControlling/spotCheckConfirm/dealerVinView/index.vue"
          ),
        meta: {
          title: "Spot Check Controlling Checked",
          showInTab: false,
        },
      },
      {
        path: "flVinView",
        name: "flVinView",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingControlling/spotCheckConfirm/flVinView/index.vue"
          ),
        meta: {
          title: "Spot Check Controlling Checked",
          showInTab: false,
        },
      },
      {
        path: "allClaimsReview",
        name: "allClaimsReview",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingControlling/allClaimsReview/index.vue"
          ),
        meta: {
          title: "All Claims Review",
          showInTab: true,
          permission: { path: "/spotCheckingControlling", privcd: "Scrc-03" },
        },
        redirect: "/spotCheckingControlling/allClaimsReview",
        children: [
          {
            path: "",
            name: "packageTracking",
            component: () =>
              import(
                "@/views/Checking/spotChecking/spotCheckingControlling/allClaimsReview/packageTracking/index.vue"
              ),
            meta: {
              title: "Package Tracking",
              showInTab: true,
              activeTabName: "allClaimsReview",
              permissionCode: "ACR01",
            },
          },
          {
            path: "programTracking",
            name: "programTracking",
            component: () =>
              import(
                "@/views/Checking/spotChecking/spotCheckingControlling/allClaimsReview/programTracking/index.vue"
              ),
            meta: {
              title: "Program Tracking",
              showInTab: true,
              activeTabName: "allClaimsReview",
              permissionCode: "ACR02",
            },
          },
          {
            path: "claimChecking",
            name: "claimChecking",
            component: () =>
              import(
                "@/views/Checking/spotChecking/spotCheckingControlling/allClaimsReview/claimTracking/index.vue"
              ),
            meta: {
              title: "Claim Checking",
              showInTab: true,
              activeTabName: "allClaimsReview",
              permissionCode: "ACR03",
            },
          },
        ],
      },
      {
        path: "payoutPreparation",
        name: "payoutPreparation",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingControlling/payoutPreparation/index.vue"
          ),
        meta: {
          title: "Payout Preparation",
          showInTab: true,
          permission: { path: "/spotCheckingControlling", privcd: "Scrc-04" },
        },
      },
      {
        path: "payoutDetail",
        name: "payoutDetail",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingControlling/payoutPreparation/payoutDetail/index.vue"
          ),
        meta: {
          showInTab: false,
        },
      },
    ],
  },

  {
    path: "/spotChecking",
    name: "spotCheckingVonder",
    component: () =>
      import("@/views/Checking/spotChecking/spotCheckingVonder/index.vue"),
    children: [
      {
        path: "",
        name: "checkingPreparation",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingVonder/checkingPreparation/index.vue"
          ),
        meta: {
          title: "Checking Preparation",
          showInTab: true,
          permission: { path: "/spotChecking", privcd: "SC-01" },
        },
      },
      {
        path: "pendingChecking",
        name: "pendingChecking",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingVonder/pendingChecking/index.vue"
          ),
        meta: {
          title: "Pending Checking",
          showInTab: true,
          permission: { path: "/spotChecking", privcd: "SC-02" },
        },
      },
      {
        path: "sampleCheck",
        name: "sampleCheck",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingVonder/sampleCheck/index.vue"
          ),
        meta: {
          title: "Sample Check",
          showInTab: true,
          permission: { path: "/spotChecking", privcd: "SC-03" },
        },
      },
      {
        path: "/sampleCheckDealer",
        name: "sampleCheckDealer",
        meta: {
          title: "Sample Check",
          showInTab: false,
        },
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingVonder/sampleCheck/sampleCheckDealer/index.vue"
          ),
      },
      {
        path: "/sampleCheckFl",
        name: "sampleCheckFinance",
        meta: {
          title: "Sample Check",
          showInTab: false,
        },
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingVonder/sampleCheck/sampleCheckFl/index.vue"
          ),
      },
      {
        path: "checked",
        name: "checked",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingVonder/checked/index.vue"
          ),
        meta: {
          title: "Checked",
          showInTab: true,
          permission: { path: "/spotChecking", privcd: "SC-04" },
        },
      },
      {
        path: "/checkedProgramDealer",
        name: "checkedProgramDealer",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingControlling/spotCheckConfirm/spotCheckProgramDealer/index.vue"
          ),
        meta: {
          showInTab: false,
        },
      },
      {
        path: "packageDetail",
        name: "packageDetail",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingVonder/checkingPreparation/packageDetail/index.vue"
          ),
        meta: {
          showInTab: false,
        },
      },
      {
        path: "packageFinanceDetail",
        name: "packageFinanceDetail",
        component: () =>
          import(
            "@/views/Checking/spotChecking/spotCheckingVonder/checkingPreparation/packageDetail/mbclDetail.vue"
          ),
        meta: {
          showInTab: false,
        },
      },
    ],
  },
  {
    path: "/spotChecking/pendingApproval",
    name: "pendingApproval",
    component: () =>
      import("@/views/Checking/spotChecking/pendingApproval/index.vue"),
    children: [
      {
        path: "",
        name: "spotCheckingPendingApproval",
        component: () =>
          import(
            "@/views/Checking/spotChecking/pendingApproval/spotCheckingPendingApproval/index.vue"
          ),
        meta: {
          title: "Spot Checking Pending Approval",
          showInTab: true,
          permission: {
            path: "/spotChecking/pendingApproval",
            privcd: "SCA-01",
          },
        },
      },
      {
        path: "approvalReview",
        name: "approvalReview",
        component: () =>
          import(
            "@/views/Checking/spotChecking/pendingApproval/approvalReview/index.vue"
          ),
        meta: {
          title: "Approval Review",
          showInTab: true,
          permission: {
            path: "/spotChecking/pendingApproval",
            privcd: "SCA-02",
          },
        },
      },
      {
        path: "payoutRound",
        name: "pendingApprovalPayoutRound",
        component: () =>
          import(
            "@/views/Checking/spotChecking/pendingApproval/payoutRound/index.vue"
          ),
        meta: {
          showInTab: false,
        },
      },
      {
        path: "payoutDetail",
        name: "pendingApprovalPayoutDetail",
        component: () =>
          import(
            "@/views/Checking/spotChecking/pendingApproval/payoutDetail/index.vue"
          ),
        meta: {
          showInTab: false,
        },
      },
    ],
  },
  {
    path: "/checking/SIPOCheckingDetail",
    name: "SIPOCheckingDetail",
    component: () => import("@/views/Checking/SIPOCheckingDetail/index.vue"),
  },
  {
    path: "/checking/checkingDocCollection",
    name: "checkingDocCollection",
    component: () =>
      import("@/views/Checking/siccoCheckingDocumentCollection/index.vue"),
  },
  {
    path: "/checking/claimDelayRuleSetup",
    name: "claimDelayRuleSetup",
    component: () => import("@/views/Checking/claimDelayRuleSetup/index.vue"),
  },
  {
    path: "/checking/delayCallbackRuleSetup",
    name: "delayCallbackRuleSetup",
    component: () =>
      import("@/views/Checking/delayCallbackRuleSetup/index.vue"),
  },
  {
    path: "/checking/exemption",
    name: "exemption",
    component: () => import("@/views/Checking/exemption/index.vue"),
  },
  {
    path: "/checking/exemptionSetup",
    name: "exemptionSetup",
    component: () => import("@/views/Checking/exemption/setup.vue"),
  },
  {
    path: "/sourceDataManagement/demoCarClaimGenerate",
    name: "demoCarClaimGenerate",
    component: () => import("@/views/Checking/DemoCarClaim/index.vue"),
  },
];
