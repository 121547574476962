import { Module } from 'vuex';
import { GlobalDataProps } from '@/store/index';
import { PayoutBatchTableData } from "@/views/Payment/BU/types"
import { DataProps as BreakdownDataProps }from '@/views/Payment/DealerBreakdown/type' 
export interface PaymentDataProps {
    approvealTableCount: number;
    selectProgramRows: PayoutBatchTableData[];
    // Breakdown传到详情页的参数
    breakdownRow: BreakdownDataProps;
    // entitySiPayoutApplication传到Dealer Breakdown Report
    breakdownParams: { payoutRoumd: string; entity: string };
    // 上传文件最大数量
    maxFileCount: number;
    // 上传文件最大附件大小
    maxFileSize: number;
}

const payment: Module<PaymentDataProps, GlobalDataProps> = {
    namespaced: true,
    state: {
        approvealTableCount: 0,
        selectProgramRows: [],
        breakdownRow: {id: ''},
        breakdownParams: { payoutRoumd: '', entity: '' },
        maxFileCount: 5,
        maxFileSize: 10
    },
    mutations: {
        updateApprovealTableCount: (state, approvealTableCount) => {
            state.approvealTableCount = approvealTableCount
        },
        updateSelectProgramRows: (state,selectProgramRows) =>{
            state.selectProgramRows = selectProgramRows
        },
        updateBreakdown: (state, curRow) => {
            state.breakdownRow = curRow
        },
        updateBreakdownParams: (state, params) => {
            state.breakdownParams = params
        }
    }
}
export default payment;