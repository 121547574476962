import { reactive, onMounted, toRefs } from 'vue';

interface TablePosition {
    tableHeight: number;
}

/**
 *
 * @param othersDom  分页的class或者其他的 class .pagination-container
 * @param isOthersDom 是否获取其他的dom
 */
function useTableHeight(isOthersDom = true, othersDom = '.pagination-container', isResizeHandler = false) {
    const tablePosition = reactive<TablePosition>({tableHeight: 200});
    const resizeHandler = (): void => {
        try {
            let paginationHeight = 0;
            const clientHeight: number = document.body.clientHeight;
            const tableOffsetTop: number = (document.querySelector('.ant-table') as HTMLDivElement).getBoundingClientRect().top;
            const tableTheadHeight: number = (document.querySelector('.ant-table-thead') as HTMLDivElement).offsetHeight;
            if (othersDom && isOthersDom) {
                paginationHeight = (document.querySelector(othersDom) as HTMLUListElement)?.offsetHeight;
            }
            tablePosition.tableHeight = clientHeight - tableOffsetTop - paginationHeight - tableTheadHeight - 5;
        } catch (e) {
            console.log(e)
        }
    }
    onMounted(() => {
        window.addEventListener('resize', resizeHandler);
        resizeHandler();
    })
    if (isResizeHandler) {
        resizeHandler()
    }
    return toRefs(tablePosition);
}

export default useTableHeight;
