import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
//reset.scss 覆盖ant的默认reset样式列如：dl，ul ol 的默认 margin-bottom: 1em;
import './assets/css/reset.scss'
import './assets/css/preview.scss'

//echarts
import * as echarts from 'echarts'
// 国际化
import i18n from './locales/index'
// TODO 登录拦截 勿删
import permission from './utils/permission'
import { btnPermissions } from './utils'
//公用的组件全局注册
import Breadcrumb from "./components/Breadcrumb.vue"
import MainWrapper from "./components/MainWrapper.vue"

declare global {
    interface Window {
      wangEditor?: any;
    }
}

/* TODO 登录拦截 勿删 */ 
const app = createApp(App).use(store).use(router).use(i18n).use(Antd);
[Breadcrumb, MainWrapper].forEach(component => {
    app.component(component.name, component);
})
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$btnPermissions = btnPermissions;
app.mount('#app')

permission()