import { RouteRecordRaw } from "vue-router";

export const rvRouters: Array<RouteRecordRaw> = [
  {
    path: "/retailValidation",
    name: "Retail Validation Dealer",
    component: () => import("@/views/RetailValidation/Dealer/index.vue"),
  },
  {
    path: "/retailValidation/sicco",
    name: "Retail Validation Sicco",
    component: () => import("@/views/RetailValidation/Sicco/index.vue"),
  },
  {
    path: "/retailValidation/sipo",
    name: "Retail Validation Sipo",
    component: () => import("@/views/RetailValidation/Sipo/index.vue"),
  },
  {
    path: "/retailValidation/controlling",
    name: "Retail Validation Controlling",
    component: () => import("@/views/RetailValidation/Controlling/index.vue"),
  },
  {
    path: "/retailValidation/dataAdmin",
    name: "Retail Validation DataAdmin",
    component: () =>
      import("@/views/RetailValidation/DataAdmin/SystemParameter.vue"),
  },
  {
    path: "/retailValidation/retailData",
    name: "Retail Data Import",
    component: () => import("@/views/RetailValidation/RetailData/index.vue"),
  },
  // cr Dealer Communication
  {
    path: "/retailValidation/taskDistribution",
    name: "Task Distribution Overview",
    component: () => import("@/views/RetailValidation/Communication/TaskDistribution/index.vue"),
  },
  {
    path: "/retailValidation/taskDistribution/detail",
    name: "Task Distribution Detail",
    component: () => import("@/views/RetailValidation/Communication/TaskDistribution/Detail/index.vue"),
  },
  {
    path: "/retailValidation/taskOverview",
    name: "Task Overview Region",
    component: () => import("@/views/RetailValidation/Communication/TaskOverview/index.vue"),
  },
  {
    path: "/retailValidation/taskOverview/detail",
    name: "Task Overview Detail",
    component: () => import("@/views/RetailValidation/Communication/TaskOverview/detail.vue"),
  },
  {
    path: "/retailValidation/dataDocumentReceive",
    name: "Data Document Receive",
    component: () => import("@/views/RetailValidation/Communication/DataDocumentReceive/index.vue"),
  },
];
