import { RouteRecordRaw } from 'vue-router';

export const guiDangRouters: Array<RouteRecordRaw> = [
  {
    path: '/guiDang',
    name: 'GuiDang',
    component: () => import('@/views/Guidang/GuiDangIndex/index.vue')
  },
  {
    path: '/archiveDocument',
    name: 'Archive Document',
    component: () => import('@/views/Guidang/ArchiveDocument/index.vue')
  }
];
