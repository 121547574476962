/*
 * @Author: guowu
 * @Date: 2021-11-15 13:43:50
 * @LastEditTime: 2021-11-29 17:39:27
 */
import axios from 'axios'
import { AxiosRequestConfig } from '@/API/types'
import { message } from "ant-design-vue"

export default (options: AxiosRequestConfig) => {
    const { url, method = 'get', params, fileName = '' } = options;
    const config: any = {
        url: url,
        method,
        responseType: "blob",

    };
    if (method === "get") {
        config["params"] = params;
    } else {
        config["data"] = params;
    }
    console.log(config,'config');
    return new Promise((resolve, reject) => {
        axios(config)
        .then((res: any) => {
            if (res.data.size > 0) {
                let userFileName = ''
                if (!fileName) {
                    console.log(res.headers)
                    userFileName = res.headers['content-disposition'].split('filename=')[1]
                    if (userFileName && userFileName.includes('"')) userFileName = userFileName.slice(1, -1);
                    userFileName = decodeURIComponent(userFileName)
                } else {
                    userFileName = fileName
                }

                // 文件格式
                const contentType = res.headers['content-type']
                const blob = new Blob([res.data], { type: contentType }) //指定格式为vnd.ms-excel
                const downloadElement = document.createElement('a')
                const href = window.URL.createObjectURL(blob) //创建下载的链接
                downloadElement.href = href
                downloadElement.download = !userFileName ? Date.now().toString() : userFileName  //下载后文件名
                document.body.appendChild(downloadElement)
                downloadElement.click() //点击下载
                document.body.removeChild(downloadElement) //下载完成移除元素
                window.URL.revokeObjectURL(href) //释放掉blob对象
                resolve(res)
            } else {
                message.error('Data is null!')
            }

        })
        .catch(function (error: string) {
            console.log(error)
            return reject(error)
        })
    })
}

