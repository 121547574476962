import { Module } from 'vuex';
import { GlobalDataProps } from '@/store/index';
export interface ApproveDataProps {
    programIds: string[];
    pendingApproveCount: number;
}

const approve: Module<ApproveDataProps, GlobalDataProps> = {
    namespaced: true,
    state: {
        programIds: [],
        pendingApproveCount: 0
    },
    mutations: {
        updateProgramIds(state, program) {
            state.programIds = program;
        },
        updatePendingApproveCount(state, count) {
            state.pendingApproveCount = count;
        }
    }
}

export default approve;
