import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "new-offer bg-fff p-10 m-t-10" }
const _hoisted_2 = { class: "flex-div align-s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_navbar = _resolveComponent("navbar")!
  const _component_AddOfferLeft = _resolveComponent("AddOfferLeft")!
  const _component_AddOfferRight = _resolveComponent("AddOfferRight")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Breadcrumb, { dataSource: [{name: 'Offer Type Setting'}] }),
    _createVNode(_component_navbar, {
      navArr: _ctx.navArr,
      class: "bg-fff p-10 m-t-10"
    }, null, 8, ["navArr"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AddOfferLeft),
        _createVNode(_component_AddOfferRight)
      ])
    ])
  ]))
}