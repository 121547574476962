import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "root" }
const _hoisted_2 = { class: "d-f" }
const _hoisted_3 = { style: {"width":"0"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_SideNav = _resolveComponent("SideNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainWrapper = _resolveComponent("MainWrapper")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$store.state.Authorization && _ctx.$route.path !== '/login' && _ctx.$route.meta && !_ctx.$route.meta.isMobile)
      ? (_openBlock(), _createBlock(_component_a_spin, {
          key: 0,
          spinning: _ctx.$store.state.spinning || _ctx.$store.state.pageSpinning,
          wrapperClassName: "spingWrapper"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MainWrapper, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  name: "header",
                  style: _normalizeStyle({
            background: `url(${require('@/assets/images/header-bg.jpg')})`
          })
                }, [
                  _createVNode(_component_Header)
                ], 4),
                _createElementVNode("main", _hoisted_2, [
                  _createVNode(_component_SideNav),
                  _createElementVNode("aside", _hoisted_3, [
                    _createVNode(_component_router_view, null, {
                      default: _withCtx(({ Component }) => [
                        (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.keepAliveComponentNames }, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                        ], 1032, ["include"]))
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["spinning"]))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 }, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.keepAliveComponentNames }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ], 1032, ["include"]))
          ]),
          _: 1
        }))
  ]))
}