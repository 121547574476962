
import { defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css'
import GCC from '@grapecity/spread-sheets'
import GC from '@grapecity/spread-sheets/dist/gc.spread.sheets.d'
import { IO } from "@grapecity/spread-excelio"
import saveAs from 'file-saver'
import axios from 'axios'
export default defineComponent({
    setup () {
        const store = useStore()
        const excelIo = new IO()
        let workbook: GCC.Spread.Sheets.Workbook
        const spreadInit = (spread: GCC.Spread.Sheets.Workbook) => {
            const excelFilePath = '/pgapi/files/test';
            axios({
                method: 'GET',
                url: excelFilePath, 
                responseType: 'blob',
                data: '',
                headers: {
                    'accept': '*/*',
                    'Authorization': store.state.Authorization,
                    'X-USER-INFO': 'eyJ1c3JpZCI6ImZmODA4MDgxNzYxOGFmZjgwMTc2MThiMGJmZGQwMDAxIiwidXNybm0iOiJPQVNJU0lUQURNSU4wMiIsImZ1bG5tIjoiT0VNIElUIEFkbWluIiwiZW1haWwiOiJPQVNJUzA5QE9BU0lTLmNvbSIsInVzcnRwIjoxMDAwMSwidXNydHBubSI6Ik9FTSIsIm9yZ2lkIjoiMmRlOWY0NzRhODdhNDRhMGExNzkzOGIyMWJkYzg3ZTkiLCJvcmdubWNuIjpudWxsLCJvcmdubWVuIjpudWxsLCJzdGF0ZSI6dHJ1ZSwiY3J0ZWQiOjE2MDY4MTc1OTc2NDcsImNydGJ5IjoiT0FTSVNJVEFETUlOMDkiLCJjcnRieW5tIjoiT0FTSVNJVEFETUlOMDkifQ=='
                }
            }).then((res: any) => {
                const blob = new Blob([res.data], {type: 'application/vnd.ms-excel;charset=utf-8'})
                // convert Excel to JSON
                excelIo.open(blob, function (json: any) {
                    const workbookObj = json;
                    spread.fromJSON(workbookObj);
                    const sheet = workbook.getActiveSheet();
                    sheet.addRows(3, 1);
                })
            })
        }
        const handleDownload = () => {
            const json = workbook.toJSON();
            // here is excel IO API
            excelIo.save(json, function (blob: any) {
                saveAs.saveAs(blob, 'export.xlsx');
            }, function (e: any) {
                // process error
                console.log(e);
            });
        }
        onMounted(() => {
            const spread = new GCC.Spread.Sheets.Workbook(document.getElementById('ssHost') as HTMLElement);
            const sheet = spread.getActiveSheet();
            workbook = spread
            spreadInit(spread)
        })
        return {
            handleDownload
        }
    }
})
