
import { defineComponent, PropType } from 'vue';
import { NavbarProps } from '@/API/types';
export default defineComponent({
  props: {
    navArr: {
      default: () => [],
      type: Array as PropType<NavbarProps[]>
    },
    activeKey: {
      type: String
    }
  },
  name: 'Navigation Bar',
  setup(props, { emit }) {
    const routeSelect = (id: any) => {
      emit('update:activeKey', id);
    };
    return { routeSelect };
  }
});
