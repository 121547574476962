import { ref, reactive, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { getProgramAllStatus } from '@/API/program';

const navToolTipContentMaps = {
  fulfill: 'Content saved',
  reject: 'Content not saved or generated',
  pending: 'Content needs to be confirmed'
};

const AllNavStatusMaps = {
  // Design
  Temporary: 'reject',
  NonTemporary: 'fulfill',
  // Combinability parameter
  Draft: 'reject',
  Default: 'fulfill',
  Confirmed: 'fulfill',
  ToBeConfirmed: 'pending',
  // Budget
  N: 'reject',
  Y: 'fulfill'
};

export default function useNavBar() {
  const store = useStore();
  const route = useRoute();
  const activeKey = ref('1');
  const navArr = ref<any[]>();

  const currentProgramId = computed(() => {
    return store.state.pgmData.programId;
  });

  navArr.value = [
    {
      title: 'Design',
      key: 'design',
      path: '/programdesign/design',
      id: '1',
      query: {
        pgmId: !route.query.pgmId ? '' : route.query.pgmId
      }
    },
    {
      title: 'Combinability',
      key: 'combinability',
      id: '4',
      path: '/programdesign/combinabilitysetting',
      query: {
        pgmId: !route.query.pgmId ? '' : route.query.pgmId
      }
    },
    {
      title: 'Parameter',
      key: 'parameter',
      path: '/parametersetup',
      id: '3',
      query: {
        pgmId: !route.query.pgmId ? store.state.pgmData.programId : route.query.pgmId
      }
    },
    {
      title: 'Budget',
      key: 'budget',
      path: '/budgetinput',
      id: '2',
      query: {
        pgmId: !route.query.pgmId ? store.state.pgmData.programId : route.query.pgmId
      }
    }
  ];

  watch(currentProgramId, (newVal: any) => {
    if (newVal !== '' && newVal !== undefined) {
      navArr.value?.forEach((nav: any) => {
        nav.query.pgmId = newVal;
      });
      console.log('watch navArr.value', navArr.value);
    }
  });

  const changeNavStatus = (tabStatus: any) => {
    navArr.value?.forEach((item: any) => {
      const currentNavStatus =
        item.key === 'design' && tabStatus.design !== 'Temporary' ? 'NonTemporary' : tabStatus[item.key];
      item['status'] = AllNavStatusMaps[currentNavStatus];
      if (tabStatus.design === 'Temporary') {
        if (item.key !== 'design') {
          item.disabled = true;
        }
      } else {
        item.disabled = false;
      }
      item.toolTipContent = item.status ? navToolTipContentMaps[item.status] : null;
    });
  };

  const programAllStatus = ref({
    budget: 'Y',
    combinability: 'Confirmed',
    design: 'Sales Submitted',
    parameter: 'Confirmed'
  });

  const getTabStatus = async () => {
    const res = await getProgramAllStatus(currentProgramId.value);
    Object.assign(programAllStatus.value, res);
    changeNavStatus(res);
  };

  return {
    activeKey,
    navArr,
    changeNavStatus,
    getTabStatus,
    programAllStatus
  };
}
