
import { defineComponent, onMounted, ref } from 'vue'
import Navbar from "@/views/Budget/ConfigurationNav.vue"
import { useRoute } from 'vue-router'
import { NavbarProps } from './types'
import { useStore } from 'vuex'
import { getFleetByOffetId } from '@/API/budget'
import useTableHeight from "@/hooks/useTableHeight";
const columns = [
  {
    title: "Budget Data Fields",
    dataIndex: "budgetDataFields",
    key: "budgetDataFields",
    width: "40%",
    // align: 'center'
  },
  {
    title: "AUTO/MANUAL",
    key: "auto",
    width: "30%",
    // align: 'center',
    slots: { customRender: "auto" },
  },
 
  {
    title: "Budget Form",
    dataIndex: "operation",
    key: "operation",
    width: "30%",
    // align: 'center',
    slots: { customRender: "budgetForm" },
  },
  
];
export default defineComponent({
    components: { Navbar, },
    name: '',
    setup() {
        const route = useRoute()
        const store = useStore()
        const navArr = ref<NavbarProps[]>()
        const { tableHeight } = useTableHeight(false);
        const pathData = ref('')
        navArr.value = [
            {
                title: 'BUDGET TEMPLATE',
                path: pathData.value,
                icon: store.state.maintain ? 'icon-remove-circle' : 'icon-circle-1',
                id: '1'
            },
        ]
        const dataSource = ref()
        const initData = () => {
          getFleetByOffetId(route.params.id as string).then(res => {
            dataSource.value = res
          })
        }

        onMounted(() => {
          initData()
        })

        return {
            navArr,
            columns,
            dataSource,
            tableHeight,
        }

    }
 });
