import { RouteRecordRaw } from "vue-router";
export const reportingRouters: RouteRecordRaw[] = [
  {
    path: "/SIConfigurationReport",
    name: "SI Configuration Report",
    component: () => import("@/views/Reporting/Index.vue"),
  },
  {
    path: "/ControllerReporting",
    name: "Controller Reporting",
    component: () => import("@/views/Reporting/ControllerReporting.vue"),
  },
  {
    path: "/SISummaryByVehicle",
    name: "SI Summary By Vehicle",
    component: () => import("@/views/Reporting/SISummaryByVehicle.vue"),
  },
  {
    path: "/SIBudgetReport",
    name: "SI Budget Report",
    component: () => import("@/views/Reporting/SIBudgetReport.vue"),
  },
  {
    path: "/GuidelineReportSales",
    name: "Guideline Report Sales",
    component: () => import("@/views/Reporting/GuidelineReportSales.vue"),
  },
];
