import { RouteRecordRaw } from 'vue-router';

export const communicationRouters: Array<RouteRecordRaw> = [
    {
        path: '/communication/appeal',
        name: 'appeal',
        component: () =>
            import('@/views/Communication/Appeal/MainPage/index.vue'),
    },
    {
        path: '/communication/appeal/history',
        name: 'appealHistory',
        component: () =>
            import('@/views/Communication/Appeal/History/index.vue'),
    },
    {
        path: '/communication/appeal/detail',
        name: 'appealDetail',
        component: () =>
            import('@/views/Communication/Appeal/Detail/index.vue'),
    },
];
