import { Module } from 'vuex';
import { GlobalDataProps } from '@/store/index';
// status=unconfirm&offerType=fa&bu=&brand=MB&customerType=&offer=
interface  FinanceProps {
    stateParmas: {
        status: string;
        offerType: string;
        bu: string;
        brand: string;
        customerType: string;
        offer: string;
    };
   
}

const finance: Module<FinanceProps, GlobalDataProps> = {
    namespaced: true,
    state: {
        stateParmas: {
            status: '',
            offer: '',
            offerType: '',
            brand: '',
            bu: '',
            customerType: '',
        }
        
    },
    mutations: {
        updateStateParmast: (state, stateParmas) => {
            state.stateParmas = stateParmas
        }
    }
}

export default finance