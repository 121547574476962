import {
  fetchGet,
  fetchGetMess,
  fetchPost,
  fetchPostPic,
  fetchPut,
} from "./index";
import axios from "axios";
// 获取 fa maintain的数据

// 获取Approval Chain Setting列表
export const getApprovalChainSetting = (roleType: string) => {
  console.log("roleType====", roleType);
  return fetchPostPic("/apapi/RoleSetting/approvalRole?roleType=" + roleType);
};
// 插入角色- FL
export const insertFLRole = (params = {}) => {
  return fetchPostPic("/apapi/RoleSetting/insertFLRole", params);
};
// 插入角色- Normal
export const insertNormalRole = (params = {}) => {
  return fetchPostPic("/apapi/RoleSetting/insertNormalRole", params);
};
// 更新角色- Normal
export const updateNormalRole = (params = {}) => {
  return fetchPostPic("/apapi/RoleSetting/updateNormalRole", params);
};
// 更新角色- FL
export const updateFLRole = (params = {}) => {
  return fetchPost("/apapi/RoleSetting/updateFLRole", params);
};
// 获取Role编辑详情
export const getRoleDetail = (roleId: string, roleType: string) => {
  console.log("roleType====", roleId, roleType);
  return fetchPostPic(
    "/apapi/RoleSetting/editRole?roleId=" + roleId + "&roleType=" + roleType
  );
};
// 删除Role
export const deleteRoleDetail = (params = {}) => {
  return fetchPostPic("/apapi/RoleSetting/deleteRole", params);
};
//下载fl
export const downloadNormal = (payoutCode: string) => {
  return axios.get("/apapi/RoleSetting/downloadApprovalRoleDetailExcel", {
    params: { payoutCode },
    responseType: "blob",
  });
};
//下载Normal
export const downloadFL = (payoutCode: string) => {
  return axios.get("/apapi/RoleSetting/downloadFLRoleDetailExcel", {
    params: { payoutCode },
    responseType: "blob",
  });
};
// 保存fa setting 的配置
export const saveFaSetting = (
  brand: string,
  buId: string,
  sheetName: string,
  programId: string | number,
  tableId: string | number,
  params = {}
) => {
  return fetchPost(
    `pgapi/setting/commu_stting/${brand}/${buId}/${sheetName}/${programId}/${tableId}/save`,
    params
  );
};
// 查询version 信息
export const getVersionList = (params = {}) => {
  return fetchGet("/pgapi/prog/setting/fa/getVersionList", params);
};
//#region [program]
// 查询program列表
export const getListProgramApproval = (params = {}) => {
  return fetchPost("/apapi/approvalSetting/listProgramApproval", params);
};
// 切换工作流状态
export const ToActiveApprovalChain = (chainId: string) => {
  return fetchGetMess(`/apapi/approvalSetting/activeApprovalChain/${chainId}`);
};
export const ToInactiveApprovalChain = (chainId: string) => {
  return fetchGetMess(
    `/apapi/approvalSetting/inactiveApprovalChain/${chainId}`
  );
};
// 新增工作流
export const addNewApprovalChain = (params = {}) => {
  return fetchPostPic("/apapi/approvalSetting/addNewApprovalChain", params);
};
export const newVersionForCopy = (params = {}) => {
  return fetchPostPic("/apapi/approvalSetting/newVersionForCopy", params);
};
//#endregion
//#region [payout]
// 查询FL列表
export const getListFLPayoutApproval = (params = {}) => {
  return fetchPost("/apapi/approvalSetting/listFLPayoutApproval", params);
};
// 查询Dealer列表
export const getListDealerPayoutApproval = (params = {}) => {
  return fetchPost("/apapi/approvalSetting/listDealerPayoutApproval", params);
};
//#endregion

// 获取审批流详细信息
export const getApprovalChainDetail = (chainId: string) => {
  return fetchGet(
    "/apapi/approvalSetting/getDetailApprovalChainInfo?approvalBusinessId=" +
      chainId
  );
};
// 获取当前生效日期
export const getEffectiveInfo = (businessId: string) => {
  return fetchGet(
    "/apapi/approvalSetting/effectiveInfoSearch?businessId=" + businessId
  );
};
// 获取approvalRole下拉列表--by user
export const getAllRolesByUser = (chainId = "", userId: string) => {
  return fetchGet("/apapi/approvalSetting/getAllRoleSelective", {
    params: { chainId, userId },
  });
};
// 获取user下拉列表--by role
export const getAllUsersByRole = (
  chainId = "",
  roleId = "",
  searchKey = ""
) => {
  return fetchGet("/apapi/approvalSetting/getAllUserSelective", {
    params: { chainId, roleId, searchKey },
  });
};
// 保存审批流- Normal
export const saveApprovalRole = (params = {}) => {
  return fetchPostPic("/apapi/approvalSetting/saveData", params);
};
//校验编辑审批流
export const editableCheckApprovalChain = (chainId: string) => {
  return fetchGetMess("/apapi/approvalSetting/editableCheck", {
    params: { chainId },
  });
};
//新增审批流-version
export const addApprovalChainNewVersion = (params = {}) => {
  return fetchPostPic("/apapi/approvalSetting/newVersion", params);
};
// 查询有效版本等信息
export const getEffectiveDate = (businessId: string) => {
  return fetchGet("/apapi/approvalSetting/getEffectiveDate", {
    params: { businessId },
  });
};

export const getCopiedApprovalChainName = (type: number) => {
  return fetchGet("/apapi/approvalSetting/getEffectiveDate", {
    params: { type },
  });
};

// 查询BU列表
export const getBuList = () => {
  return fetchGet("/usapi/noAuth/bu/list");
};
// 查询BU树
export const getBuTreeList = () => {
  return fetchGet("/usapi/noAuth/bu/treeList");
};
// 查询user
export const getUserList = (userName: string) => {
  return fetchGet(`/usapi/users/${userName}`);
};