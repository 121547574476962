import { ref, watch, onMounted } from 'vue';

export default (height: number) => {
    //* 设置表格
    const docHeight = ref<number>(document.documentElement.clientHeight);
    const usedHeight = height; // 从上到下其他固定高度的部分占据的空间
    const tableHeight = ref<number>(docHeight.value - usedHeight);
    watch(docHeight, (newVal) => {
        tableHeight.value = newVal - usedHeight;
    });
    onMounted(() => {
        window.onresize = () => {
            docHeight.value = document.documentElement.clientHeight;
            // tableHeight.value = docHeight.value - usedHeight;
        };
    });
    return tableHeight;
};
