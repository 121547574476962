import { RouteRecordRaw } from 'vue-router';

export const localRegistrationRouters: Array<RouteRecordRaw> = [
    {
        path: '/localRegistration/setup/keyModelsSet',
        name: 'Key Models Set',
        component: () =>
            import('@/views/LocalRegistration/Setup/KeyModelsSet.vue'),
    },
    {
        path: '/localRegistration/setup/uploadCurveSelection',
        name: 'Upload curve selection of restricted cities',
        component: () =>
            import('@/views/LocalRegistration/Setup/UploadCurveSelection.vue'),
    },
    {
        path: '/localRegistration/setup/uploadCurveSelection/failedReason',
        name: 'Curve Selection Upload Failed Reason',
        component: () =>
            import(
                '@/views/LocalRegistration/Setup/CurveUploadFailedReason.vue'
            ),
    },
    {
        path: '/localRegistration/setup/restrictedCitySet',
        name: 'Restricted City Set',
        component: () =>
            import('@/views/LocalRegistration/Setup/RestrictedCitySet.vue'),
    },
    {
        path: '/localRegistration/setup/registrationValiditySet',
        name: 'Registration Validity Set',
        component: () =>
            import(
                '@/views/LocalRegistration/Setup/RegistrationValiditySet.vue'
            ),
    },
    {
        path: '/localRegistration/setup/licensePlateMappingCity',
        name: 'License Plate Mapping City',
        component: () =>
            import(
                '@/views/LocalRegistration/Setup/LicensePlateMappingCity.vue'
            ),
    },
    {
        path: '/localRegistration/focusAreaSet/home',
        name: 'Focus Area Set Home',
        component: () =>
            import('@/views/LocalRegistration/FocusAreaSet/index.vue'),
    },
    {
        path: '/localRegistration/focusAreaSet/dealerAdjustment',
        name: 'Focus Area Set Dealer Adjustment',
        component: () =>
            import(
                '@/views/LocalRegistration/FocusAreaSet/DealerAdjustment.vue'
            ),
    },
    {
        path: '/localRegistration/focusAreaSet/dealerAdjustment/add',
        name: 'Focus Area Set Dealer Adjustment Add',
        component: () =>
            import(
                '@/views/LocalRegistration/FocusAreaSet/DealerAdjustmentAdd.vue'
            ),
    },
    {
        path: '/localRegistration/focusAreaSet/dealerAdjustment/add2',
        name: 'Focus Area Set Dealer Adjustment Add2',
        component: () =>
            import(
                '@/views/LocalRegistration/FocusAreaSet/DealerAdjustmentAdd2.vue'
            ),
    },
    {
        path: '/localRegistration/focusAreaSet/dealerAdjustment/delete',
        name: 'Focus Area Set Dealer Adjustment Delete',
        component: () =>
            import(
                '@/views/LocalRegistration/FocusAreaSet/DealerAdjustmentDelete.vue'
            ),
        meta: {
            keepAlive: false,
        },
    },
    {
        path: '/localRegistration/focusAreaSet/dealerAdjustment/delete2',
        name: 'Focus Area Set Dealer Adjustment Delete2',
        component: () =>
            import(
                '@/views/LocalRegistration/FocusAreaSet/DealerAdjustmentDelete2.vue'
            ),
    },
    {
        path: '/localRegistration/focusAreaSet/dealerAdjustment/edit',
        name: 'Focus Area Set Dealer Adjustment Edit',
        component: () =>
            import(
                '@/views/LocalRegistration/FocusAreaSet/QueryFocusAreaList/DealerAdjustmentEdit.vue'
            ),
    },
    {
        path: '/localRegistration/focusAreaSet/dealerAdjustment/edit2',
        name: 'Focus Area Set Dealer Adjustment Edit2',
        component: () =>
            import(
                '@/views/LocalRegistration/FocusAreaSet/QueryFocusAreaList/DealerAdjustmentEdit2.vue'
            ),
    },
    {
        path: '/localRegistration/focusAreaList',
        name: 'Query Focus Area List',
        component: () =>
            import(
                '@/views/LocalRegistration/FocusAreaSet/QueryFocusAreaList/index.vue'
            ),
        children: [
            {
                path: 'query',
                name: 'Query Focus Area List Query',
                component: () =>
                    import(
                        '@/views/LocalRegistration/FocusAreaSet/QueryFocusAreaList/Query.vue'
                    ),
                meta: {
                    title: 'Query',
                    showInTab: true,
                },
            },
            {
                path: 'changeHistory',
                name: 'Query Focus Area List Change History',
                component: () =>
                    import(
                        '@/views/LocalRegistration/FocusAreaSet/QueryFocusAreaList/ChangeHistory.vue'
                    ),
                meta: {
                    title: 'Change History',
                    showInTab: true,
                },
            },
        ],
    },
    {
        path: '/localRegistration/focusAreaRuleSet',
        name: 'Focus Area Rule Set',
        component: () =>
            import(
                '@/views/LocalRegistration/FocusAreaSet/FocusAreaRuleSet/index.vue'
            ),
    },
    {
        path: '/localRegistration/dataUpload/exemption',
        name: 'Local Registration Data Upload Exemption',
        component: () =>
            import(
                '@/views/LocalRegistration/LocalRegistrationDataUpload/Exemption/index.vue'
            ),
    },
    {
        path: '/localRegistration/dataUpload/exemption/:mode',
        name: 'Local Registration Data Upload Exemption Editor',
        component: () =>
            import(
                '@/views/LocalRegistration/LocalRegistrationDataUpload/Exemption/Editor.vue'
            ),
    },
    {
        path: '/localRegistration/dataUpload/thirdPartyData',
        name: 'Local Registration Data Upload Third Party Data',
        component: () =>
            import(
                '@/views/LocalRegistration/LocalRegistrationDataUpload/ThirdPartData/index.vue'
            ),
    },
    {
        path: '/localRegistration/dataUpload/thirdPartyData/failedReason',
        name: 'Local Registration Data Upload Third Party Data Failed Reason',
        component: () =>
            import(
                '@/views/LocalRegistration/LocalRegistrationDataUpload/ThirdPartData/FailedReason.vue'
            ),
    },
    {
        path: '/localRegistration/setup/rateCalculationRuleSet',
        name: 'Local Registration Rate Calculation Rule Set',
        component: () =>
            import(
                '@/views/LocalRegistration/Setup/RateCalculationRuleSet/index.vue'
            ),
    },
    {
        path: '/localRegistration/setup/rateCalculationRuleSet/:mode',
        name: 'Local Registration Rate Calculation Rule Editor',
        component: () =>
            import(
                '@/views/LocalRegistration/Setup/RateCalculationRuleSet/Editor.vue'
            ),
    },
    {
        path: '/localRegistration/registrationgResult',
        name: 'Local Registration Registrationg Result',
        component: () =>
            import('@/views/LocalRegistration/RegistrationResult/index.vue'),
        children: [
            {
                path: 'checkingResult',
                name: 'Local Registration Registrationg Result Checking Result',
                component: () =>
                    import(
                        '@/views/LocalRegistration/RegistrationResult/CheckingResult.vue'
                    ),
                meta: {
                    title: 'Checking Result',
                    showInTab: true,
                },
            },
            {
                path: 'comparison',
                name: 'Local Registration Registrationg Result Comparison',
                component: () =>
                    import(
                        '@/views/LocalRegistration/RegistrationResult/Comparison.vue'
                    ),
                meta: {
                    title: 'Comparison',
                    showInTab: true,
                },
            },
        ],
    },
    {
        path: '/localRegistration/dealerCheckingResult',
        name: 'Local Registration Dealer Checking Result',
        component: () =>
            import('@/views/LocalRegistration/DealerCheckingResult/index.vue'),
    },
    {
        path: '/localRegistration/centralReport',
        name: 'Local Registration Reporting Central',
        component: () =>
            import('@/views/LocalRegistrationReport/Central/index.vue'),
    },
    {
        path: '/localRegistration/regionReport',
        name: 'Local Registration Reporting Region',
        component: () =>
            import('@/views/LocalRegistrationReport/Region/index.vue'),
    },
];
