import {
    BudgetTableDataCell,
    BudgetTableHeaderCell,
    BudgetTableProps,
    SmallTableProps,
    OfferType
} from "@/views/Budget/BudgetInput/types";
import GC from "@grapecity/spread-sheets";
import { message } from "ant-design-vue";
import { toNumber } from "@/utils/approve";


type BudgetProps = BudgetTableProps;

interface HitInfo {
    x: number;
    y: number;
    row: any;
    col: any;
    cellStyle: any;
    cellRect: any;
    sheetArea: any;
    sheet: any;
}

//tip 提示
export class TipCellType extends GC.Spread.Sheets.CellTypes.ColumnHeader {
    header: BudgetTableHeaderCell[];
    tipText: string;
    toolTipElement?: HTMLDivElement;

    constructor(header: BudgetTableHeaderCell[], tipText: string, toolTipElement: any = null) {
        super();
        this.header = header;
        this.tipText = tipText;
        toolTipElement = null;
    }


    getHitInfo(x: number, y: number, cellStyle: any, cellRect: any, context: any): HitInfo {
        return {
            x: x,
            y: y,
            row: context.row,
            col: context.col,
            cellStyle: cellStyle,
            cellRect: cellRect,
            sheetArea: context.sheetArea,
            sheet: context.sheet
        };
    }

    processMouseEnter(hitinfo: HitInfo): boolean {
        if (!this.header[hitinfo.col]) {
            return false;
        }
        if (!this.toolTipElement) {
            const div = document.createElement("div");
            div.style.position = 'absolute';
            div.style.border = '1px #C0C0C0 solid';
            div.style.boxShadow = '1px 2px 5px rgba(0,0,0,0.4)';
            div.style.font = '12px';
            div.style.background = 'white';
            div.style.padding = '5px';
            div.style.whiteSpace = 'nowrap'
            div.innerText = this.tipText;
            this.toolTipElement = div;
        }

        this.toolTipElement.style.left = hitinfo.x + 'px';
        this.toolTipElement.style.transform = 'translateX(-50%)';
        this.toolTipElement.style.display = 'block';
        (document.querySelector('#ssHost') as Element).appendChild(this.toolTipElement);
        this.toolTipElement.style.top = -(this.toolTipElement.offsetHeight + 5) + 'px';
        const toolTipElementWidth = this.toolTipElement.offsetWidth;
        const totalWidth = hitinfo.sheet.Zs.width;
        if (hitinfo.x + toolTipElementWidth / 2 > totalWidth) {
            const bounds: number = totalWidth - (hitinfo.x + toolTipElementWidth / 2) || 0;
            this.toolTipElement.style.left = hitinfo.x + bounds + 'px';
        }
        return true;
    }

    processMouseLeave(hitInfo: HitInfo): boolean {
        // debugger
        if (this.toolTipElement) {
            this.toolTipElement.style.display = 'none';
        }
        return true;
    }
}

//自定义注册copy事件
export const customCopyConVEvent = (spread: GC.Spread.Sheets.Workbook) => {
    const sheet = spread.getActiveSheet();
    // spread.commandManager().register("copyConVEvent", {
    //     //canUndo 指示该命令是否支持撤消和重做操作。
    //     canUndo: true,
    //     // 	执行执行或撤消操作。
    //     //context {GC.Spread.Sheets.Workbook} 操作的上下文。
    //     //options {Object} 操作的选项。
    //     //options.sheetName {string} 表单名称。
    //     //isUndo {boolean} true 这是撤消操作； 否则为 false 。
    //     execute: function (context: any, options: any, isUndo: any) {
    //         console.log(context, options, isUndo);
    //         const Commands = GC.Spread.Sheets.Commands;
    //         if (isUndo) {
    //             //撤消在事务中所做的更改。
    //             Commands.undoTransaction(context, options);
    //             return true;
    //         } else {
    //             //开始事务。 在事务过程中，将保存数据模型的更改。
    //             Commands.startTransaction(context, options);

    //             const sheet = context.getActiveSheet();
    //             const selectArea = sheet.getSelections()[0]
    //             sheet.suspendPaint();

    //             sheet.options.isProtected = false;
    //             // spread.commandManager().execute({ cmd: "paste", sheetName: "Sheet1" });
    //             setTimeout(function () {
    //                 sheet.options.isProtected = true;
    //             }, 1000);


    //             spread.resumePaint()

    //             //结束事务。 在交易过程中，将保存数据模型的更改。
    //             Commands.endTransaction(context, options);
    //             return true;
    //         }
    //     }
    // })
    // spread.commandManager().setShortcutKey('copyConVEvent', GC.Spread.Commands.Key.v, true, false, false, false);
    // spread.commandManager().setShortcutKey('copyConVEvent', GC.Spread.Commands.Key.v, true, false, false, true);
    document.addEventListener("keydown", (e: KeyboardEvent)=> {
        if (e.ctrlKey) {
            sheet.options.isProtected = false;
        }
    })
    document.addEventListener("keyup", ()=> {
        sheet.options.isProtected = true;
    })
    // spread.bind(GC.Spread.Sheets.Events.InvalidOperation, function (e: any, info: any) {
    //     console.log("Message (" + info.invalidType + ")");
    //     sheet.options.isProtected = false;
    //     spread.commandManager().execute({ cmd: "paste", sheetName: "Sheet1" });
    //     setTimeout(function () {
    //         sheet.options.isProtected = true;
    //     }, 1000);
    // });
}
const SUMMARY = ['TYPECLASSSUMMARY', 'MODELYEARSUMMARY', 'BRANDSUMMARY', 'CUSTOMERSUMMARY', 'MAKESUMMARY']
//判断是否是可编辑cell
export const isEditCell = (type: string): boolean => {
    if (SUMMARY.indexOf(type) > -1) {
        return false;
    } else if (type === 'READ') {
        return false;
    } else {
        return true;
    }
}

//判断是否是汇总cell
export const isSummaryCell = (type: string): boolean => {
    if (SUMMARY.indexOf(type) > -1) {
        return true;
    } else {
        return false;
    }
}

// 绑定数据 locked true 表示锁定
export const bindDataTable = (spread: GC.Spread.Sheets.Workbook, data: BudgetTableDataCell[][], locked = false): void => {
    const sheet = spread.getActiveSheet();
    sheet.suspendPaint();
    sheet.setRowCount(data.length, GC.Spread.Sheets.SheetArea.viewport);
    const autoFitColumnIndex: number[] = [];
    let currentText = 0
    for (let row = 0; row < data.length; row++) {
        // 展开收起
        // sheet.getCell(row, 0).textIndent(data[row].level);
        if (data[row][0].type === 'MAKESUMMARY') {
            sheet.getCell(row, 0).textIndent(0)
            currentText = 0
        } else if (data[row][0].type === 'CUSTOMERSUMMARY') {
            sheet.getCell(row, 0).textIndent(1);
            currentText = 1
        } else if (data[row][0].type === 'BRANDSUMMARY') {
            sheet.getCell(row, 0).textIndent(2);
            currentText = 2
        } else if (data[row][0].type === 'TYPECLASSSUMMARY') {
            sheet.getCell(row, 0).textIndent(3);
            currentText = 3
        } else if (data[row][0].type === 'MODELYEARSUMMARY') {
            sheet.getCell(row, 0).textIndent(4);
            currentText = 4
        } else if (data[row][0].type === 'READ') {
            sheet.getCell(row, 0).textIndent(currentText + 1);
        }
        //设置row高度
        // debugger
        sheet.setRowHeight(row, 40, GC.Spread.Sheets.SheetArea.viewport);
        for (let col = 0; col < data[row].length; col++) {
            if (data[row][col].autoAutoFitColumn && !autoFitColumnIndex.includes(col)) {
                autoFitColumnIndex.push(col);
            }
            if (locked) {
                sheet.getCell(row, col).locked(true);
            } else {
                //判断是否可以编辑
                if (data[row][col].readOnly) {
                    sheet.getCell(row, col).locked(true);
                } else {
                    sheet.getCell(row, col).locked(!isEditCell(data[row][col].type));
                }
            }
            //设置对齐方式
            if (data[row][col].align) {
                sheet.getCell(row, col).hAlign(GC.Spread.Sheets.HorizontalAlign[data[row][col].align as string]);
            }
            //判断是否是汇总列
            if (isSummaryCell(data[row][col].type)) {
                // sheet.getCell(row, col).backColor('#B0CEEA');
                // sheet.getCell(row, col).backColor('#CCDDFF');
                sheet.getCell(row, col).backColor('#D4E6F1');
                
                
            } else {
                // sheet.getCell(row, col).backColor('#F8F9F9');
                sheet.getCell(row, col).backColor('#f5f5f5');
            }
            if (!locked) {
                //可编辑的cell添加不同的颜色
                if (!data[row][col].readOnly && isEditCell(data[row][col].type)) {
                    sheet.getCell(row, col).backColor('#FFFFFF');
                }
            }
            sheet.getCell(row, col).wordWrap(true);
            sheet.setColumnWidth(col, data[row][col].width || '2*', GC.Spread.Sheets.SheetArea.viewport);
            if (data[row][col].contentsType === 'percent') {
                sheet.setValue(row, col, data[row][col].visible !== false ? data[row][col].contents || 0 : '');
                // 格式化成百分比
                sheet.setFormatter(row, col, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
                //判断AVG SI% 需要序列化成0.00%
            } else if (data[row][col].contentsType === "percentDecimal") {
                sheet.setValue(row, col, data[row][col].visible !== false ? data[row][col].contents || 0 : '');
                sheet.setFormatter(row, col, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
            } else if (data[row][col].contentsType === "text") {
                // 格式化成千分符
                sheet.setValue(row, col, data[row][col].visible !== false ? data[row][col].contents : '')
            } else if (data[row][col].contentsType === "number") {
                // 格式化成千分符
                sheet.setValue(row, col, data[row][col].visible !== false ? data[row][col].contents || 0 : '')
                //判断col是第一个不序列化为千分符  第一位是日期，如果序列化会有显示问题， 234  是文字序列化后没有显示问题
                col > 0 && sheet.setFormatter(row, col, "#,##0", GC.Spread.Sheets.SheetArea.viewport);
            }
        }
    }
    //设置自适应列 "Model", "Model Year"
    autoFitColumnIndex.forEach(item => {
        sheet.autoFitColumn(item);
    })
    sheet.resumePaint();
}

// 设置表格的头部，可以不占用单元格
export const setHeader = (spread: GC.Spread.Sheets.Workbook, headerData: BudgetTableHeaderCell[]) => {
    const sheet = spread.getActiveSheet();
    sheet.setColumnCount(headerData.length, GC.Spread.Sheets.SheetArea.viewport);
    const row = sheet.getRange(0, -1, 1, -1, GC.Spread.Sheets.SheetArea.colHeader); // 获取第一行区域
    row.wordWrap(true);
    const alignLeftKey: string[] = ['Period', 'Make', 'Customer', 'Brand', "Type Class", "Model", "Model Year"];
    //冻结列的数量
    let frozenColumnCount = 0;
    for (let i = 0; i < headerData.length; i++) {
        sheet.setValue(0, i, headerData[i].name, GC.Spread.Sheets.SheetArea.colHeader);
        sheet.getCell(0, i, GC.Spread.Sheets.SheetArea.colHeader).backColor('#e6e6e6')
        if (alignLeftKey.includes(headerData[i].name)) {
            sheet.getCell(0, i, GC.Spread.Sheets.SheetArea.colHeader).hAlign(GC.Spread.Sheets.HorizontalAlign.left);
            frozenColumnCount += 1;
        } else {
            // 设置除alignLeftKey的表头对齐方式
            sheet.getCell(0, i, GC.Spread.Sheets.SheetArea.colHeader).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        }
    }

    //冻结列  //解冻列设置为0
    sheet.frozenColumnCount(frozenColumnCount);
    sheet.options.frozenlineColor = "transparent";
}

export const autoHeaderFitColumn = (spread: GC.Spread.Sheets.Workbook, headerData: BudgetTableHeaderCell[]): void => {
    //自定义操作列集合
    const autoFitColumnKeys: string[] = ["Model", "Model Year"];
    const autoFitColumnIndex: { col: number }[] = [];
    for (let i = 0; i < headerData.length; i++) {
        if (autoFitColumnKeys.includes(headerData[i].name)) {
            autoFitColumnIndex.push({ col: i });
        }
    }
    // 获取该实例的CommandManager实例
    const commandManager = spread.commandManager();
    // 设置当前worksheet允许撤销命令
    spread.options.allowUndo = true;
    // 命令调用：
    commandManager.execute({
        cmd: "autoFitColumn",
        sheetName: "Sheet1",
        columns: autoFitColumnIndex,
        isRowHeader: true,
        autoFitType: GC.Spread.Sheets.AutoFitType.cellWithHeader
    });
}

//更新ClipboardPasted 事件的数据
const updateClipboardPastedData = (data: BudgetProps, rowIndex: number, colIndex: number, value: string): void => {
    const cellData =  data.inputTableCell;
    cellData[rowIndex][colIndex].contents = value;
    cellData[rowIndex][colIndex].edited = true;
    //判断如果触发的是禁用cell，输入值完成则需要禁用对应的cell
    if ((cellData[rowIndex][colIndex].trigger as Array<number>).includes(colIndex)) {
        const index = (cellData[rowIndex][colIndex].trigger as Array<number>).filter(item => {
            return item != colIndex;
        })
        if (index.length > 0) {
            if (Number(value) > 0) {
                cellData[rowIndex][index[0]].type = 'READ';
            } else {
                cellData[rowIndex][index[0]].type = 'WRITE';
            }
        }
        
    }
}

interface ClipboardPastedEventData {
    col: number;
    colCount: number;
    row: number;
    rowCount: number;
    [key: string]: any;
}

const getClipboardPastedValues = (sheet: GC.Spread.Sheets.Worksheet, eventData: ClipboardPastedEventData): string[] => {
    const cellRange = Object.assign({}, eventData);
    const values: string[] = [];
    // 多个row 多个cell
    if (cellRange.colCount > 1 && cellRange.rowCount > 1) {
        //row 循环
        while (cellRange.rowCount > 0) {
            const value = [];
            //col 循环
            while (cellRange.colCount > 0) {
                value.push(sheet.getValue(cellRange.row, cellRange.col));
                cellRange.col += 1;
                cellRange.colCount--;
            }
            // col循环完重置col的变量为初始值
            cellRange.colCount = eventData.colCount;
            cellRange.col = eventData.col;

            cellRange.row += 1;
            cellRange.rowCount--;

            values.push(value.join('&&'));
        }
        //处理多个col, 单个row
    } else if (cellRange.colCount > 1 && cellRange.rowCount === 1) {
        const value = [];
        while (cellRange.colCount > 0) {
            value.push(sheet.getValue(cellRange.row, cellRange.col));
            cellRange.col += 1;
            cellRange.colCount--;
        }
        values.push(value.join('&&'));
        //处理多个row，单个col
    } else if (cellRange.colCount === 1 && cellRange.rowCount > 1) {
        while (cellRange.rowCount > 0) {
            values.push(sheet.getValue(cellRange.row, cellRange.col));
            cellRange.row += 1;
            cellRange.rowCount--;
        }
        //单个
    } else {
        values.push(sheet.getValue(cellRange.row, cellRange.col));
    }
    return values;
}

// spread 实例 data数据 headerData数据表头 添加事件处理
export const inputEventToFormula = (spread: GC.Spread.Sheets.Workbook, data: BudgetProps, headerData: BudgetTableHeaderCell[]): void => {
    //ClipboardPasted事件
    const sheet = spread.getActiveSheet();
    sheet.bind(GC.Spread.Sheets.Events.ClipboardPasted, function (sender: any, args: any) {
        const inputCellData = data.inputTableCell;
        const cellRange = Object.assign({}, args.cellRange);
        // const values: string[] = args.pasteData.text.replace(/\r\n/g, ';').replace(/\t/g, '&&').split(';');
        const values: string[] = getClipboardPastedValues(sheet, cellRange);
        let rowIndex: number = cellRange.row;
        let colIndex: number = cellRange.col;
        //处理粘贴多cell 多个 row
        if (cellRange.colCount > 1 && cellRange.rowCount > 1) {
            const _value: string[][] = values.map(item => item.split('&&'));
            //row 循环
            while (cellRange.rowCount > 0) {
                //处理复制多个值
                let rowValues: string[] = [];
                if (_value.length > 1) {
                    rowValues = _value[values.length - cellRange.rowCount]
                } else {
                    rowValues = _value[0];
                }
                //col 循环
                while (cellRange.colCount > 0) {
                    if (!sheet.getCell(rowIndex, colIndex).locked()) {
                        updateClipboardPastedData(data, rowIndex, colIndex, toNumber(rowValues[rowValues.length - cellRange.colCount]));
                    }
                    colIndex += 1;
                    cellRange.colCount--;
                }
                // col循环完重置col的变量为初始值
                cellRange.colCount = args.cellRange.colCount;
                colIndex = args.cellRange.col;

                rowIndex += 1;
                cellRange.rowCount--;
            }
            //处理多个col, 单个row
        } else if (cellRange.colCount > 1 && cellRange.rowCount === 1) {
            while (cellRange.colCount > 0) {
                const value = values[0].split('&&');
                if (!sheet.getCell(rowIndex, colIndex).locked()) {
                    updateClipboardPastedData(data, rowIndex, colIndex, toNumber(value[value.length - cellRange.colCount]));
                }
                colIndex += 1;
                cellRange.colCount--;
            }

            //处理多个row，单个col
        } else if (cellRange.colCount === 1 && cellRange.rowCount > 1) {
            while (cellRange.rowCount > 0) {
                if (!sheet.getCell(rowIndex, colIndex).locked()) {
                    updateClipboardPastedData(data, rowIndex, colIndex, toNumber(values[values.length - cellRange.rowCount]));
                }
                //处理复制多个值
                if (values.length > 1) {
                    if (!sheet.getCell(rowIndex, colIndex).locked()) {
                        updateClipboardPastedData(data, rowIndex, colIndex, toNumber(values[values.length - cellRange.rowCount]));
                    }
                } else {
                    if (!sheet.getCell(rowIndex, colIndex).locked()) {
                        updateClipboardPastedData(data, rowIndex, colIndex, toNumber(values[0]));
                    }
                }
                rowIndex++;
                cellRange.rowCount--;
            }
            //单个
        } else {
            if (!sheet.getCell(rowIndex, colIndex).locked()) {
                updateClipboardPastedData(data, rowIndex, colIndex, toNumber(values[0]));
            }
        }
        bindDataTable(spread, inputCellData);
    });

    //cell value change 事件
    sheet.bind(GC.Spread.Sheets.Events.ValueChanged, (e: any, info: any) => {
        //事件挂起
        sheet.suspendEvent();
        // info  包含当前变的cell的位置信息 row（行）  和 col（列）  根据这个就可以找到对应的这条数据;
        //判断输入的类型是percent，则输入的值不能大于1
        const inputCellData = data.inputTableCell;
        //当前的row
        const row = inputCellData[info.row];

        //判断输入是否合法
        if (isNaN(Number(info.newValue))) {
            message.error('请输入数字类型');
            sheet.setValue(info.row, info.col, info.oldValue);
            sheet.resumeEvent();
            return;
        }

        //判断负数
        if (info.newValue < 0) {
            message.error('请输入正确的数值');
            sheet.setValue(info.row, info.col, info.oldValue);
            sheet.resumeEvent();
            return;
        }

        //Penetration 验证
        if (headerData[info.col].name === '% Penetration' && info.newValue > 1) {
            message.error('% Penetration cant > 100%');
            sheet.setValue(info.row, info.col, info.oldValue);
            sheet.resumeEvent();
            return;
            //验证小数
        } else if (inputCellData[info.row][info.col].contentsType === 'percent' && info.newValue > 1) {
            message.error('请输入正确的数值');
            sheet.setValue(info.row, info.col, info.oldValue);
            sheet.resumeEvent();
            return;
        }

        //判断estimated volume的值不能大于pipeline override
        if (headerData[info.col].name === 'Estimated Volume') {
            const pipelineOverrideIndex = headerData.findIndex(item => item.name.includes('Pipeline Override'));
            if (!row[pipelineOverrideIndex].contents || info.newValue > row[pipelineOverrideIndex].contents) {
                message.error('Estimated Volume cant > Pipeline Override');
                sheet.setValue(info.row, info.col, info.oldValue);
                sheet.resumeEvent();
                return;
            }
        }
        //赋值，禁用cell
        row[info.col].contents = info.newValue;
        row[info.col].edited = true;
        //判断如果触发的是禁用cell，输入值完成则需要禁用对应的cell
        if ((row[info.col].trigger as Array<number>).includes(info.col)) {
            const index = (row[info.col].trigger as Array<number>).filter(item => {
                return item != info.col;
            })
            if (index.length > 0) {
                if (info.newValue != info.oldValue && info.newValue > 0) {
                    inputCellData[info.row][index[0]].type = 'READ';
                } else {
                    inputCellData[info.row][index[0]].type = 'WRITE';
                }
            }
            
        }
        bindDataTable(spread, inputCellData);
        // 恢复事件
        sheet.resumeEvent();
    });
}

//设置headerTip 提示
export const setHeaderCellTypeTip = (spread: GC.Spread.Sheets.Workbook, headerData: BudgetTableHeaderCell[]) => {
    const sheet = spread.getActiveSheet();
    //设置% Penetration  和 Estimated Volume 录入互斥 提示
    const tipCellTypes: { name: string; tipText: string }[] = [
        {
            name: '% Penetration',
            tipText: '% Penetration，Estimated Volume录入互斥，如需切换录入字段，请先清空已录入数据并保存'
        },
        {
            name: 'Estimated Volume',
            tipText: '% Penetration，Estimated Volume录入互斥，如需切换录入字段，请先清空已录入数据并保存'
        },
        {
            name: 'Budget Estimation',
            tipText: 'Budget Estimation = Cost/unit * Estimated Volume'
        },
        {
            name: 'AVG.SI %',
            tipText: 'Avg.SI% = Avg.SI / MSRP w/o VAT'
        },
        {
            name: 'Avg.SI',
            tipText: 'Typeclass Avg.SI = Budget Estimaton / Max(Kufri, Pipeline Override)'
        }
    ]

    //重置header的cellType
    headerData.forEach(item => {
        sheet.setCellType(0, item.columnNumber, new GC.Spread.Sheets.CellTypes.ColumnHeader(), GC.Spread.Sheets.SheetArea.colHeader)
    })

    //设置固定字段的tip提示
    headerData.filter(cell => tipCellTypes.some(item => cell.name === item.name)).forEach(item => {
        // 拿到当前这一条对应的tipText
        const tips = tipCellTypes.filter(cell => cell.name === item.name);
        if (tips.length > 0) {
            sheet.setCellType(0, item.columnNumber, new TipCellType(headerData, tips[0].tipText), GC.Spread.Sheets.SheetArea.colHeader);
        }
    })

}

// 初始配置
export const ssHotTop = (spread: GC.Spread.Sheets.Workbook, data: BudgetProps, cellData: BudgetTableDataCell[][], headerData: BudgetTableHeaderCell[], locked = false, bindEvent = false, bu: string): void => {
    if (cellData.length < 1) {
        (document.getElementById('ssHost') as HTMLElement).innerHTML = 'NO Data'
        return
    }
    spread.suspendPaint();
    const sheet = spread.getActiveSheet();
    // 过滤
    const range = new GC.Spread.Sheets.Range(-1, 0, -1, headerData.length);
    const rowFilter = new GC.Spread.Sheets.Filter.HideRowFilter(range);
    sheet.rowFilter(rowFilter);
    rowFilter.filterDialogVisibleInfo({
        sortByValue: true,
        sortByColor: false, 
        filterByColor: true,
        filterByValue: true,
        listFilterArea: true   
    })
    // sheet.rowOutlines.group(1, 3);
    // sheet.columnOutlines.group(1, 2);
    // sheet.columnOutlines.group(4, 6);
    // 必须加上这两个才能折叠展开
    sheet.options.protectionOptions.allowOutlineRows = true
    sheet.options.protectionOptions.allowOutlineColumns = true
    sheet.options.protectionOptions.allowFilter = true
    sheet.options.protectionOptions.allowSort = true

    // 滚动条样式
    // spread.options.scrollbarAppearance = GC.Spread.Sheets.ScrollbarAppearance.mobile;
    // sheet是否显示x
    spread.options.tabNavigationVisible = false
    spread.options.tabStripVisible = false
    // 滚动条常用设置
    spread.options.scrollbarMaxAlign = true
    sheet.setRowCount(1, GC.Spread.Sheets.SheetArea.colHeader);
    // 设置头部
    setHeader(spread, headerData)
    // 设置header的高度
    sheet.setRowHeight(0, 50, GC.Spread.Sheets.SheetArea.colHeader);
    // spread.commandManager().execute({cmd: "autoFitRow", sheetName: "Sheet1", rows: [{row:0}], columnHeader: true, autoFitType: GC.Spread.Sheets.AutoFitType.cell});
    // 设置多少行和列
    sheet.setRowCount(cellData.length, GC.Spread.Sheets.SheetArea.viewport)
    sheet.setColumnCount(cellData[0].length, GC.Spread.Sheets.SheetArea.viewport)
    // 设置整个表格不能插入， 删除行列
    // allowInsertRows不允许插入行，allowInsertColumns不允许插入列
    // allowDeleteRows不允许删除行, allowDeleteColumns不允许删除列
    sheet.options.protectionOptions.allowInsertRows = false
    sheet.options.protectionOptions.allowInsertColumns = false
    sheet.options.protectionOptions.allowDeleteRows = false
    sheet.options.protectionOptions.allowDeleteColumns = false

    sheet.options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values;

    //填充铺满整个canvas
    spread.options.scrollbarMaxAlign = true;
    spread.options.scrollByPixel = true;
    //禁止拖动填充
    spread.options.allowUserDragFill = false;
    spread.options.allowUserDragDrop = false;
    //自适应的时候同时测量 header 区域和 Viewport 区域
    spread.options.autoFitType = GC.Spread.Sheets.AutoFitType.cellWithHeader;
    // 设置隐藏头和列
    sheet.options.rowHeaderVisible = false
    // sheet.options.colHeaderVisible = false
    // 要设置允保护，不允许的才能生效
    sheet.options.isProtected = true
    // 不允许单个格子编辑，是样式控制
    const sheetStyle = sheet.getDefaultStyle();
    //修改并设置表的默认样式locked为false.
    sheetStyle.locked = true;
    sheetStyle.font = '"Daimler CS", "Mier B", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "SimSun"'
    //默认对其方式
    sheetStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
    sheetStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    sheet.setDefaultStyle(sheetStyle);
    // AMG不显示 typeClass
    // if (bu === 'AMG') {
    //     sheet.setColumnVisible(1, false, GC.Spread.Sheets.SheetArea.viewport)
    // }
    //禁止缩放
    spread.options.allowUserZoom = false;
    

    // 设置样式第一行的背景色
    const styleUnEdit = new GC.Spread.Sheets.Style();

    // 第一行不能编辑
    styleUnEdit.locked = true;
    // 设置样式第一行的背景色
    const styleEdit = new GC.Spread.Sheets.Style();
    // 第一行不能编辑
    styleEdit.locked = false;

    //自定义copy事件
    customCopyConVEvent(spread);

    //绑定数据
    bindDataTable(spread, cellData, locked);

    //设置表格header tip提示
    setHeaderCellTypeTip(spread, headerData);
    

    spread.resumePaint();
}

function hasValue<T extends Record<string, any>>(value: string, dataSource: Array<T>,): boolean {
    return dataSource.some(item => Object.values(item).includes(value));
}

export const handlerSheetsData = (data: BudgetProps, isCheckoutMonth = false, headerArr: string[], isOverview = false): BudgetProps => {
    //header 的汇总的固定key input 需要通过这个两个字段来确定唯一， Period都是一样的所以不需要判断
    // const inputHeaderFixedKeys: string[] = ['Period', 'Brand', 'Type Class', 'Model Year'];
    const inputHeaderFixedKeys: string[] = headerArr
    //deader 的汇总固定key的索引 input
    const inputHeaderFixedKeysIndex: number[] = [];
    //header需要序列化为百分比的索引
    const percentCellIndex: number[] = [];
    //互斥的header cell
    const mutexHeaderCellKeys: string[] = ['% Penetration', 'Estimated Volume'];
    //互斥的header cell index
    const mutexHeaderCellIndex: number[] = [];
    //固定左对齐的key
    // const alignLeftKey: string[] = ['Period', 'Brand', "Type Class", "Model", "Model Year"];
    const alignLeftKey: string[] = headerArr
    //需要显示类型为text类型的key的索引
    // const textKeyIndex: number[] = data.header.filter(item => ['Period', 'Brand', "Type Class", "Model", "Model Year"].includes(item.name)).map(item => item.columnNumber);
    const textKeyIndex: number[] = data.header.filter(item => headerArr.includes(item.name)).map(item => item.columnNumber);
    //根据固定的header key 获取对应的索引;

    //百分比小数的ke
    const percentDecimalKeys: string[] = ['AVG.SI %', '% MSRP w/o VAT'];

    // 设置特殊宽度
    const smallWidthKeys: string[] = ['AVG.SI %', 'Avg.SI', 'Type Class',]

    const kufriWidthKeys: string[] = ['RT Kufri', 'RT Pipeline Override', 'WS Kufri', 'WS Pipeline Override', 'Brand']

    if ('header' in data) {
        data.header.forEach((item, index) => {
            //获取input固定key的索引
            if (inputHeaderFixedKeys.includes(item.name)) {
                inputHeaderFixedKeysIndex.push(index);
            }
            //判断那个cell需要序列化为百分比
            if (item.name.indexOf('%') > -1) {
                percentCellIndex.push(index);
            }
            //查找互斥key的索引
            if (mutexHeaderCellKeys.includes(item.name)) {
                mutexHeaderCellIndex.push(index);
            }
        })
    }


    const cellData = data.inputTableCell;

    //添加属性visible  是否显示contents字段  添加属性contentsType 显示的类型   添加属性trigger  互斥的对应的索引
    if ('inputTableCell' in data) {
        data.inputTableCell.forEach(item => {
            item.forEach((cell, index) => {
                cell.visible = true;
                //添加显示类型
                if (percentCellIndex.includes(index)) {
                    //百分比
                    cell.contentsType = 'percent'
                } else if (textKeyIndex.includes(index)) {
                    //文本
                    cell.contentsType = 'text';
                } else {
                    //千分数
                    cell.contentsType = 'number';
                }
                //默认宽度
                cell.width = 120;
                //添加显示序列化的数据格式  百分比小数
                if (percentDecimalKeys.includes(data.header[index].name)) {
                    //百分比小数
                    cell.contentsType = 'percentDecimal';
                }
                if (smallWidthKeys.includes(data.header[index].name)) {
                    cell.width = 80;
                }

                if (kufriWidthKeys.includes(data.header[index].name)) {
                    cell.width = 100;
                }
                if (['% Penetration', 'Cost / unit'].includes(data.header[index].name)) {
                    cell.width = 100
                }
                if (['Estimated Volume', 'MSRP w/o VAT'].includes(data.header[index].name)) {
                    cell.width = 120
                }
                //判断trigger的列是否有值
                if (mutexHeaderCellIndex.includes(index)) {
                    cell.trigger = mutexHeaderCellIndex;
                } else {
                    cell.trigger = [];
                }
            })
        })
    }

    //获取当前row，根据headerFixedKeys  获取到对应的值
    function getRowContents(row: BudgetTableDataCell[], indexList: number[]): string[] {
        const arr: string[] = [];
        indexList.forEach(index => {
            arr.push(row[index].contents);
        })
        return arr;
    }

    //判断是否相等
    function hasRepeat(content: string[][], content1: string[]): boolean {
        return content.some(item => item.join(',') == content1.join(','));
    }

    //设置row下的cell visible属性
    function setCellVisible(row: BudgetTableDataCell[]) {
        inputHeaderFixedKeysIndex.forEach(index => {
            row[index].visible = false;
        })
    }

    //设置对齐方式
    // 固定的列，要看配的东西,下面处理显示隐藏用
    const fixedCol: string[] = [];
    for (const row of cellData) {
        for (let cell = 0; cell < row.length; cell++) {
            row[0].align = 'left'
            // 设置的单元格对齐方式
            if (alignLeftKey.includes(data.header[cell].name)) {
                row[cell].align = 'left';
                if (fixedCol.indexOf('left' + cell) < 0) fixedCol.push('left' + cell);
            } else {
                row[cell].align = 'right';
            }
        }
    }
    //处理input汇总行和children  cellData 第一条数据肯定是汇总行，需求
    if ('inputTableCell' in data) {
        const isModelYear = hasValue('Model Year', data.header);
        const isModel = hasValue('Model', data.header);
        const isPeriod = hasValue('Period', data.header)
        // const modelIndex = data.header.findIndex(item => item.name === headerArr[headerArr.length - 1])
        let refValPeriod = null;
        // period 处理period显示隐藏用visible: false/true
        // if (!isOverview) {
            for (let row = 0; row < cellData.length; row++) {
                if (!cellData[row][0].visible) {
                    if (!refValPeriod) {
                        refValPeriod = null
                    }
                    continue
                }
                if (!refValPeriod && refValPeriod === null) {
                    cellData[row][0].visible = true
                    refValPeriod = cellData[row][0].contents
                    continue
                }
                if (cellData[row][0].contents === refValPeriod) {
                    cellData[row][0].visible = false
                    continue
                }
                // cellData[row][col].contents !== refVal // 隐含条件
                cellData[row][0].visible = true
                refValPeriod = cellData[row][0].contents
            }
        // }
        // Brand.... Model Year显示隐藏用visible: false/true
        
        // if (!isOverview)  col = 0
        for (let col = 1; col < fixedCol.length; col++) {
            let refVal = null;
            for (let row = 0; row < cellData.length; row++) {
                if (!cellData[row][col].visible) {
                    if (!refVal) {
                        refVal = null
                    }
                    continue
                }
                if (!refVal) {
                    cellData[row][col].visible = true
                    refVal = cellData[row][col - 1].contents + cellData[row][col].contents
                    continue
                }
                if (cellData[row][col - 1].contents + cellData[row][col].contents === refVal) {
                    cellData[row][col].visible = false
                    continue
                }
                // cellData[row][col].contents !== refVal // 隐含条件
                cellData[row][col].visible = true
                refVal = cellData[row][col - 1].contents + cellData[row][col].contents
                // for (let row2 = col + 2; row2 < modelIndex; row2++) {
                //     cellData[row][col].visible = false
                // }
            }
        }

        // if (!isCheckoutMonth) {
        //     //处理 Period 字段，只会有一个日期并且在每行第一个
        //     for (const [rowIndex, row] of cellData.entries()) {
        //         if (rowIndex == 0) {
        //             row[0].visible = true;
        //         } else {
        //             row[0].visible = false;
        //         }
        //     }
            
        //     //处理brand保证唯一
        //     const brandMap: Map<string, string> = new Map();
        //     const brandIndex = data.header.findIndex(item => item.name === 'brand');
        //     if (brandIndex > -1) {
        //         for (const row of cellData) {
        //             if (brandMap.has(row[brandIndex].contents)) {
        //                 row[brandIndex].visible = false;
        //             } else {
        //                 row[brandIndex].visible = true;
        //                 brandMap.set(row[brandIndex].contents, row[brandIndex].contents);
        //             }
        //         }
        //     }

        //     //处理typeClass保证唯一
        //     const typeClassMap: Map<string, string> = new Map();
        //     const typeClassIndex = data.header.findIndex(item => item.name === 'Type Class');
        //     for (const row of cellData) {
        //         if (typeClassMap.has(row[typeClassIndex].contents)) {
        //             row[typeClassIndex].visible = false;
        //         } else {
        //             row[typeClassIndex].visible = true;
        //             typeClassMap.set(row[typeClassIndex].contents, row[typeClassIndex].contents);
        //         }
        //     }
        //     //没有 Model Year 肯定有  Model 无需处理

        //     //没有 Model 肯定有 Model Year 无需处理

        //     // 有 Model  和 Model Year 需要根据typeClass + Model Year 来去确认唯一, 确认Model Year是否显示
        //     if (isModel && isModelYear) {
        //         const summaryContents: string[][] = [];
        //         const modelYearIndex = data.header.findIndex(item => item.name === 'Model Year');
        //         for (const row of data.inputTableCell) {
        //             // 查找汇总行根据 typeClass + Model Year 去重
        //             if (row[0].type === 'TYPECLASSSUMMARY' || row[0].type === 'MODELYEARSUMMARY') {
        //                 if (!hasRepeat(summaryContents, getRowContents(row, [typeClassIndex, modelYearIndex]))) {
        //                     row[modelYearIndex].visible = true;
        //                     summaryContents.push(getRowContents(row, [typeClassIndex, modelYearIndex]));
        //                 }
        //             } else {
        //                 // 查找非汇总行根据 typeClass + Model Year 去重
        //                 if (hasRepeat(summaryContents, getRowContents(row, [typeClassIndex, modelYearIndex]))) {
        //                     row[modelYearIndex].visible = false;
        //                 }
        //             }
        //         }
        //     }
        // } else {
            
        //     // rowIndex 行的索引 row 这一行的数据
        //     const periodValues: string[] = []
        //     const periodIndex = data.header.findIndex(item => item.name === 'Period');

        //     //处理typeClass保证唯一
        //     const makeMap: Map<string, string> = new Map();
        //     const makeIndex = data.header.findIndex(item => item.name === 'Make');

        //     //处理typeClass保证唯一
        //     const customerMap: Map<string, string> = new Map();
        //     const customerIndex = data.header.findIndex(item => item.name === 'Customer');

        //     //处理typeClass保证唯一
        //     const brandMap: Map<string, string> = new Map();
        //     const brandIndex = data.header.findIndex(item => item.name === 'Brand');
        //     //处理typeClass保证唯一
        //     const typeClassMap: Map<string, string> = new Map();
        //     const typeClassIndex = data.header.findIndex(item => item.name === 'Type Class');
            
        //     //处理modelYear保证唯一
        //     const modelYearMap: Map<string, string> = new Map();
        //     const modelYearIndex = data.header.findIndex(item => item.name === 'Model Year');
        //     for (const row of cellData) {
                
        //         // period
        //         if (periodValues.includes(row[periodIndex].contents)) {
        //             row[periodIndex].visible = false;
        //         } else {
        //             periodValues.push(row[periodIndex].contents)
        //             row[periodIndex].visible = true;
        //         }
        //         // make
        //         if (makeMap.has(row[periodIndex].contents + row[makeIndex].contents)) {
        //             row[makeIndex].visible = false;
        //         } else {
        //             row[makeIndex].visible = true;
        //             makeMap.set(row[periodIndex].contents + row[makeIndex].contents, row[makeIndex].contents);
        //         }
        //         // customer
        //         if (makeMap.has(row[periodIndex].contents + row[makeIndex].contents + row[makeIndex].contents)) {
        //             row[makeIndex].visible = false;
        //         } else {
        //             row[makeIndex].visible = true;
        //             makeMap.set(row[periodIndex].contents + row[makeIndex].contents, row[makeIndex].contents);
        //         }
        //         if (brandMap.has(row[periodIndex].contents + row[brandIndex].contents)) {
        //             row[brandIndex].visible = false;
        //         } else {
        //             row[brandIndex].visible = true;
        //             brandMap.set(row[periodIndex].contents + row[brandIndex].contents, row[brandIndex].contents);
        //         }
        //         if (typeClassMap.has(row[periodIndex].contents + row[brandIndex].contents + row[typeClassIndex].contents)) {
        //             row[typeClassIndex].visible = false;
        //         } else {
        //             row[typeClassIndex].visible = true;
        //             typeClassMap.set(row[periodIndex].contents + row[brandIndex].contents + row[typeClassIndex].contents, row[typeClassIndex].contents);
        //         }
        //         if (isModel && isModelYear) {
        //             if (modelYearMap.has(row[periodIndex].contents + row[brandIndex].contents + row[typeClassIndex].contents + row[modelYearIndex].contents)) {
        //                 row[modelYearIndex].visible = false;
        //             } else {
        //                 row[modelYearIndex].visible = true;
        //                 modelYearMap.set(row[periodIndex].contents + row[brandIndex].contents + row[typeClassIndex].contents + row[modelYearIndex].contents, row[modelYearIndex].contents);
        //             }
        //         }
        //     }
        // }
        
        
        //处理有Model的情况给model cell 添加宽度
        if (isModel) {
            const index = data.header.findIndex(item => item.name === 'Model');
            for (const row of cellData) {
                // row[index].width = 160;
                row[index].autoAutoFitColumn = true;
            }
        }
        
        //处理有Model的情况给modelYear cell 添加宽度
        if (isModelYear) {
            const index = data.header.findIndex(item => item.name === 'Model Year');
            for (const row of cellData) {
                row[index].autoAutoFitColumn = true;
            }
        }
        if (isPeriod) {
            const index = data.header.findIndex(item => item.name === 'Period');
            for (const row of cellData) {
                row[index].width = 150;
            }
        }
    }
    return data;
}


export const bindDataTableSmall = (sheet: GC.Spread.Sheets.Worksheet, data: SmallTableProps[]): void => {
    sheet.suspendPaint();
    sheet.setDataSource(data);
    // 绑定每列
    sheet.bindColumn(0, { name: 'make', displayName: 'Make', size: 70 });
    sheet.bindColumn(1, { name: 'fleetVolume', displayName: 'Fleet Volume', size: 150 });
    sheet.bindColumn(2, { name: 'budgetEstimation', displayName: 'Budget Estimation', size: 150 });
    sheet.bindColumn(3, { name: 'avgSi', displayName: 'Avg.SI', size: 70 });
    sheet.bindColumn(4, { name: 'avgSiPercentage', displayName: 'Avg.Si%', size: 70 });
    
    // 设置每列的宽度
    // sheet.setColumnWidth(0, '2*');
    // sheet.setColumnWidth(1, '2*');
    // sheet.setColumnWidth(2, '2*');
    // sheet.setColumnWidth(3, '2*');
    // sheet.setColumnWidth(4, '2*');
    
    const headerArr = ['Make', 'Fleet Volume', 'Budget Estimation', 'Avg.SI', 'Avg.Si%',]
    data.forEach((item, row) => {
        headerArr.forEach((ite, col) => {
            if (ite === 'Avg.Si%') {
                sheet.setFormatter(row, col, "0.00%", GC.Spread.Sheets.SheetArea.viewport);
            } else if (ite !== 'Make') {
                sheet.setFormatter(row, col, "#,##0", GC.Spread.Sheets.SheetArea.viewport);
            }
        })
    })
    for (const key of data.keys()) {
        for (let cell = 1; cell < 6; cell++) {
            // 设置的单元格对齐方式
            sheet.getCell(key, cell).hAlign(GC.Spread.Sheets.HorizontalAlign.right);

        }
    }
    
    sheet.resumePaint();
}
export const ssHostSmall = (spread: GC.Spread.Sheets.Workbook, sheet: GC.Spread.Sheets.Worksheet, data: SmallTableProps[]) => {
    spread.suspendPaint();
    // 滚动条样式
    // spread.options.scrollbarAppearance = GC.Spread.Sheets.ScrollbarAppearance.mobile;
    spread.options.showHorizontalScrollbar = false
    spread.options.showVerticalScrollbar = false
    
    // sheet是否显示
    spread.options.tabNavigationVisible = false
    spread.options.tabStripVisible = false
    
    // 滚动条常用设置
    spread.options.scrollbarMaxAlign = false
    sheet.setRowCount(1, GC.Spread.Sheets.SheetArea.colHeader);
    // 设置头部
    sheet.setRowHeight(0, 30, GC.Spread.Sheets.SheetArea.colHeader)
    sheet.setRowHeight(0, 24, GC.Spread.Sheets.SheetArea.viewport);
    // 设置多少行和列
    sheet.setRowCount(data.length, GC.Spread.Sheets.SheetArea.viewport)
    sheet.setColumnCount(5, GC.Spread.Sheets.SheetArea.viewport)
    // 设置整个表格不能插入， 删除行列
    // allowInsertRows不允许插入行，allowInsertColumns不允许插入列
    // allowDeleteRows不允许删除行, allowDeleteColumns不允许删除列
    sheet.options.protectionOptions.allowInsertRows = false
    sheet.options.protectionOptions.allowInsertColumns = false
    sheet.options.protectionOptions.allowDeleteRows = false
    sheet.options.protectionOptions.allowDeleteColumns = false
    // 设置隐藏头和列
    sheet.options.rowHeaderVisible = false
    // sheet.options.colHeaderVisible = false
    // 要设置允保护，不允许的才能生效
    sheet.options.isProtected = true
    // 不允许单个格子编辑，是样式控制
    const sheetStyle = sheet.getDefaultStyle();
    //修改并设置表的默认样式locked为false.
    sheetStyle.locked = true;
    sheet.setDefaultStyle(sheetStyle);
    // 设置样式第一行的背景色
    const style = new GC.Spread.Sheets.Style();
    // 第一行的背景色
    style.backColor = '#f5f5f5';
    //set style to row.第一行的背景色, setStyle容易全局污染，用 getCell/getRange
    sheet.setStyle(data.length - 1, -1, style, GC.Spread.Sheets.SheetArea.viewport);
    // 设置样式第一行的背景色
    const styleUnEdit = new GC.Spread.Sheets.Style();
    // 第一行不能编辑
    styleUnEdit.locked = true;
    // 设置样式第一行的背景色
    const styleEdit = new GC.Spread.Sheets.Style();
    // 第一行不能编辑
    styleEdit.locked = false;
    // 第一列不能编辑
    bindDataTableSmall(sheet, data)
    spread.resumePaint();
}
export const isVisibleSpecialIndex = (arr: string[], offerTypeValue: string, offerTypeArr: OfferType[]): number => {
    const offerArr = offerTypeArr.find((val: OfferType) => val.offerTypeId === offerTypeValue)
    if (offerArr) {
        return arr.findIndex((item: string) => item === offerArr.offerTypeName)
    } else {
        return -1
    }
    
}
