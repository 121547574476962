
import {
  computed,
  ref,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import { TreeProps } from "@/views/User/types";
import { useStore } from "vuex";

function menuKeyPaths(menuKeys: TreeProps[], key: string): string[] {
  for (const menuKey of menuKeys) {
    if (menuKey.mnuid === key) {
      return [menuKey.mnuid];
    }
    const path = menuKeyPaths(menuKey.children, key);
    if (path.length) {
      return [menuKey.mnuid, ...path];
    }
  }
  return [];
}

export default defineComponent({
  setup() {
    const { commit, state } = useStore();

    const menuKeys = reactive<{
      openKeys: string[];
      selectedKeys: string[];
    }>({
      openKeys: state.sideNavKeys.openKeys,
      selectedKeys: state.sideNavKeys.selectedKeys,
    });

    // 是否收起来
    const collapsed = ref(false);
    const toggleCollapsed = () => {
      collapsed.value = !collapsed.value;
      //每次更新都更新
      commit("updateSideNavCollapsed", collapsed.value);
    };

    const isFixed = ref(false);
    // 当展开一个时把弄一个之前的关闭
    const onOpenChange = (openKeys: string[]) => {
      if (openKeys.length > menuKeys.openKeys.length) {
        // 展开
        const path = menuKeyPaths(
          state.user.menulist,
          openKeys[openKeys.length - 1]
        );
        menuKeys.openKeys = [...path];
      } else {
        // 关闭
        menuKeys.openKeys = openKeys;
      }
    };

    // 点击当前路由存储
    watch(
      () => menuKeys.selectedKeys,
      (value) => {
        commit("updateSideNavKeys", {
          openKeys: menuKeys.openKeys,
          selectedKeys: value,
        });
      }
    );
    // watch(
    //     () => state.sideNavKeys,
    //     (value) => {
    //         menuKeys.openKeys = state.sideNavKeys.openKeys;
    //         menuKeys.selectedKeys = state.sideNavKeys.selectedKeys;
    //     }
    // );
    onMounted(() => {
      //初始化值
      commit("updateSideNavCollapsed", collapsed.value);
      window.addEventListener("scroll", () => {
        isFixed.value = document.documentElement.scrollTop > 100;
      });
    });
    return {
      collapsed,
      toggleCollapsed,
      isFixed,
      ...toRefs(menuKeys),
      onOpenChange,
    };
  },
});
