import { RouteRecordRaw } from "vue-router";
export const faqRouters: Array<RouteRecordRaw> = [
  {
    path: "/faq/management",
    name: "FAQ Management",
    component: () => import("@/views/FAQ/Management/index.vue"),
  },
  {
    path: "/faq/dealerManagement",
    name: "FAQ Dealer Management",
    component: () => import("@/views/FAQ/DealerManagement/index.vue"),
  },
  {
    path: "/faq/enquiry",
    name: "FAQ Enquiry",
    component: () => import("@/views/FAQ/Enquiry/index.vue"),
  },
  {
    path: "/faq/edit/:faqId?",
    name: "FAQ Edit",
    component: () => import("@/views/FAQ/Edit/index.vue"),
  },
  {
    path: "/faq/configrate/:faqId",
    name: "FAQ Configrate",
    component: () => import("@/views/FAQ/Configrate/index.vue"),
  },
  {
    path: "/faq/dealerView/:faqId",
    name: "FAQ Dealer View",
    component: () => import("@/views/FAQ/DealerView/index.vue"),
  },
  {
    path: "/faq/View/:faqId",
    name: "FAQ View",
    component: () => import("@/views/FAQ/View/index.vue"),
  },
];
