import { RouteRecordRaw } from 'vue-router';

export const settingRouters: Array<RouteRecordRaw> = [
    {
        path: '/setting/systemSetting',
        name: 'systemSetting',
        component: () => import('@/views/Setting/Index.vue'),
    },
    {
        path: '/setting/AttachementMasterSetting',
        name: 'AttachementMasterSetting',
        component: () => import('@/views/Setting/AttachementMasterSetting.vue'),
    },
    {
        path: '/setting/pdilist',
        name: 'PDIList',
        component: () => import('@/views/Setting/PDIManagement.vue'),
    }
];
