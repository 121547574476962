import { fetchGet, fetchPost, fetchPostPic, fetchPut, fetchDelete, fetchPutPic } from './index'
import { CurrentuserProps, TypeProps, PGProps, RoleProps, TreeProps, APIMenuProps, SelectMenuCheckedProps, PrgProps, RoleTreeProps, SelectMenuRoleCheckedProps, OrgProps, OrgDealerProps, UserProps, SaveBUsProps, BuTreeProps, OrgParamsProps, OutLoginProps, LegalEntity, Entity, OrganizationProps, Bu } from '@/views/User/types'
import { ResProps } from './types'
import axios, { AxiosResponse } from 'axios'
// privilage Group

export const PGFindall = (params = {}): Promise<ResProps> => {
    return fetchGet('/usapi/privgroups/findall', params)
}
export const PGchangestatus = (params = {}): Promise<PGProps> => {
    return fetchPut('/usapi/privgroups/changestatus', params)
}
export const PGsavegroup = (params = {}) => {
    return fetchPost('/usapi/privgroups/savegroup', params)
}
export const PGupdategroup = (params = {}): Promise<PGProps> => {
    return fetchPut('/usapi/privgroups/updategroup', params)
}
export const PGfindgroupbyid = (params = {}): Promise<PGProps> => {
    return fetchGet('/usapi/privgroups/findgroupbyid', params)
}

// privilage Group Setting
export const PGfindmnuall = (params = {}): Promise<TreeProps[]> => {
    return fetchGet('/usapi/resources/findmnuall', params)
}
export const PGfindapiall = (params = {}): Promise<APIMenuProps[]> => {
    return fetchGet('/usapi/resources/findapiall', params)
}
export const PGsaveassignment = (params = {}) => {
    return fetchPut('/usapi/privgroups/saveassignment', params)
}
export const PGfindassignment = (params = {}): Promise<SelectMenuCheckedProps> => {
    return fetchGet('/usapi/privgroups/findassignment', params)
}


// role
export const rolesFindall = (params = {}): Promise<ResProps> => {
    return fetchGet('/usapi/roles/findall', params)
}
export const rolesSaverole = (params = {}) => {
    return fetchPost('/usapi/roles/saverole', params)
}
export const rolesUpdaterole = (params = {}): Promise<PrgProps[]> => {
    return fetchPut('/usapi/roles/updaterole', params)
}
export const PGfindgroupbytype = (params = {}): Promise<PrgProps[]> => {
    return fetchGet('/usapi/privgroups/findgroupbytype', params)
}
export const PGfindgroupbytypes = (params = {}): Promise<PrgProps[]> => {
    return fetchGet('/usapi/privgroups/findgroupbytypes', params)
}
export const rolesFindrolebyid = (params = {}): Promise<RoleProps> => {
    return fetchGet('/usapi/roles/findrolebyid', params)
}
export const PGfindalllist = (params = {}): Promise<PGProps> => {
    return fetchGet('/usapi/privgroups/findalllist', params)
}
export const rolesChangestatus = (params = {}): Promise<PrgProps[]> => {
    return fetchPut('/usapi/roles/changestatus', params)
}
// role setting
export const roleFindbyprivgrpid = (params = {}): Promise<RoleTreeProps> => {
    return fetchGet('/usapi/roles/findbyprivgrpid', params)
}
export const roleFindassignmen = (params = {}): Promise<SelectMenuRoleCheckedProps> => {
    return fetchGet('/usapi/roles/findassignment', params)
}
export const roleSaveassignment = (params = {}) => {
    return fetchPut('/usapi/roles/saveassignment', params)
}




// user
export const usersFindall = (params = {}): Promise<ResProps> => {
    return fetchGet('/usapi/users/findall', params)
}
export const bizFindbuall22 = (params = {}): Promise<OrgProps[]> => {
    return fetchGet('/mdapi/bizunits/findbuall', params)
}
export const bizFindbuall = (params = {}): Promise<OrgParamsProps[]> => {
    return fetchGet('/mdapi/bu/no-tree', params)
}
// 以前的获取dealers
export const deaFinddealerall22 = (params = {}): Promise<OrgDealerProps[]> => {
    return fetchGet('/mdapi/dealers/finddealerall', params)
}
// ---- 新的获取dealers
export const deaFinddealerall = (params = {}): Promise<OrgParamsProps[]> => {
    return fetchGet('/mdapi/dealers', params)
}
export const checkUserExisted = (params = {}): Promise<boolean> => {
    return fetchGet('/usapi/users/existed', params)
}
export const userSaveuser = (params = {}) => {
    return fetchPostPic('/usapi/users/saveuser', params)
}
export const userUpdateuser = (params = {}): Promise<UserProps> => {
    return fetchPutPic('/usapi/users/updateuser', params)
}
export const userFinduserbyid = (params = {}): Promise<UserProps> => {
    return fetchGet('/usapi/users/finduserbyid', params)
}
export const userChangestatus = (params = {}): Promise<UserProps> => {
    return fetchPut('/usapi/users/changestatus', params)
}
// user setting
export const usrFinduserbyorgid = (params = {}): Promise<UserProps[]> => {
    return fetchGet('/usapi/users/finduserbyorgid', params)
}

export const usrFinduserrole = (params = {}): Promise<RoleProps[]> => {
    return fetchGet('/usapi/users/finduserrole', params)
}

export const usrFindsubscribedbu = (params = {}): Promise<SaveBUsProps[]> => {
    return fetchGet('/usapi/users/findsubscribedbu', params)
}

export const getBuSelected = (params = {}): Promise<Bu[]> => {
    return fetchGet('/usapi/users/bu/selected', params)
}

export const deleteBuSelected = (params = {}) => {
    return fetchDelete('/usapi/users/bu/selected', params)
}

export const roleFindrolebytype = (params = {}): Promise<RoleProps[]> => {
    return fetchGet('/usapi/roles/findrolebytype', params)
}

export const bizBu2subscribe = (params = {}): Promise<BuTreeProps[]> => {
    return fetchGet('/mdapi/bizunits/findbu2subscribe', params)
}

// 获取bu (不用之前的接口获取)
export const getBuList = (): Promise<BuTreeProps[]> => {
    return fetchGet('/mdapi/bu')
}

export const getBuListByDealer = (params= {}): Promise<BuTreeProps[]> => {
    return fetchGet('mdapi/bu/dealer', params)
}

export const getBuDealer = ({ params }: any): Promise<BuTreeProps[]> => {
    return fetchGet(`/usapi/bu/dealer/${params.dealerId}`)
}
export const dealerBu2subscribe = (params = {}): Promise<BuTreeProps[]> => {
    return fetchGet('/mdapi/dealers/findbu2subscribe', params)
}

export const usrImportsetting = (params = {}) => {
    return fetchPost('/usapi/users/importsetting', params)
}

export const usrSaveuserrole = (params = {}) => {
    return fetchPost('/usapi/users/saveuserrole', params)
}

export const usrSaveuserorg = (params = {}) => {
    return fetchPost('/usapi/users/saveuserorg', params)
}

export const removeUserRole = (params = {}) => {
    return fetchDelete('/usapi/users/removeuserrole', params)
}

export const removeUserOrg = (params = {}) => {
    return fetchDelete('/usapi/users/removeuserorg', params)
}

export const findCurrentUser = (params = {}): Promise<CurrentuserProps> => {
    return fetchGet('/usapi/users/currentuser', params)
}

// 获取type
export const findbyLkpmaincode = (params = {}): Promise<TypeProps> => {
    return fetchGet('/mdapi/lookupdetails/findbylkpmaincode', params)
}

// 获取token,token在response headers中返回authorization,axios响应拦截中将authorization存储到stroe和缓存中
export const getToken = (params = {}): any => {
    return fetchPostPic('/oauth/token/exchange', params)
}

export const outLogin = (params = {}): Promise<string> => {
    return fetchPost('/oauth/token/revocation', params)
}

export const ssoLogout = (): Promise<string> => {
    return fetchPost('oauth/sso/logout')
}

// 获取legal entity下拉数据
export const getLegalEntityOptions = (params = {}): Promise<LegalEntity[]> => {
    return fetchGet('/usapi/entity/legalEntity', params)
}

// 获取entity下拉数据
export const getEntityOptions = (params = {}): Promise<Entity[]> => {
    return fetchGet('/usapi/entity/entity', params)
}

// 获取Organization下拉数据
export const getOrganizationOptions = (params: any = {}): Promise<OrganizationProps[]> => {
    return fetchGet(`usapi/entity/children/${params.params.parentId}`)
}

export const saveBuAndRegion = (params = {}) => {
    return fetchPost('/usapi/bu/saveBuAndRegion', params)
}

// 获取全部bu
export const getBuTreeList = (params= {}): Promise<BuTreeProps[]> => {
    return fetchGet('/usapi/bu/treeList', params)
}

// 获取全部entity

export const getEntityList = (params= {}): Promise<BuTreeProps[]> => {
    return fetchGet('/usapi/entity/entity', params)
}

// User Role Matrix
// 角色名称下拉框
export const getRoleMatrixRoleOptions = (): Promise<any> => {
    return fetchGet('/usapi/roles/findRolnmenList')
}

export const getRoleMatrixRoleOptionsByGrpId = (grpId: string): Promise<any> => {
    return fetchGet('/usapi/roles/findbygrpid', { params: { grpid: grpId } })
}

// 分页查询role列表接口
export const getRoleMatrixList = (data = {}): Promise<any> => {
    return fetchPost('/usapi/rolepriv/page/list', data)
}

// 导出Role permission Matrix列表接口
export const exportRoleMatrix = (data = {}): Promise<AxiosResponse<Blob>> => {
    return axios.post('/usapi/rolepriv/export', data, {
        responseType: 'blob'
    })
}

// 导出role log接口
export const exportRoleMatrixLog = (): Promise<AxiosResponse<Blob>> => {
    return axios.post('/usapi/rolePrivChangeLog/export', undefined, {
        responseType: 'blob'
    })
}

// fullname下拉框列表
export const getUserMatrixUserOptions = (): Promise<any> => {
    return fetchGet('/usapi/users/searchFullNameOrUserName')
}

// User BU下拉框

// 分页查询user列表接口
export const getUserMatrixList = (data = {}): Promise<any> => {
    return fetchPost('/usapi/users/page/list', data)
}

// 导出Role permission Matrix列表接口
export const exportUserMatrix = (data = {}): Promise<AxiosResponse<Blob>> => {
    return axios.post('/usapi/users/export', data, {
        responseType: 'blob'
    })
}

// 导出role log接口
export const exportUserMatrixLog = (): Promise<AxiosResponse<Blob>> => {
    return axios.post('/usapi/userRoleChangeLog/export', undefined, {
        responseType: 'blob'
    })
}