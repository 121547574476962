
import { defineComponent, ref, watch } from 'vue'
import AddOfferLeft from './AddOfferLeft.vue'
import AddOfferRight from './AddOfferRight.vue'
import Navbar from "@/views/Budget/ConfigurationNav.vue"
import { useRoute } from 'vue-router'
import { NavbarProps } from './types'
import { useStore } from 'vuex'
export default defineComponent({
   components: { Navbar, AddOfferLeft, AddOfferRight },
   name: 'Add Offer',
   setup () {
       const route = useRoute()
       const store = useStore()
       const pathData = ref('')
       if (route.name === 'Add Offer') {
            sessionStorage.setItem('path', route.path)
        }
        if (sessionStorage.getItem('path')) {
            pathData.value = sessionStorage.getItem('path') as string

        }
        const navArr = ref<NavbarProps[]>()
        navArr.value = [
            {
                title: 'BUDGET TEMPLATE',
                path: pathData.value,
                icon: store.state.maintain ? 'icon-remove-circle' : 'icon-circle-1',
                id: '1'
            },
            // {
            //     title: 'SUPPORTING',
            //     path: '/budgetinput',
            //     icon: store.state.maintain ? 'icon-remove-circle' : 'icon-circle-1',
            //     id: '2'
            // }
        ]
        watch(() => store.state.maintain, () => {
            navArr.value = [
                {
                    title: 'BUDGET TEMPLATE',
                    path: pathData.value,
                    icon: store.state.maintain ? 'icon-remove-circle' : 'icon-circle-1',
                    id: '1'
                },
                {
                    title: 'SUPPORTING',
                    path: '/budgetinput',
                    icon: store.state.maintain ? 'icon-remove-circle' : 'icon-circle-1',
                    id: '2'
                }
            ]
        })
       return {
           navArr
       }
   }
})
