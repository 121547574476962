import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e7367af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "breadcrumb d-f align-c" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["breadcrumb-wrapper bg-fff", _ctx.className])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_breadcrumb, { separator: ">" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.crumbLists, (item, index) => {
            return (_openBlock(), _createBlock(_component_a_breadcrumb_item, { key: index }, {
              default: _withCtx(() => [
                (item.mnuuri)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: item.mnuuri
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$i18n.locale === "zh" ? item.mnunmcn : item.mnunmen), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.$i18n.locale === "zh" ? item.mnunmcn : item.mnunmen), 1)
                    ], 64))
              ]),
              _: 2
            }, 1024))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSource, (item, index) => {
            return (_openBlock(), _createBlock(_component_a_breadcrumb_item, { key: index }, {
              default: _withCtx(() => [
                (item.path)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: item.path
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ], 64))
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}