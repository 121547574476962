import { ref } from 'vue'
import { useStore } from 'vuex'
import { addDataKey } from "@/utils";
// import { PGFindall } from '@/API/user'
import { PGProps, PageOptionProps } from '@/views/User/types'
import { message } from "ant-design-vue";
    const useSearch = (pageOptions: PageOptionProps, PGFindall: any, searchType: string) => {
    const store = useStore()
    // 搜suo的用户名
    const enName = ref<string>('');
    const cnName = ref<string>('');
    // 搜suo的用户类型
    const userType = ref<string>();
    const privilegeGroup = ref<PGProps[]>([]);
    // // 分页配置
    // 搜索提交
    // 搜索name的检索有无特殊字符
    const boolSearch = ref(true)
    const searchSubmit = () => {
        if (!boolSearch.value){
            message.error("Please enter qualified characters!")
            return false
          }
          let name = ''
          if (enName.value || cnName.value) {
            name = store.state.lang === "en"? enName.value.trim() : cnName.value.trim()
          }
          const params = {
            [searchType]: userType.value,
            name,// 去除参数是空格的问题
            pageIndex: 0
          };
        PGFindall({ params: params }).then((res: any) => {
            privilegeGroup.value = addDataKey(res.content)
            pageOptions.pageSize = res.size
            pageOptions.total = res.totalElements
            pageOptions.current = 0
        })
    };
    const searchChange = (e: Event) => {
        const event = e && (e.target as HTMLInputElement).value
        const re = /^([a-zA-Z0-9_]|[\u4e00-\u9fa5]|\s){0,}$/g;
        if (!re.test(event)) {
          boolSearch.value = false
          message.error("Please enter qualified characters!")
        } else {
          boolSearch.value = true
        }
    }
    return {
        enName, cnName, userType, searchSubmit, privilegeGroup, searchChange, boolSearch
    }
}
export default useSearch
