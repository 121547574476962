import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_budget_report_content = _resolveComponent("budget-report-content")!
  const _component_main_wrapper = _resolveComponent("main-wrapper")!

  return (_openBlock(), _createBlock(_component_main_wrapper, { className: "p-l-r-10 overflow-h" }, {
    header: _withCtx(() => [
      _createVNode(_component_Breadcrumb, { dataSource: [{name: 'Guideline Report'}] })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_budget_report_content)
    ]),
    _: 1
  }))
}