
import { defineComponent, ref, toRefs, reactive, h, onMounted, nextTick, } from "vue";
import { salesList } from "@/views/Budget/data";
import { ValidProps } from '@/API/types'
import { overviewProps, siofferProps, siofferNameProps, siStatusProps,  expandProps } from "@/views/Budget/types"
import { SelectProps } from '@/API/types'
import { OverviewFindall, CheckExisted, AddOverview, AddSioffer, UpdateOfferStatus, UpdateSiStatus, UpdateSiOffer, getProgramType, systemSettingProgramType } from '@/API/budget'
import { Modal, message } from "ant-design-vue";
import deepClone from '@/utils/deepClone'
import blobDownload from '@/utils/blobDownload';
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import useTableHeight from "@/hooks/useTableHeight";

const columns = [
  {
    title: "Incentive Category",
    dataIndex: "incentiveCategoryName",
    key: "ientive",
    width: "20%",
  },
  {
    title: "Offer Type / SI Offer",
    dataIndex: "offerTypeName",
    key: "offertp",
    width: "30%",
    slots: { customRender: "offerTypeName" },
  },
  {
    // title: "Status * Active",
    dataIndex: "active",
    key: "active",
    // filters: [
    //   { text: 'active', value: 'active' },
    //   { text: 'inactive', value: 'inactive' },
    //   { text: 'all', value: 'all' },
    // ],
    // defaultFilteredValue: ['active'], // 设置默认过滤的值
    // filterMultiple: false,
    width: "30%",
    slots: { title: 'customTitle' ,customRender: "active" },
  },
  {
    title: "Operation",
    dataIndex: "operation",
    key: "operation",
    slots: { customRender: "operation" },
  },
  
];
interface FiltersProps {
  active: string[];
}

export default defineComponent({
  props: {
    keys: {
      default: 1,
      type: Number
    }
  },
  components: {
  },
  setup() {
    const { tableHeight } = useTableHeight(false);
    const router = useRouter()
    const store = useStore()
    const activeText = ref('active')
    const incaentiveList = ref<overviewProps[]>([])
    // 更改数据的key 用来展示折叠效果
    const changeKey = (data: overviewProps[]) => {
      data.forEach((item) => {
        item['key'] = item.offerTypeId
        for (const key in item) {
          if (key === 'siOffer') {
            item['children'] = item[key];
            item['children'].forEach( (siOffer: siofferProps) => {
                for (const sikey in siOffer) {
                  if (sikey === 'name') {
                    siOffer['offerTypeName'] = siOffer[sikey]
                  }
                  if (sikey === 'id') {
                     siOffer['offerTypeId'] = siOffer[sikey]
                     siOffer['key'] = siOffer[sikey]
                  }
                }
                if (!item.offerTypeActive) {
                  siOffer['offerTypeActive'] = false
                } else {
                  siOffer['offerTypeActive'] = true
                }
            })

          }
        }
      })
      return data
    }
    // 过滤si offer
    const tableData = ref<overviewProps[]>([])
    const filterSIoffer = (arr: overviewProps[],type: string) => {
        if (type === 'active') {
           arr.forEach(item => {
              if (item.siOffer) {
                item.siOffer = item.siOffer.filter(sioffer => {
                  return sioffer.active === true
                })
              }
          })
          changeKey(arr)
        }  
    }
    // 默认获取active的数据 
    const defaultData = (data: overviewProps[]): overviewProps[] => {
      if (sessionStorage.getItem('status') === 'active' || !sessionStorage.getItem('status')) {
        data = data.filter(item => item.offerTypeActive === true)
        filterSIoffer(data, 'active')
      } else if (sessionStorage.getItem('status') === 'inactive') {
        data = data.filter(item => item.offerTypeActive === false)
      }
      return data
    }
    // 用于过滤的原始数据
    const filterTableData = ref<overviewProps[]>([])
    const _incaentiveList = (data: overviewProps[]) => {
      for (const key of data) {
        if (incaentiveList.value.findIndex((item: overviewProps) => item.incentiveCategoryName === key.incentiveCategoryName) < 0) {
          incaentiveList.value.push(key);
          continue;
        }
      }
    }
    // 获取初始化数据
    const initTableData = () => {
        getProgramType().then((res) => {
          _incaentiveList(res)
          filterTableData.value = deepClone(changeKey(res)) 
          tableData.value = deepClone(changeKey(defaultData(res))) 

        }) 
    }
    // 过滤table--
    const tableChange = (filters: string) => {
      tableData.value = deepClone(filterTableData.value.filter((item) => {
        if (filters === 'active') {
          activeText.value = 'active'
          sessionStorage.setItem("status", 'active')
          return item.offerTypeActive === true 
        }
        if (filters === 'inactive') {
          activeText.value = 'inactive'
          sessionStorage.setItem("status", 'inactive')
          return item.offerTypeActive === false
        }
        if (filters === 'all' || !filters) {
          sessionStorage.setItem("status", 'all')
          activeText.value = 'all'
          return item
        }
      }))
      filterSIoffer(tableData.value, filters)
    }

    // -------------------------------------
    // 检查offer/si 是否重复
    let copySiName: string
    let siname: string
    // 点击加符号，添加新的一组input 输入SI Offer
    const siOffarr = ref<siofferNameProps[]>([])
    // 验重offer type 或者 si offer
    const judgeType = async(value: string, type: string): Promise<boolean> => {  
        const params = {
            name: value,
            type
        }
        const res = await CheckExisted(params)
        return res
    }
    // 判断新增中si offer数组中有无重复数据
    const isRepeat = (arr: siofferNameProps[]): boolean => {
      const hash= {}
      for (const key in arr) {
        console.log(arr[key].name);
        if (hash[arr[key].name]) {
          return true;
        }
        // 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
        hash[arr[key].name] = true;
      }
      return false;
    }
    // 保存si offer的value (必填项的)
    let shortValue = ''
    // 自定义规则
    const validateAsync = async(rule: ValidProps, value: string) => {
          if ((value && value.split(' ').join('').length === 0) || (!value && rule.field && rule.field !== 'siOfferNameAdd')) {
            return Promise.reject('Please input name')
          }
          if ((siname && siname.split(' ').join('').length === 0 && rule.field === 'siOfferNameAdd')) {
            return Promise.reject('Please input name')
          }
          if ((value && value.length > 100) || (siname && siname.length > 100)) {
            return Promise.reject('Length should be 1 to 100')
          }
          if (rule.field === 'offerTypeName') {
            const res =  await judgeType(value, 'OFFER-TYPE')
            if (res) {
                return Promise.reject('The Offer Type name already exists')
            }
          }
          if (rule.field === 'siOfferName' && copySiName !== value) {
              shortValue = value
             const res = await judgeType(value, 'SI-OFFER')
             if (res) {
                return Promise.reject('The SI Offer name already exists')
            }
          }
          if (rule.field === 'siOfferNameAdd') {
            if (siname) {
              const res = await judgeType(siname, 'SI-OFFER')
              if (res || siname === shortValue || isRepeat(siOffarr.value)) {
                return Promise.reject('The SI Offer name already exists')
              }
            }   
          }
        return Promise.resolve('success')
    }
    // 定义form
    const formData = reactive({
        labelCol: { span: 7 },
        wrapperCol: { span: 15 },
        other: '',
        form: {
            sales: 'Normal Sales',
            salesid: 0,
            incentiveCategoryName: [],
            incentiveCategoryId: '',
            siOfferName: '',
            siOfferId: '',
            offerTypeName: '',
            offerTypeId: ''

        },
        rules: {
           incentiveCategoryName: [
            {
              required: true,
              message: 'Please input name',
              trigger: 'blur'
            },
          ],
           offerTypeName: [
            {
              required: true,
              validator: validateAsync,
              trigger: 'blur'
            },
          ],
           siOfferName: [
            {
              required: true,
              validator: validateAsync,
              trigger: 'blur'
            },
          ],
          siOfferNameAdd: [
            {
              validator: validateAsync,
              trigger: 'blur'
            },
          ],
        }
    })
    // 获取form元素
    const getofferRef = ref()
    const offerRef = (el: HTMLElement | null) => {
        nextTick(() => {
          getofferRef.value = el
        })
    }
    
    // 自定义展开icon图标-----------------------展开折叠部分
    const clickExpand = (props: expandProps,e: Element) => {
         props.onExpand(props.record, e)
    } 
    // 自定义展开icon
    const expandIcon = (props: expandProps) => {
      if (props.record.children && props.record.children.length > 0 ) {
        if (props.expanded) {
          //有数据-展开时候图标
          return h("i", {
            class: "font_family icon-xiangxia m-l-5 cursor-p",
            onClick: (e: Element) => {
              clickExpand(props,e)
            },
          });
        } else {
          //有数据-未展开时候图标
          return h("i", {
            class: "font_family icon-xiangyou m-l-5 cursor-p",
            onClick: (e: Element) => {
              clickExpand(props,e)
            },
          });
        }
      } else {
        //无数据-图标
        return h("i", {
            class: "m-l-5",
            style: { width: '16px', height: '1px', display: 'inline-block'}
          });
      }
    };
    // 展开所有的折叠--------------
    const expKeys = ref<string[]>([])
    let expandBool = false
    const handleExpand = () => {
      if (expandBool) {
        expKeys.value = []
        expandBool = false
      } else {
        tableData.value.forEach((item): void => {
          if (item.siOffer) {
            if ( item.siOffer.length > 0) {
              expKeys.value.push(item.offerTypeId)
              expKeys.value = Array.from(new Set(expKeys.value))
              expandBool = true
            } 
          }
          
        })
      }
      
    }
    // 点击展开图标触发
    const onExpand = (expanded: boolean, record: overviewProps) => {
      if (expanded) {
        // 设置展开窗Key，代表展开操作
        expKeys.value.push(record.offerTypeId)
        expKeys.value = Array.from(new Set(expKeys.value))
      } else {
        // 代表折叠操作
        if (expKeys.value.length) {
          nextTick( () => {
            expKeys.value = expKeys.value.filter(v => {
              return v !== record.offerTypeId
            })
          })
          
        }
      } 
    }

    // modal------------------------------------开始
    // 添加offer type (isAdd === 1 代表添加， isAdd === 0 代表编辑)
    const isAdd = ref<number>(1)
    const visibleOffer = ref(false)
    const handleAddOffer = () => {
        visibleOffer.value = true
        isAdd.value = 1
        formData.form.offerTypeName = ''
    }
    
    
    // 用来判断是否可以添加si offer
    const isSIoffer = ref(true)
    // 下拉的回调函数
    const selectChange = (value: string, option: SelectProps) => {
        // 下拉选择 Dealer Incentive时
        formData.form.incentiveCategoryId = option.key
        formData.form.offerTypeName = ''
        // Dealer 
        if (option.key === "67ff8bd2-5be2-4a80-9e12-2a3c92ca769f" ) {
            isSIoffer.value = false
            siOffarr.value = []
        } else {
            isSIoffer.value = true
        }
    }
    // 添加 offer type
    const createOffer = () => {
        const siOffer: siofferNameProps[] = []
        // 添加的si offer数据
        siOffarr.value.forEach((item) => {
          if (item.name) {
            siOffer.push(item)
          }
        })
        // 如果当前是不是Dealer类型 
        if (isSIoffer.value) {
          // 必填项的si offer数据
          siOffer.push({name: formData.form.siOfferName, active: true})
        }
        const params= {
          incentiveCategoryId: formData.form.incentiveCategoryId,
          offerTypeName: formData.form.offerTypeName,
        }
        getofferRef.value.validate().then(() => {
            AddOverview(params).then((res) => {
              const paramss= {
                incentiveCategoryId: formData.form.incentiveCategoryId,
                offerTypeName: formData.form.offerTypeName,
                offerTypeId: res.offerTypeId
              }
              systemSettingProgramType(paramss)
              // 跳转到add offer
              const { href }  = router.resolve({
                path: `/addoffer/${res.offerTypeId}/${res.offerTypeName}/true`,
              })
              window.open(href,'_blank')
              // 设置控制addoffer页面的操作
              store.commit('updateOperable',false)
              siOffarr.value = []
              getofferRef.value.resetFields();
              initTableData()
            })
            visibleOffer.value = false
            message.success("Add Offer type successfully!")
            // 初始化可以添加Si offer
            isSIoffer.value = true
        })
        .catch((error: string) => {
          console.log('error', error);
        });
    }
    // 关闭添加offer type 页面
    const cancelOffer = () => {
        visibleOffer.value = false
        isSIoffer.value = true  
        siOffarr.value = []
        getofferRef.value.resetFields();
    }
    // 编辑 offer type （还没设计好。。。）
    const handleEditOffer = () => {
        visibleOffer.value = true
        formData.form.siOfferName = '123'
        isAdd.value = 0
    }
    // 获取Offer type Name (用来展示offer type信息)
    const getOfferType = (id: string): overviewProps=> {
        let offerData: any;
        tableData.value.forEach((item) => {
          if (item.siOffer) {
            item.siOffer.forEach((siOffer) => {
              if (siOffer.id === id) {
                offerData = item
              }
            })
          }
        })
        return offerData
    }

    // 添加SI offer的确定
    // let offerTypenm = ''
    const checkboxArr = ref<string[]>([]) // 需要激活的si offer
    const optionsData = ref<siofferProps[]>([]) // 复选框中的数据
    const newSioffer = ref<siofferProps[]>([]) // 添加si offer后获取的数据，需要添加到复选框中，并默认选中
    const visibleStatus = ref(false)

    // 更改status
    // 更改父元素的status
    const handleDisableOffer = (record: overviewProps) => {
      Modal.confirm({
        class: 'my-confirm',
        content: h('div', {}, [
          h('i',{ 'class': 'font_family icon-old-Warning m-confirm'}, ''),
          h('h1',{ 'style': 'font-size: 18px; line-height: 70px'} , `Change status to ${record.offerTypeActive ? "Inactive" : "Active"} ?`),
        ]),
        cancelText: 'No',
        okText: 'Yes',
        onOk() {
          // inactive --》 active
          const params = { offerTypeActive: !record.offerTypeActive }
          UpdateOfferStatus(params,record.offerTypeId).then(() => {
            message.success(`${record.offerTypeName} status changed to ${record.offerTypeActive ? "Inactive" : "Active"}!`)
            initTableData()
          })
        },
        onCancel() {
          console.log("cancel");
        },
      });
    };

    // 取消复选框的勾选
    const cancelStatus = () => {
      checkboxArr.value = []
    }
    
    // 更改offer type的状态
    const handleChangeStaus = (record: overviewProps) => {
      // 更改成inactive 和 Icentive是Dealer类型
      if (record.offerTypeActive || !record.siOffer.length) {
        handleDisableOffer(record)
      } else {
        visibleStatus.value = true
        formData.form.offerTypeName = record.offerTypeName
        optionsData.value = record.siOffer
        formData.form.offerTypeId = record.offerTypeId
      }    
    }
    // SI offer有一个变成acitve, offer Type就变成active, 所以提示信息也需要有SI offer,和offer Type
    const InactiveToActive = (data: overviewProps) => {
        message.success(`${data.offerTypeName} status changed to Active!`)
        for (const item of data.siOffer) {
          message.success(`${item.name} status changed to Active!`)
        }
    }
    // 自定义修改status时的title样式
    // const modalTitle =  h('h1', {}, [
    //    h('span', {},  'Select Active SI Offer or Add SI Offer') ,
    //     h('i',{ class: 'iconfont icon-pen-31',style: 'margin-left: 10px',onClick: ()=>{
    //           visibleSI.value = true
    //     }}),
    //   ])

    // 将offer type的状态inactive修改成active确定时
    const confirmStaus = async() => {
        if (checkboxArr.value.length > 0) {
          const paramsOffer = { offerTypeActive: true }
          await UpdateOfferStatus(paramsOffer, formData.form.offerTypeId)
          const params: siStatusProps[] = []
          checkboxArr.value.forEach((item: string) => {
            params.push({id: item, active: true})
          })
          UpdateSiStatus(params, formData.form.offerTypeId).then(res => {
              InactiveToActive(res)
              initTableData()
              checkboxArr.value = []
          })
          visibleStatus.value = false
        } else {
           message.error('Please activate the SI Offer first!');
        }
    }
    
    // 更改子元素的status
    const handleDisable = (record: siofferProps) => {
      const siOfferArr = getOfferType(record.id).siOffer
          //  默认lastActive是true代表是当前si offer是最后一个active的
          let lastActive = true
          if (record.active) {
            if (siOfferArr){
              siOfferArr.forEach((item) => {
                if ((item.id !== record.id && item.active)) {
                    lastActive = false
                  }
              })
            
              if (siOfferArr.length === 1) {
                lastActive = true
              }
            }
          } else {
            lastActive = false
          }
        Modal.confirm({
          class: 'my-confirm',
          width: lastActive ? 620 : 400,
          content: h('div', {}, [
            h('i',{ 'class': 'font_family icon-old-Warning m-confirm'}, ''),
            h('h1',{ 'style': `font-size: 18px; line-height: 70px`} , lastActive ? 'If Inactive current SI Offer, Offer Type  will also be Inactive.': `Change status to ${record.active ? "Inactive" : "Active"} ?`),
          ]),
          cancelText: 'No',
          okText: 'Yes',
          onOk() {
            const offerTypeId = getOfferType(record.id).offerTypeId
            
            if (lastActive) {
                // console.log(content);
                const params = { offerTypeActive: false }
                UpdateOfferStatus(params, offerTypeId).then(() => {
                  message.success(`${record.name} status changed to Inactive`)
                  initTableData()
                })
            } else {
              const params = [
                {
                  id: record.id,
                  active: !record.active
                }
              ]
              UpdateSiStatus(params,offerTypeId).then(() => {
                  initTableData()
              })
            }
          
        },
        onCancel() {
          console.log("cancel");
        },
      });
    };
    // -----------------------------------------
    // save Offer Type
    
   

    // 导出excel
    const expotToExcel = () => {
      const params = {
        url: '/pgapi/program/setting/export_program_type',
        params: {
          programTypeKind: 'Normal'
        }
      }
      blobDownload(params)
    }
  
    onMounted(() => {
      // 设置表格的头部状态文字显示
      if (sessionStorage.getItem('status')) activeText.value = sessionStorage.getItem('status') as string
      // 设置控制addoffer页面的操作
      store.commit('updateOperable',true)
      // 初始化数据;
      initTableData()
    });
    return {
      tableHeight,
      // 数据
      tableData,
      optionsData,
      salesList,
      incaentiveList,
      activeText,
      // 自定义title
      // modalTitle,
      // table的分页,过滤
      tableChange,
      columns,
      // table展开icon,控制
      expandIcon,
      onExpand,
      expKeys,
      handleExpand,
      ...toRefs(formData),
      offerRef,
      // modal的事件,变量
      isAdd,
      visibleOffer,
      handleAddOffer,
      createOffer,
      cancelOffer,
      handleEditOffer,
      visibleStatus,
      handleChangeStaus,
      handleDisable,
      confirmStaus,
      selectChange,
      isSIoffer,
      checkboxArr,// 复选框的集合
      cancelStatus,
      // 导出excel
      expotToExcel,
    };
  },
});
