import { Module } from 'vuex';
import { GlobalDataProps } from '@/store/index';
import { TreeDataItem } from "@/views/User/ApprovalProcess/components/buTreeType"
export interface ApprovalChainDataProps {
    BUTreeData: TreeDataItem[];
    BUListData: string[];
}

const approvalChain: Module<ApprovalChainDataProps, GlobalDataProps> = {
    namespaced: true,
    state: {
        BUTreeData: [],
        BUListData: []
    },
    mutations: {
        updateBUTreeData: (state, data) => {
            state.BUTreeData = data
        },
        updateBUListData: (state, data) => {
            state.BUListData = data
        },
    }
}
export default approvalChain;