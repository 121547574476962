import { Module } from 'vuex';
import { GlobalDataProps } from '@/store/index';
import { TableData } from "@/hooks/checking/spotChecking/usePaymentPreparation"
export interface ApprovalChainDataProps {
    curPayoutRowData: Partial<TableData>;
    payoutRoundData: Partial<TableData>;
    packageData: Partial<TableData>;
    packageFinanceData: Partial<TableData>;
    sampleCheckPackagecodeId: string;
    sampleCheckPackagecode: string;
    sampleCheckPayoutRound: {
      payoutRoundId?: string;
      payoutRound?: string;
      type?: string;
    };
    spotCheckConfirmPackageDealer: any;
    spotCheckConfirmProgramDealer: any;
    spotCheckConfirmPackageFl: any;
    spotCheckConfirmProgramFl: any;
    spotCheckStatus: any;
    
}
const spotChecking: Module<ApprovalChainDataProps, GlobalDataProps> = {
    namespaced: true,
    state: {
        curPayoutRowData: {},
        payoutRoundData: {},
        packageData: {},
        packageFinanceData: {},
        sampleCheckPackagecodeId: '',
        sampleCheckPackagecode: '',
        sampleCheckPayoutRound: {},
        spotCheckConfirmPackageDealer: {},
        spotCheckConfirmProgramDealer: {},
        spotCheckConfirmPackageFl: {},
        spotCheckConfirmProgramFl: {},
        spotCheckStatus: {} // checkStatus 原始数据
    },
    mutations: {
        updateSpotCheckStatus: (state, data) => {
            state.spotCheckStatus = data
        },
        updatePayoutCurRow: (state, data) => {
            state.curPayoutRowData = data
        },
        updatePayoutRoundData: (state, data) => {
            state.payoutRoundData = data
        },
        updatePackageData: (state, data) => {
            state.packageData = data
        },
        updatePackageFinanceData: (state, data) => {
            state.packageFinanceData = data
        },
        updateSampleCheckPackagecodeId: (state, id) => {
            state.sampleCheckPackagecodeId = id
        },
        updateSampleCheckPackagecode: (state, code) => {
            state.sampleCheckPackagecode = code
        },
        updateSampleCheckPayoutRound: (state, payoutRound) => {
            state.sampleCheckPayoutRound = payoutRound
        },
        // 4717 -> 4720
        updateSpotCheckConfirmPackageDealer: (state, data) => {
            state.spotCheckConfirmPackageDealer = data
        },
        // 4720 -> 4722
        updateSpotCheckConfirmProgramDealer: (state, data) => {
            state.spotCheckConfirmProgramDealer = data
        },
         // 4717 -> 4721
         updateSpotCheckConfirmPackageFl: (state, data) => {
            state.spotCheckConfirmPackageFl = data
        },
        // 4721 -> 4723
        updatespotCheckConfirmProgramFl: (state, data) => {
            state.spotCheckConfirmProgramFl = data
        }
    }
}
export default spotChecking;