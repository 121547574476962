//千分数转数字
export const toNumber = (value: number | string): string => {
    const reg = RegExp(/%/g);
    if (reg.test(value.toString())) {
        return (Number((value || 0).toString().replace(/,|%/g, '')) / 100).toString();
    }
    return (value || 0).toString().replace(/,|%/g, '');
}


//转换千分数
export const toThousands = (value: number | string, saveDecimal=  false): string => {
    if (!value) {
        return ''
    }
    if (!saveDecimal) {
        let _value = value.toString();
        _value.includes(',') && (_value = toNumber(_value).toString());
        return parseInt(_value).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    } else {
        let _value = value.toString();
        _value.includes(',') && (_value = toNumber(_value).toString());
        _value.includes('.') && (_value += '.');
        return _value.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
            return $1 + ',';
        }).replace(/\.$/, '');

    }
}
