/*
 * @Author: guowu
 * @Date: 2021-07-27 10:29:27
 * @FilePath: \oasis-frontend\src\router\systemRouters.ts
 */
import { RouteRecordRaw } from "vue-router";

export const systemVehicleRouters: Array<RouteRecordRaw> = [
  {
    path: "/vehicleModel",
    name: "VehicleModel",
    component: () => import("@/views/System/VehicleModelMaster/VehicleModel.vue"),
  },
  {
    path: "/vehicleModel/vehicleModelView",
    name: "VehicleModelView",
    component: () => import("@/views/System/VehicleModelMaster/VehicleModelView.vue"),
  },
  {
    path: "/vehicleManagement",
    name: "VehicleManagement",
    component: () => import("@/views/System/VehicleManagement/VehicleManagement.vue"),
  },
  {
    path: "/vehicleManagement/vehicleView",
    name: "VehicleManagement VehicleModelView",
    component: () => import("@/views/System/VehicleManagement/VehicleView.vue"),
  },
  {
    path: '/kufriPanel',
    name: 'KufriPanel',
    component: () => import('@/views/System/Kufri/KufriPanel.vue')
  },
  {
    path: '/kufriPanel/:make',
    name: 'KufriPanelControl',
    component: () => import('@/views/System/Kufri/KufriPanelControl/index.vue')
  },
  {
    path: '/kufriActive',
    name: 'KufriActive',
    component: () => import('@/views/System/Kufri/KufriActive/kufriActive.vue')
  },
  {
    path: '/kufriDetail',
    name: 'KufriDataDetail',
    component: () => import('@/views/System/Kufri/KufriPanelControl/index.vue')
  },
  {
    path: '/updateMSRP',
    name: 'UpdateMSRP',
    component: () => import('@/views/System/Kufri/UpdateMSRP/updateMSRP.vue')
  },
  {
    path: '/nstGroupMapping',
    name: 'NstGroupMapping',
    component: () => import('@/views/System/Kufri/NstMapping/index.vue')
  },
  {
    path: '/NSTGroupMSRP',
    name: 'NSTGroupMSRP',
    component: () => import('@/views/System/NSTGroupMSRP/index.vue'),
    children: [
      {
        path: '',
        name: 'IMPACTMSRPWVAT',
        meta: {
          showInTab: true,
          title: 'NST Group MSRP Override',
        },
        component: () =>
          import(
            '@/views/System/NSTGroupMSRP/NSTGroupMSRP/NSTGroupMSRP.vue'
          ),
      },
      {
        path: '/VATRate',
        name: 'VATRate',
        meta: {
          showInTab: true,
          title: 'VAT Rate',
        },
        component: () =>
          import(
            '@/views/System/NSTGroupMSRP/VATRate/VATRate.vue'
          ),
      },
    ],
  },
  {
    path: '/IMPACTMSRP',
    name: 'IMPACTMSRP',
    component: () => import('@/views/System/IMPACTMSRP/IMPACTMSRP.vue')
  },
  {
    path: '/taskcenter',
    name: 'taskCenter',
    component: () => import('@/views/System/TaskCenter/index.vue'),
  },
];
