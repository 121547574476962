import { RouteRecordRaw } from 'vue-router'

export const closingRouters: Array<RouteRecordRaw> = [
  {
    path: '/closing',
    name: 'closing Homepage',
    component: () => import('@/views/Closing/index.vue')
  },
  {
    path: '/report',
    name: 'Report Structure',
    component: () => import('@/views/Report/index.vue')
  },
  {
    path: '/setPMT',
    name: 'Set PMT Closing Rule',
    component: () => import('@/views/Closing/Configuration/SetPMT.vue')
  },
  // 下面是临时放的usercar板块
  {
    path: '/UploadUsedCarPage',
    name: 'User Car',
    component: () => import('@/views/UserCar/UploadUsedCarPage.vue')
  }, {
    path: '/DealerPage',
    name: 'User Car DealerPage',
    component: () => import('@/views/UserCar/DealerCUCD.vue')
  }, {
    path: '/UCSicco',
    name: 'User Car UCSICCO',
    component: () => import('@/views/UserCar/UCSICCO.vue')
  },
]
