/*
 * @Author: guowu
 * @Date: 2021-11-15 13:43:50
 * @LastEditTime: 2021-12-17 14:18:49
 */
import { RouteRecordRaw } from 'vue-router'

export const vlRouters: Array<RouteRecordRaw> =[
  {
    path:'/vehiclelicense',
    name:'Vehicle License',
    component: () => import('@/views/VehicleLicense/VehicleLicenseSubmit/index.vue')
  },
  {
    path:'/vehiclelicense/siccoChecking',
    name:'Vehicle License SICCO Checking',
    component: () => import('@/views/VehicleLicense/SICCOChecking/index.vue')
  },
  {
    path:'/vehiclelicense/siccoCheckingSampling',
    name:'Vehicle License SICCO Checking Sampling',
    component: () => import('@/views/VehicleLicense/SICCOCheckingSampling/index.vue')
  },
  {
    path:'/vehiclelicense/siccoCheckingDetail',
    name:'Vehicle License SICCO Checking Detail',
    component: () => import('@/views/VehicleLicense/SamplingDetail/index.vue')
  },
  {
    path:'/vehiclelicense/siccoCheckingDetailView',
    name:'Vehicle License SICCO Checking Detail View',
    component: () => import('@/views/VehicleLicense/SamplingDetail/indexView.vue')
  },
  {
    path:'/vehiclelicense/checkingResult',
    name:'Vehicle License SICCO Checking Result',
    component: () => import('@/views/VehicleLicense/CheckingResult/index.vue')
  },
  {
    path:'/vehiclelicense/dealercommunication',
    name:'Vehicle License Dealer Communication',
    component:() =>import('@/views/VehicleLicense/DealerCommunication/index.vue')
  },
]
