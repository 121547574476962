
    import { defineComponent, onMounted, ref, watch, reactive, toRefs, onBeforeUnmount, nextTick } from 'vue'
    import BudgetInputSet from './BudgetInputSet.vue'
    import {
        BudgetTableProps,
        BudgetInfoProps,
        SiOfferCustomerVo,
        BudgetKufriProps,
        CustomerProps,
        SmallTableProps
    } from './types'
    import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css'
    import GC from '@grapecity/spread-sheets'
    import './licensen';
    import { initOutlineColumn } from '../BudgetReport/utils';
    import {
        getBudgetInfo,
        getBudgetTableData,
        updateInputData,
        getBudgetKufriData,
        findbudgetDataOverview,
        getVersion,
        updateVersion,
        updateProgramView,
        budgetExportView, getBudgetAllocationData,
        getFillInMonthlyData,
        getCancelFillInMonthlyData,
        fleetBudgetDataSummary
    } from '@/API/budget';
    import {
        handlerSheetsData,
        ssHotTop,
        bindDataTable,
        setHeader,
        inputEventToFormula,
        autoHeaderFitColumn,
        setHeaderCellTypeTip,
        ssHostSmall,
        isVisibleSpecialIndex
    } from './utils';
    import { useRoute, useRouter } from 'vue-router'
    import { useStore } from "vuex";
    import deepClone from "@/utils/deepClone";
    import { toThousands} from "@/utils/approve";
    // 不同的offerType, inputMonth和按钮copy kufri，setVolume base显示否，都有brand,没有model year.只有overview会有customer
    const visibleOfferList = ['BMBS Initiated Fleet', 'BMBS Oversea Returnee', 'BMBS Initiated Fleet - Special',  'Dealer Initiated Fleet', 'Dealer Initiated Fleet - Special', 'Dealer service support']
    const companyCar = ['Company Car']
    // PMT, Demo Car 是通用的，因判断了有没有Model Year
    const tableHeaderListSummery = ['Period', 'Brand', "Type Class", "Model Year", "Model"]
    // fleet overview
    const tableHeaderListSummery2 = ['Make', 'Customer', 'Brand', 'Type Class', "Model Year", 'Model']

    
    // 只要在这个里面的，就是tableHeaderListSummery1， 在这个里面并且有overview的，就是tableHeaderListSummery2
    // overview的小表格
    
    export default defineComponent({
        components: { BudgetInputSet },
        name: 'Budgut Input Controlling',
        setup() {
            const route = useRoute();
            const router = useRouter();
            const { state } = useStore();
            const visible = ref<boolean>(false);
            const active = ref<boolean>(false); // 标识Input By Month是否选中

            const offerTypeValue = ref<string>('');
            const siOfferValue = ref<string>('');
            const currentTab = ref<string>('budget');
            
            const fleetCustomerList = ref<CustomerProps[]>([])

            //是否是最新的version
            const latestVersion = ref<boolean | null>(null);
            // 是否是overview fleet

            const isOverview = ref<string>('')
            const budgetPeriod = ref<string>('')
        




            // sheet当前表格的初始化
            let worksheet: GC.Spread.Sheets.Worksheet
            let workbook: GC.Spread.Sheets.Workbook
            let worksheetSmall: GC.Spread.Sheets.Worksheet
            let workbookSmall: GC.Spread.Sheets.Workbook
            //budgetInfo
            const budgetInfoData = reactive<BudgetInfoProps>({
                offerType: [],
                programInfoVo: {
                    programId: '',
                    programCode: '',
                    programName: '',
                    status: '',
                    kufriVersion: '',
                    bu: '',
                }
            });

            const dataSmall = ref<SmallTableProps[]>([])

            // 定义kufriVersion
            const kufriVersion = ref('')

            //siOffer
            const siOfferData = ref<SiOfferCustomerVo[]>([]);

            //budget table data
            const budgetTableData = reactive<BudgetTableProps>({
                header: [],
                inputTableCell: []
            })

            //当前的sioffer下make
            const activeMakeValue = ref<string>('');

            //budget kufri data
            const budgetKufriData = reactive<BudgetKufriProps>({
                cbuKufri: '',
                cbuBudget: '',
                pbpKufri: '',
                pbpBudget: '',
                vanKufri: '',
                vanBudget: '',
                denzaKufri: '',
                denzaBudget: ''
            })


            // const hasCustomerList = () => {

            // }
            // customer选中值
            const customerValue = ref<string>('')

        

            const budgetTableDataH = ref<number>(400);

            const budgetAllocationTableDataH = ref<number>(100);

            // 根据不同的offerType不同，需要处理的header也不同
            const headerArr = (): string[] => {
                let arr: string[];
                if (isVisibleSpecialIndex(visibleOfferList, offerTypeValue.value, budgetInfoData.offerType) > -1) {
                    arr = tableHeaderListSummery2
                } else {
                    arr = tableHeaderListSummery
                }
                return arr
            }

            // 初始第一个表格
            const ssHotTopInit = () => {
                const spread = new GC.Spread.Sheets.Workbook(document.getElementById('ssHost') as HTMLElement);
                const sheet = spread.getActiveSheet();
                worksheet = sheet
                workbook = spread;
                
                ssHotTop(spread, budgetTableData, budgetTableData.inputTableCell, budgetTableData.header, false, true, budgetInfoData.programInfoVo.bu);
                inputEventToFormula(spread, budgetTableData, budgetTableData.header);
                autoHeaderFitColumn(spread, budgetTableData.header);
                if (budgetTableData.inputTableCell.length > 0) {
                    initOutlineColumn(sheet);
                }
                // 不能允许操作
                 const option = {
                    allowSelectLockedCells: false,
                    allowSelectUnlockedCells: false,
                    allowFilter: false,
                    allowSort: false,
                    allowResizeRows: true,
                    allowResizeColumns: false,
                    allowEditObjects: false,
                    allowDragInsertRows: false,
                    allowDragInsertColumns: false,
                    allowInsertRows: false,
                    allowInsertColumns: false,
                    allowDeleteRows: false,
                    allowDeleteColumns: false
                };
                sheet.options.protectionOptions = option;
                sheet.options.isProtected = true;
            }
            
            const ssHotSmallInit = () => {
                const spreadSmall = new GC.Spread.Sheets.Workbook(document.getElementById('ssHostSmall') as HTMLElement);
                const sheetSmall = spreadSmall.getActiveSheet();
                worksheetSmall = sheetSmall
                workbookSmall = spreadSmall;
                ssHostSmall(spreadSmall, sheetSmall, dataSmall.value);
            }
            //根据bu获取version最新版本
            const fetchLastVersion = (make: string) => {
                getVersion(make).then(data => {
                    data == kufriVersion.value ? latestVersion.value = true :  latestVersion.value = false;
                })
            }

            //获取budgetInfo的信息
            const fetchBudGetInfo = (): void => {
                getBudgetInfo(route.query.pgmId).then(data => {
                    Object.assign(budgetInfoData, data);
                    offerTypeValue.value = data.offerType[0].offerTypeId;
                    // fleetCustomerList.value = data.offerType[0].siOfferCustomerVo[0].fleetCustomerList
                    // customerValue.value = fleetCustomerList.value[0].budgetId
                    // fetchLastVersion(data.programInfoVo.bu);
                })
            }
            const getBudgetId = (siofferId: string): string => {
                const siOfferList = budgetInfoData.offerType.filter((item) => {
                    return item.offerTypeId == offerTypeValue.value;
                })
                const budgetIdList = siOfferList[0].siOfferCustomerVo.find((item) => {
                    return item.siOfferId === siofferId
                })
                let budgetId = '';
                if (budgetIdList) {
                    if (budgetIdList.fleetCustomerList) {
                        budgetId = budgetIdList.fleetCustomerList[0].budgetId
                    } else {
                        budgetId = budgetIdList.budgetId as string
                    }
                }
                return budgetId
            }
            //根据offerId获取budget table 主数据
            const fetchBudgetTableData = (budgetId: string): void => {
                if (budgetId === undefined) {
                    findbudgetDataOverview(route.query.pgmId, offerTypeValue.value).then(data => {
                        budgetTableDataH.value =  (data.inputTableCell.length + 1) * 40;
                        Object.assign(budgetTableData, handlerSheetsData(data, active.value, headerArr()));
                        //如果初始化后直接调用bindDataTable 更新数据而不是初始化
                        if (worksheet) {
                            workbook.destroy();
                            ssHotTopInit();
                        } else {
                            ssHotTopInit();
                        }
                    })
                    fleetBudgetDataSummary(route.query.pgmId, offerTypeValue.value).then(data => {
                        dataSmall.value = data
                        if (worksheetSmall) {
                            workbookSmall.destroy();
                            ssHotSmallInit();
                        } else {
                            ssHotSmallInit();
                        }
                    })
                } else {
                    getBudgetTableData(budgetId).then(data => {
                        budgetTableDataH.value =  (data.inputTableCell.length + 1) * 40;
                        Object.assign(budgetTableData, handlerSheetsData(data, active.value, headerArr()));
                        //如果初始化后直接调用bindDataTable 更新数据而不是初始化
                        if (worksheet) {
                            workbook.destroy();
                            ssHotTopInit();
                            // inputEventToFormula(workbook, budgetTableData, budgetTableData.header);
                            // setHeader(workbook, budgetTableData.header);
                            // bindDataTable(workbook, budgetTableData.inputTableCell);
                            // autoHeaderFitColumn(workbook, budgetTableData.header);
                            // setHeaderCellTypeTip(workbook, budgetTableData.header);
                            // ssHotSmallInit() // 异步时，初始化才能拿到
                        } else {
                            ssHotTopInit();
                        }
                    })
                }
            }

            //获取kufri的数据
            const fetchBudgetKufriData = (): void => {
                getBudgetKufriData(getBudgetId(siOfferValue.value)).then(data => {
                    Object.assign(budgetKufriData, data);
                })
            }

            // copy kufri  需求 copy kufri字段的值到Pipeline Override
            const handlerCopyKufriClick = (sheet: GC.Spread.Sheets.Worksheet): void => {
                //汇总行的type
                const summaryTypes = ['TYPECLASSSUMMARY', 'MODELYEARSUMMARY'];
                //kufri Pipeline Override的索引
                const index: number[] = [];
                //根据header确认着两个字段的索引
                for (const item of budgetTableData.header) {
                    if (item.name.indexOf('Kufri') > -1) {
                        index.push(item.columnNumber)
                    }
                    if (item.name.indexOf('Pipeline Override') > -1) {
                        index.push(item.columnNumber)
                    }
                }

                for (let row = 0; row < budgetTableData.inputTableCell.length; row++) {
                    //排除汇总行
                    if (summaryTypes.includes(budgetTableData.inputTableCell[row][0].type)) {
                        continue;
                    }
                    for (let cell = 0; cell < budgetTableData.inputTableCell[row].length; cell++) {
                        //判断Pipeline Override 是 WRITE 在赋值
                        if (budgetTableData.inputTableCell[row][index[1]].type === 'WRITE') {
                            budgetTableData.inputTableCell[row][index[1]].contents = budgetTableData.inputTableCell[row][index[0]].contents
                        }
                    }
                }
                bindDataTable(workbook, budgetTableData.inputTableCell);
                autoHeaderFitColumn(workbook, budgetTableData.header);
            }

            // 显示月份的具体信息
            const handlerCheckboxChange = (): void => {
                // 返回数据后
                if (active.value) {
                    getFillInMonthlyData(getBudgetId(siOfferValue.value)).then(res => {
                        Object.assign(budgetTableData, handlerSheetsData(res, active.value, headerArr()));
                        if (workbook) {
                            inputEventToFormula(workbook, budgetTableData, budgetTableData.header);
                            setHeader(workbook, budgetTableData.header);
                            bindDataTable(workbook, budgetTableData.inputTableCell);
                            autoHeaderFitColumn(workbook, budgetTableData.header);
                            setHeaderCellTypeTip(workbook, budgetTableData.header);
                        }
                    })
                } else {
                    getCancelFillInMonthlyData(getBudgetId(siOfferValue.value)).then((res) => {
                        Object.assign(budgetTableData, handlerSheetsData(res, active.value, headerArr()));
                        if (workbook) {
                            inputEventToFormula(workbook, budgetTableData, budgetTableData.header);
                            setHeader(workbook, budgetTableData.header);
                            bindDataTable(workbook, budgetTableData.inputTableCell);
                            autoHeaderFitColumn(workbook, budgetTableData.header);
                            setHeaderCellTypeTip(workbook, budgetTableData.header);
                        }
                    })
                }
            }

            // 刷新数据
            const handleRefresh = (): void => {
                //用于更新budgetReport使用，不关心是否成功
                updateProgramView(getBudgetId(siOfferValue.value)).then(res => {
                    console.log('更新数据到budgetReport', res);
                })
    
                //生成分摊
                getBudgetAllocationData(getBudgetId(siOfferValue.value)).then(data => {
                    //用于更新budgetReport使用，不关心是否成功
                    budgetExportView(getBudgetId(siOfferValue.value)).then(res => {
                        console.log('更新数据到budgetReport', res);
                    })
                })
                if (latestVersion.value) {
                    fetchBudgetKufriData();
                    fetchBudgetTableData(getBudgetId(siOfferValue.value));
                } else  {
                    updateVersion(activeMakeValue.value, getBudgetId(siOfferValue.value)).then(data => {
                        fetchBudGetInfo();
                    })
                }
            }

            //处理保存
            const handlerSaveClick = (): void => {
                const _budgetTableData = deepClone(budgetTableData);
                _budgetTableData.inputTableCell.forEach(row => {
                    row.forEach(cell => {
                        delete cell.contentsType;
                        delete cell.trigger;
                        delete cell.visible;
                        delete cell.align;
                        delete cell.width;
                    })
                });
                updateInputData(_budgetTableData).then(data => {
                    handleRefresh();
                })
            }

            //切换显示
            const handleSetVolumeBaseClick = (): void => {
                visible.value = !visible.value;
            }

            //处理切换Volume Base
            const handlerOk = (type: string): void => {
                //顺序是固定的 所以找到的索引肯定是Kufri  和 Pipeline Override
                const volumeBaseKeys: string[] = ['Kufri', 'Pipeline Override'];
                const volumeBaseIndex: number[] = [];
                for (let i = 0; i < budgetTableData.header.length; i ++) {
                    if (budgetTableData.header[i].name.indexOf('Kufri') > -1 || budgetTableData.header[i].name.indexOf('Pipeline Override') > -1) {
                        volumeBaseIndex.push(i);
                    }
                }
                const _budgetTableData =  deepClone(budgetTableData);
                _budgetTableData.header[volumeBaseIndex[0]].name = `${type} ${volumeBaseKeys[0]}`;
                _budgetTableData.header[volumeBaseIndex[1]].name = `${type} ${volumeBaseKeys[1]}`;
                _budgetTableData.inputTableCell.forEach(row => {
                    row.forEach(cell => {
                        delete cell.contentsType;
                        delete cell.trigger;
                        delete cell.visible;
                        delete cell.align;
                        delete cell.width;
                    })
                })
                updateInputData(_budgetTableData).then(data => {
                    handleRefresh();
                })
            }

            //处理切换offerType
            const handlerToggleOfferType = (offerTypeId: string): void => {
                offerTypeValue.value = offerTypeId;
                const activeOfferType =  budgetInfoData.offerType.filter(item => {
                    item.offerTypeId == offerTypeId;
                })
                if (activeOfferType.length > 0) {
                    siOfferData.value = activeOfferType[0].siOfferCustomerVo || [];
                    siOfferValue.value = siOfferData.value[0].siOfferId
                }
            }

            //处理切换siOffer
            const handlerToggleSiOffer = (siOfferId: string, make: string, byMonthStatus: boolean, kufriVersionn: string, siOffer: SiOfferCustomerVo): void => {
                isOverview.value = siOffer.viewTag
                budgetPeriod.value = siOffer.period
                siOfferValue.value = siOfferId;
                activeMakeValue.value = make;
                active.value = byMonthStatus
                if (siOffer.fleetCustomerList) {
                    kufriVersion.value = siOffer.fleetCustomerList[0].kufriVersion
                } else {
                    kufriVersion.value = kufriVersionn
                }
                if (siOfferId !== undefined) fetchLastVersion(activeMakeValue.value);
                const siOfferList = budgetInfoData.offerType.filter((item) => item.offerTypeId === offerTypeValue.value)[0].siOfferCustomerVo;
                // 如果有fleetCustomer,fleetCustomerList的第一个，没有才取siOfferId 的第一个的budgetId
                // 给customer下拉赋值
                const CustomerList = siOfferList.find((item) => item.siOfferId === siOfferValue.value)
                if (CustomerList && CustomerList.fleetCustomerList) {
                    fleetCustomerList.value = CustomerList.fleetCustomerList
                    // 默认取第一个
                    customerValue.value = fleetCustomerList.value[0].budgetId
                }
            }

            // 切换customer事件
            const changeCustomer = () => {
                fetchBudgetTableData(customerValue.value);
                // fleet的kufriVersion放到了fleetCustomerList下面
                const objCustomer = fleetCustomerList.value.find((item: CustomerProps) => item.budgetId === customerValue.value)
                if (objCustomer !== undefined && Object.keys(objCustomer).length > 0 && ('kufriVersion' in objCustomer)) {
                    kufriVersion.value = objCustomer.kufriVersion
                }
            }

            //根据offerTypeID 获取对应的siOffer
            watch(offerTypeValue, (value) => {
                const siOfferList = budgetInfoData.offerType.filter((item) => {
                    return item.offerTypeId == value;
                })[0].siOfferCustomerVo;
                // siOffer
                siOfferData.value = siOfferList;
                siOfferValue.value = siOfferList[0].siOfferId;
                // 如果有fleetCustomer,fleetCustomerList的第一个，没有才取siOfferId 的第一个的budgetId
                // 给customer下拉赋值
                if (siOfferList[0].fleetCustomerList) {
                    fleetCustomerList.value = siOfferList[0].fleetCustomerList
                    // 默认取第一个
                    customerValue.value = fleetCustomerList.value[0].budgetId
                }
                
                activeMakeValue.value = siOfferList[0].make;
                active.value = siOfferList[0].byMonthStatus;
                if (siOfferList[0].fleetCustomerList && siOfferList[0].fleetCustomerList.length > 0) {
                    kufriVersion.value = siOfferList[0].fleetCustomerList[0].kufriVersion
                } else {
                    kufriVersion.value = siOfferList[0].kufriVersion as string
                }
                fetchLastVersion(activeMakeValue.value);
            })

            //根据offerTypeID 获取对应的siOffer
            watch(siOfferValue, (value) => {
                fetchBudgetTableData(getBudgetId(value));
                if (value !== undefined) fetchBudgetKufriData()
                /* if (value !== undefined) {
                    fetchBudgetKufriData();
                } else {
                    findbudgetDataOverview(route.query.pgmId, offerTypeValue.value)
                } */
                
                //切换sioffer 清空spread js 事件
                if (worksheet) {
                    worksheet.unbindAll();
                }
                if (worksheetSmall) {
                    worksheetSmall.unbindAll();
                }
            })

            //处理侧边栏变化后刷新spread js
            watch(() => state.sideNavCollapsed, (value) => {
                nextTick(() => {
                    workbook.refresh();
                })
            })
            watch(() => activeMakeValue.value, (value) => {
                nextTick(() => {
                    workbookSmall.refresh()
                })
            })
            onMounted(() => {
                fetchBudGetInfo();
                ssHotSmallInit()
            });

            onBeforeUnmount(() => {
                workbook.destroy();
                workbookSmall.destroy()
            })
            const handleReturn = () => {
                router.push({
                    path: '/wordingbudget'
                })
            }
            return {
                //是否展示 弹窗
                visible,
                active,

                //budget data
                ...toRefs(budgetInfoData),
                siOfferData,
                ...toRefs(budgetKufriData),

                //当前的value
                offerTypeValue,
                siOfferValue,
                activeMakeValue,
                kufriVersion,

                //当前的tab
                currentTab,

                budgetTableDataH,
                budgetAllocationTableDataH,
                budgetTableData,

                //事件处理
                handlerCopyKufriClick,
                handlerSaveClick,
                handleSetVolumeBaseClick,
                handlerOk,
                handlerCheckboxChange,
                handleRefresh,
                handlerToggleOfferType,
                handlerToggleSiOffer,

                //是否是最新的version
                latestVersion,

                //转换千分数
                toThousands,

                // customer
                customerValue,
                fleetCustomerList,
                // 特殊offerType的处理
                visibleOfferList,
                isVisibleSpecialIndex,
                changeCustomer,
                isOverview,
                handleReturn,
                budgetPeriod
            }
        }
    })
