
    import { defineComponent, ref, onMounted } from 'vue'
    import { useStore } from 'vuex';
    interface Route {
        path: string;
        breadcrumbName: string;
        children?: Array<{
            path: string;
            breadcrumbName: string;
        }>;
    }

    export default defineComponent({
        setup() {
            const { commit } = useStore()
            const routes = ref<Route[]>([
                {
                    path: 'index',
                    breadcrumbName: 'home',
                },
                {
                    path: 'first',
                    breadcrumbName: 'first',
                    children: [
                        {
                            path: '/general',
                            breadcrumbName: 'General',
                        },
                        {
                            path: '/layout',
                            breadcrumbName: 'Layout',
                        },
                        {
                            path: '/navigation',
                            breadcrumbName: 'Navigation',
                        },
                    ],
                },
                {
                    path: 'second',
                    breadcrumbName: 'second',
                },
            ]);
            onMounted(() => {
                console.log(111)
                commit('updateSideNavKeys', {
                        openKeys: [],
                        selectedKeys: []
                })
            })
            return {
                routes,
            }
        }
    })
