/*
 * @Author: zhengda.lv
 * @Date: 2021-10-18 11:32:21
 * @LastEditTime: 2021-10-21 13:55:28
 * @LastEditors: zhengda.lv
 * @Description:
 */
import { RouteRecordRaw } from 'vue-router';
export const delayRouters: Array<RouteRecordRaw> = [
    //! 5030 5031 5032 Delay
    {
        path: '/claimDelay/dealerClaim',
        name: 'dealerClaim',
        component: () =>
            import('@/views/Delay/ClaimDelay/DealerClaim/index.vue'),
    },
    //! region的 Claim delay&delay callback
    {
        path: '/claimDelay/regionClaim',
        name: 'regionClaim',
        component: () =>
            import('@/views/Delay/ClaimDelay/RegionClaim/index.vue'),
    },
    //! region的 claim detail
    {
        path: '/claimDelay/regionClaimDetail',
        name: 'regionClaimDetail',
        component: () =>
            import('@/views/Delay/ClaimDetail/RegionClaimDetail/index.vue'),
    },
    //! sipo的 Claim delay&delay callback
    {
        path: '/claimDelay/SIPOClaim',
        name: 'sipoClaim',
        component: () => import('@/views/Delay/ClaimDelay/SIPOClaim/index.vue'),
    },
    //! sipo的 claim detail
    {
        path: '/claimDelay/SIPOClaimDetail',
        name: 'sipoClaimDetail',
        component: () =>
            import('@/views/Delay/ClaimDetail/SIPOClaimDetail/index.vue'),
    },
];
