
import { computed, defineComponent, ref, provide } from 'vue';
import { useStore } from 'vuex';
import Header from './components/Header.vue';
import SideNav from './components/SideNav.vue';
import * as echarts from 'echarts';

export default defineComponent({
  components: {
    Header,
    SideNav
  },
  setup() {
    provide('ec', echarts); //provide
    const store = useStore();
    const screenHeight = ref(window.screen.width);
    store.commit('updatedHeight', screenHeight.value);
    store.commit('updateIsPolling', true);
    const keepAliveComponentNames = computed(() => store.state.keepAliveComponentNames);
    return {
      keepAliveComponentNames
    };
  }
});
