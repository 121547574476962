import { RouteRecordRaw } from "vue-router";

export const dealerMasterDataRouters: Array<RouteRecordRaw> = [
    {
        path: "/dealerMasterData/region",
        name: "Dealer Region",
        component: () => import("@/views/DealerMasterData/Region/Region.vue"),
    },
    {
        path: "/dealerMasterData/dealer",
        name: "Dealer",
        component: () => import("@/views/DealerMasterData/Dealer/Dealer.vue"),
    },
    {
        path: "/dealerMasterData/:dealerId/edit",
        name: "Edit Dealer",
        component: () => import("@/views/DealerMasterData/Dealer/DealerEdit.vue"),
        props: true
    },
    {
        path: "/dealerMasterData/:dealerId/view",
        name: "View Dealer",
        component: () => import("@/views/DealerMasterData/Dealer/DealerView.vue"),
        props: true
    },
    // dealer block
    {
        path: "/dealerMasterData/dealerBlock",
        name: "Dealer Block",
        component: () => import("@/views/DealerMasterData/DealerBlock/DealerBlock.vue"),
    },
]
