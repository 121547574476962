
import { defineComponent, PropType, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { TreeProps } from "@/views/User/types";
import { useRoute } from "vue-router";
interface Breadcrumb {
  name: string;
  path: string;
}
export default defineComponent({
  name: "Breadcrumb",
  props: {
    dataSource: {
      type: Array as PropType<Breadcrumb[]>,
      required: true,
      default() {
        return [];
      },
    },
    className: {
      type: String,
    },
  },
  setup() {
    const { commit, state } = useStore();
    const route = useRoute();
    const crumbLists = ref<
      { mnunmcn: string; mnunmen: string; mnuuri: string }[]
    >([]);

    // 找到树形菜单，当前mnuid的，所有父级
    const familyTree = (arrTree: TreeProps[], id: string) => {
      const temp: TreeProps[] = [];
      const forFn = (arr: TreeProps[], id: string | undefined) => {
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];
          if (item.mnuid === id) {
            temp.push(item);
            forFn(arrTree, item.parentid); // 注意这里是传入的tree，不要写成data了，不然遍历的时候一直都是node.children,不是从最顶层开始遍历的
            break;
          } else {
            if (item.children) {
              forFn(item.children, id);
            }
          }
        }
      };
      forFn(arrTree, id);
      return temp;
    };

    // 找到对应需要的参数，菜单和面包屑需要要的
    const findURL = (arr: TreeProps[], url: string): any => {
      if (arr === null) return [];
      let openKey: string[] = [];
      const selectedKey: string[] = [];
      for (const obj of arr) {
        if (obj.mnuuri === url) {
          if (obj.parentid !== "0") {
            openKey = [
              ...familyTree(state.user.menulist, obj.mnuid).map(
                (item: TreeProps) => item.mnuid
              ),
            ];
          } else {
            openKey.push(obj.mnuid);
          }
          // 当前路由需要,当前菜单选中项
          selectedKey.push(obj.mnuid);
          // 当前页面时面包屑，当时的页面时
          // 更新vuex
          commit("updateSideNavKeys", {
            openKeys: openKey,
            selectedKeys: selectedKey,
          });
          return [obj];
        }
        const ret = findURL(obj.children, url);
        if (ret.length > 0) return ret;
      }
      return [];
    };

    onMounted(() => {
      // // 直接从复制URL的情况: 如果是菜单里有的路由，没有问题，没有的呢
      if (
        findURL(state.user.menulist, route.path).length < 1 &&
        state.sideNavKeys.selectedKeys.length < 1
      ) {
        commit("updateSideNavKeys", {
          openKeys: [],
          selectedKeys: [],
        });
      }
      // 针对从正常情况进来，菜单和导航
      crumbLists.value = familyTree(
        state.user.menulist,
        state.sideNavKeys.selectedKeys[0]
      ).reverse();
      if (route.fullPath.startsWith("/faq")) {
        crumbLists.value = [];
      }
    });

    return {
      crumbLists,
    };
  },
});
