import { RouteRecordRaw } from 'vue-router';
export const taskCenterRouters: Array<RouteRecordRaw> = [
    {
        path: '/taskCenter/taskJob',
        name: 'taskJob',
        component: () => import('@/views/TaskCenter/TaskJob/index.vue'),
    },
    {
        path: '/taskCenter/PackageTask',
        name: 'PackageTask',
        component: () => import('@/views/TaskCenter/PackageTask/index.vue'),
    },
    {
        path: '/batchOperation/DWHDataTransfer',
        name: 'DWH Data Transfer',
        component: () => import('@/views/TaskCenter/DWHDataTransfer/index.vue'),
    }
];
