
import { defineComponent, ref, onMounted, reactive, toRefs, h, nextTick } from 'vue'
import { siofferNameProps, } from "@/views/Budget/types"
import { getOfferFleetTable, getFleetTable, addOfferFleetTable, addFleetCustomerCategory, getCustomerCategoryData, addCustomerType, changeFleetStatus, changeFleetOfferStatus, editCustomerType } from '@/API/budget'
import { ValidProps } from '@/API/types'
import { useStore } from "vuex";
import blobDownload from '@/utils/blobDownload';
import { salesList } from "@/views/Budget/data";
import { incaentiveList } from './data'
import { message, Modal } from "ant-design-vue";
import deepClone from '@/utils/deepClone';
const columns = [
  {
    title: "Incentive Category",
    dataIndex: "incentiveCategoryName",
    key: "ientive",
    width: "20%",
  },
  {
    title: "Offer Type",
    dataIndex: "offerTypeName",
    key: "offertp",
    width: "30%",
    slots: { customRender: "offerTypeName" },
  },
  {
    dataIndex: "active",
    key: "active",
    width: "30%",
    slots: { title: 'customTitle' ,customRender: "active" },
  },
  {
    title: "Operation",
    dataIndex: "operation",
    key: "operation",
    slots: { customRender: "operation" },
  },
  
];
const fleetColumns = [
  {
    title: "Fleet Customer Type",
    dataIndex: "name",
    key: "ientive",
    width: "30%",
  },
  {
    title: "Fleet Customer Category",
    dataIndex: "fleetCategoryName",
    key: "ientive",
    width: "30%",
  },
  {
    dataIndex: "active",
    key: "active",
    width: "20%",
    slots: { title: 'customTitle' ,customRender: "active" },
  },
  {
    title: "Operation",
    dataIndex: "operation",
    key: "operation",
    slots: { customRender: "operation" },
  },
]
export default defineComponent({
    name: '',
    setup() {
        const activeText = ref('active')
        const activeFleeText = ref('active')
        const store = useStore()

        const visibleOffer = ref(false)
        const visibleFleetOffer = ref(false)
        const visibleStatus = ref(false)
        const visibleEdit = ref(false)
        
        // #region
        // 定义form
        const formData = reactive({
            labelCol: { span: 8 },
            wrapperCol: { span: 14 },
            other: '',
            form: {
                sales: 'Fleet Sales',
                salesid: 0,
                incentiveCategoryName: [],
                incentiveCategoryId: '',
                offerTypeName: '',
                offerTypeId: '',
                custormerType: '',
                custormerCategory: '',
                custormerCategoryItem: '',
                customerTypeValue: '',
                fleetCategoryName: '',
            },
            rules: {
              incentiveCategoryName: [
                {
                  required: true,
                  message: 'Please input name',
                  trigger: 'blur'
                },
              ],
              offerTypeName: [
                {
                  required: true,
                  message: 'Please input name',
                  trigger: 'blur'
                },
              ],
              custormerType: [
                {
                  required: true,
                  message: 'Please input name',
                  trigger: 'blur'
                },
              ],
              custormerCategory: [
                {
                  required: true,
                  message: 'Please input name',
                  trigger: 'blur'
                },
              ],
              custormerCategoryItem: [
                {
                  required: true,
                  message: 'Please input name',
                  trigger: 'blur'
                },
              ],
              customerTypeValue: [
                {
                  required: true,
                  message: 'Please input name',
                  trigger: 'blur'
                },
              ],
              fleetCategoryName: [
                {
                  required: true,
                  message: 'Please input name',
                  trigger: 'blur'
                },
              ],
            }
        })
        // #endregion
        // 添加Offer
        const handleAddOffer = () => {
          visibleOffer.value = true
        }

        // 获取form元素
        const getofferRef = ref()
        const offerRef = (el: HTMLElement | null) => {
            nextTick(() => {
              getofferRef.value = el
            })
        }

        const dataSource = ref()
        const fleetSource = ref()
        const deepCloneData: any[] = []
        const deepCloneFleetData: any[] = []

        // 过滤数据
        const filtersData = (dataSource: any, deepCloneData: any, attr: string, filters: string, activeText: any) => {
          dataSource.value = deepCloneData.filter((item: any) => {
            if (filters === 'active') {
              activeText.value = 'active'
              return item[attr] === true 
            }
            if (filters === 'inactive') {
              activeText.value = 'inactive'
              return item[attr] === false
            }
            if (filters === 'all' || !filters) {
              activeText.value = 'all'
              return item
            }
          })
        }

        const tableChange = (filters: string) => {
          filtersData(dataSource, deepCloneData, 'offerTypeActive', filters, activeText)
        }

        const tableFleetChange = (filters: string) => {
          filtersData(fleetSource, deepCloneFleetData, 'active', filters, activeFleeText)
        }

        // 获取fleet的数据
        const initTable = () => {
          getOfferFleetTable().then(res => {
            dataSource.value = res
            Object.assign(deepCloneData, res)
            tableChange('active')
          }) 
        }

        const initFleetTable = () => {
          getFleetTable().then(res => {
            fleetSource.value = res
            Object.assign(deepCloneFleetData, res)
            tableFleetChange('active')
          }) 
        }

        const custormerCategoryList = ref()
        const initCustormerCategoryData = () => {
          getCustomerCategoryData().then(res => {
            custormerCategoryList.value = res
          })
        }

        // 添加 offer type
        const createOffer = () => {
            const params= {
              incentiveCategoryId: formData.form.incentiveCategoryName,
              offerTypeName: formData.form.offerTypeName,
              offerTypeKind: 'Fleet'
            }
            getofferRef.value.validate().then(() => {
                addOfferFleetTable(params).then(() => {
                  initTable() 
                  getofferRef.value.resetFields();
                  visibleOffer.value = false 
                  message.success("Add Offer Type Successfully!")
                })
            })
            .catch((error: string) => {
              console.log('error', error);
            });
        }

        // 添加createCustormerType
        const createCustormerType = () => {
          const params = {
            customerCategory: 'Fleet',
            customerTypeName: [formData.form.custormerType],
            fleetCustomerCategory: formData.form.custormerCategory
          }
          getofferRef.value.validate().then(() => {
            addCustomerType(params).then(() => {
              initFleetTable()
              getofferRef.value.resetFields();
              message.success("Add Fleet Custormer Type Successfully!")
              visibleFleetOffer.value = false
            })
          })
        }

        // 添加。
        const confirmStaus = () => {
          const params = {
            name: formData.form.custormerCategoryItem,
          }
          getofferRef.value.validate().then(() => {
            addFleetCustomerCategory(params).then(res => {
              getofferRef.value.resetFields();
              visibleStatus.value = false
            })
          })
        }

        // 添加多个
        const fleetCustomerCategory = ref()
        const customerTypeArr = ref<{name: string}[]>([])
        const customerTypeId = ref()
        const editCustormerType = (record: any) => {
          visibleEdit.value = true
          formData.form.fleetCategoryName = record.fleetCategoryName
          customerTypeId.value = record.id
          formData.form.customerTypeValue = record.name
        }

        // 添加customer Type
        const handlerAddCustomerType = () => {
          customerTypeArr.value.push({
            name: ''
          })
        }

        // 创建多个
        const createMultipleCustormerType = () => {
          const categoryNameId = custormerCategoryList.value.find((item: any) => item.categoryName === formData.form.fleetCategoryName).categoryId
          const params = {
            customerCategory: 'Fleet',
            fleetCustomerCategory: categoryNameId,
            customerTypeName: formData.form.customerTypeValue,
            customerTypeId: customerTypeId.value,
          }
          getofferRef.value.validate().then(() => {
            editCustomerType(params).then(() => {
              initFleetTable()
              getofferRef.value.resetFields();
              message.success("Edit Fleet Custormer Type Successfully!")
              visibleEdit.value = false
            })
          })
        }

        // 关闭添加offer type 页面
        const cancelOffer = () => {
            visibleOffer.value = false
            visibleFleetOffer.value = false
            visibleEdit.value = false
            getofferRef.value.resetFields();
        }

        // 更改状态
        const handleChangeStaus = (record: any) => {
          Modal.confirm({
            class: 'my-confirm',
            content: h('div', {}, [
              h('i',{ 'class': 'font_family icon-old-Warning m-confirm'}, ''),
              h('h1',{ 'style': 'font-size: 18px; line-height: 70px'} , `Change status to ${record.active ? "Inactive" : "Active"} ?`),
            ]),
            cancelText: 'No',
            okText: 'Yes',
            onOk() {
              // inactive --》 active
              const params = { 
                active: !record.active,
                customerTypeId: [record.id]
               }
              changeFleetStatus(params).then(() => {
                message.success(`Successful`)
                initFleetTable()
              })
            },
            onCancel() {
              console.log("cancel");
            },
          });
        }

        // 更改offer type的状态
        const handleChangeOffer = (record: any) => {
          Modal.confirm({
            class: 'my-confirm',
            content: h('div', {}, [
              h('i',{ 'class': 'font_family icon-old-Warning m-confirm'}, ''),
              h('h1',{ 'style': 'font-size: 18px; line-height: 70px'} , `Change status to ${record.offerTypeActive ? "Inactive" : "Active"} ?`),
            ]),
            cancelText: 'No',
            okText: 'Yes',
            onOk() {
              // inactive --》 active
              const params = { 
                active: !record.offerTypeActive,
                offerTypeId: record.offerTypeId
               }
              changeFleetOfferStatus(params).then(() => {
                message.success(`Successful`)
                initTable()
              })
            },
            onCancel() {
              console.log("cancel");
            },
          });
        }

        // 导出fleet
        const expotToExcel = () => {
          const options = {
            url: '/pgapi/program/setting/export_program_type',
            params: {
              programTypeKind: 'Fleet'
            }
          }
          blobDownload(options)
        }

         const expotToExcelFleet = () => {
          const options = {
            url: '/pgapi/program/setting/export_customer_type',
            params: {
              customerCategory: 'Fleet'
            }
          }
          blobDownload(options)
        }

        onMounted(() => {
          // 设置表格的头部状态文字显示
          // if (sessionStorage.getItem('status')) activeText.value = sessionStorage.getItem('status') as string
          // 设置控制addoffer页面的操作
          store.commit('updateOperable',true)
          // 初始化数据;
          // initTableData()
          initTable()
          initFleetTable()
          initCustormerCategoryData()
        });
        return {
            columns,
            fleetColumns,
            dataSource,
            fleetSource,
            salesList,
            incaentiveList,
            activeText,
            activeFleeText,

            visibleOffer,
            visibleFleetOffer,
            visibleStatus,
            visibleEdit,

            custormerCategoryList,

            offerRef,
            cancelOffer,
            createOffer,
            confirmStaus,
            createCustormerType,
            editCustormerType,

            ...toRefs(formData),

            handleAddOffer,
            expotToExcel,
            expotToExcelFleet,
            tableChange,
            tableFleetChange,
            fleetCustomerCategory,

            handlerAddCustomerType,
            customerTypeArr,
            createMultipleCustormerType,
            handleChangeStaus,
            handleChangeOffer,
            initCustormerCategoryData,
            
        }

    }
 });
