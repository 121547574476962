
import { defineComponent, ref } from 'vue'
import { NavbarProps } from './types'
import Navbar from '@/views/Budget/ConfigurationNav.vue'
import { btnPermissions } from '@/utils'
export default defineComponent({
  components: { Navbar },
  name: '',
  setup() {
    const navArr = ref<NavbarProps[]>()
    navArr.value = []
    if (btnPermissions('OFFER TYPE')) {
      navArr.value.push({
        title: 'OFFER TYPE',
        path: '/offertype',
        id: '1',
      })
    }
    if (btnPermissions('BUDGET TEMPLATE')) {
      navArr.value.push({
        title: 'BUDGET TEMPLATE',
        path: '/budgutemplate',
        id: '2',
      })
    }
    return {
      navArr,
    }
  },
})
