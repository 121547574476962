import { onMounted, reactive, ref } from 'vue';

interface Pagination {
    pageSize: number;
    page: number;
    current?: number;
    total: number;
}

interface Params extends Partial<Pagination> {
    [key: string]: any;
}

/**
 * 字段不统一兼容很多格式，同一的数据格式请使用useFetchCommon
 * @param params
 * @param initData
 * @param fetchFuc
 * @param isPagination
 * @param callback
 */
function useFetch<T>(params: {}, initData: T, fetchFuc: Function, isPagination = false, callback?: Function) {
    const pagination = reactive<Pagination>({
        pageSize: 'pageSize' in params ? params['pageSize'] : 10,
        page: 1,
        total: 0,
    })
    const data = ref<T>(initData);
    const refreshFetch = (page?: number): void => {
        let _params: Params = {};
        if (isPagination) {
            _params = !page ? Object.assign({}, params, pagination) : Object.assign({}, params, pagination, { page: page });
            'total' in _params && delete _params.total;
        } else {
            _params = params;
        }
        fetchFuc({ params: _params }).then((res: any) => {
            callback && callback(res);
            //格式一
            if ('totalCount' in res && 'pageSize' in res) {
                Object.assign(pagination, { total: res.totalCount, pageSize: res.pageSize, page: res.pageAt || res.page });
            }
            //格式二
            if ('totalNum' in res && 'pageSize' in res) {
                Object.assign(pagination, { total: res.totalNum, pageSize: res.pageSize, page: res.pageAt });
            }
            //格式三
            if ('totalNum' in res && 'pageSize' in res) {
                Object.assign(pagination, { total: res.totalNum, pageSize: res.pageSize, page: res.page });
            }
            //处理返回的res.content 和 res.data
            if ('content' in res) {
                data.value = res.content;
            } else if ('data' in res) {
                data.value = res.data;
            } else {
                data.value = res;
            }

        })
    }

    onMounted(refreshFetch);

    return {
        pagination,
        data,
        refreshFetch
    };
}


export default useFetch;
