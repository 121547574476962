
import { defineComponent, ref, reactive, toRefs, onMounted, h } from "vue";
import { PageOptionProps, SearchParamsProps, LegalEntity } from './types'
import { Modal } from "ant-design-vue";
import store from "@/store";
import { addDataKey } from "@/utils";
import { PGFindall, PGchangestatus, getLegalEntityOptions } from '@/API/user'
import useSearch from '@/hooks/useSearch'
import useAddEdit from '@/hooks/useAddEdit'
import useTableHeight from "@/hooks/useTableHeight";
// 表格的列
const columns = [
  {
    title: "No.",
    dataIndex: "key",
    key: "key",
    width: '6%'
  },
  {
    // title: "Name",
    dataIndex: "grpnmcn",
    key: "grpnmcn",
    // 这里customRender: 'name',name和上面#name一致
    slots: { title: "customTitle", customRender: "name" },
    width: '25%'

  },
  {
    // title: "Type",
    dataIndex: "grptpnm",
    key: "grptpnm",
    slots: { title: "customType" },
    width: '15%'
  },
  {
    // title: "Description",
    dataIndex: "grpdsc",
    key: "grpdsc",
    slots: { title: "customDescription", customRender: "description" },
  },
  {
    // title: "Operation",
    key: "operation",
    slots: { title: "customOperation", customRender: "operation" },
    width: '25%'
  },
];


export default defineComponent({
  components: {},
  setup() {
    const legalEntityOptions = ref<LegalEntity[]>()
    const innerHeight = ref(window.innerHeight)
    // 分页配置
    const pageOptions = reactive<PageOptionProps>({
      pageSize: 0,
      current: 0,
      total: 1,
      pageSizeOptions: ['10', '20', '30', '40'],
      showSizeChanger: true,
      showQuickJumper: true,
    });
    const { tableHeight } = useTableHeight(true, '.ant-table-pagination');
    const {enName, cnName, userType, searchSubmit, privilegeGroup, searchChange, boolSearch} = useSearch(pageOptions, PGFindall, 'type')
    const getLegalEntity = () => {
      getLegalEntityOptions().then((res) => {
        legalEntityOptions.value = res
      })
    }
    
    const PGFindallFn = (params: SearchParamsProps, current: number,): void => {
      PGFindall({ params: params }).then((res): void => {
          privilegeGroup.value = addDataKey(res.content);
          pageOptions.pageSize = res.size;
          pageOptions.total = res.totalElements;
          pageOptions.current = current;
      })
    }
    // 表格
    // 表格请求数据, 初始表格数据
    const initTableData = (pageIndex = 0, pageSize = 10) => {
        const params = {
            pageIndex: pageIndex,
            pageSize
        };
        PGFindallFn(params, pageIndex + 1)
    };
    // 表格选择页码页面
    const changePage = (pagination: PageOptionProps) => {
        if (!userType.value && !enName.value && !cnName.value) {
            initTableData(pagination.current - 1, pagination.pageSize);
        } else {
            const params = {
                type: userType.value,
                name: store.state.lang === "en" ? enName.value.trim() : cnName.value.trim(),
                pageIndex: pagination.current - 1,
                pageSize: pagination.pageSize

            };
            PGFindallFn(params, pagination.current)
        }
    };

    const { handleEdit, handleCancel, submitAddPG, ruleFormRef, grptpChange, handleAddPG, visible, isAdd, modalTitle, formData} = useAddEdit()

    
    // 删除
    const delVisible = ref(false);
    const handleDisable = (id: number) => {
      Modal.confirm({
        class: 'my-confirm',
        content: h('div', {}, [
          h('i',{ 'class': 'font_family icon-shezhi confirm-h'}, ''),
          h('h1',{ 'style': 'font-size: 18px;'} , 'Are you sure to change the status of user group?'),
        ]),
        onOk() {
          return new Promise((resolve, reject) => {
            const params = { privGroupId: id };
            //接口
            PGchangestatus(params).then((res) => {
              let nowState = ''
              if (res.state) {
                nowState = 'true'
              } else {
                nowState = 'false'
              }
              sessionStorage.setItem("state", nowState)
              window.location.reload();
            })
            // console.log(resolve, reject)
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // 更新数据
          }).catch((e) => console.log(e));
        },
        onCancel() {
          console.log("cancel");
        },
      });
    };
    onMounted(() => {
      getLegalEntity()
      initTableData();
    });

    return {
      ruleFormRef,
      innerHeight,
      columns,
      // 表格数据
      privilegeGroup,
      pageOptions,
      changePage,
      // 添加
      handleAddPG,
      submitAddPG,
      handleCancel,
      grptpChange,
      isAdd,
      modalTitle,
      // 删除
      handleDisable,
      delVisible,
      enName,
      cnName,
      userType,
      visible,
      legalEntityOptions,
      ...toRefs(formData),
      searchSubmit,
      searchChange,
      boolSearch,
      handleEdit,
      tableHeight,
    };
  },
});
