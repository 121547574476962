
import { defineComponent, onMounted, ref, watch, reactive, toRefs, onBeforeUnmount, nextTick, computed } from 'vue';
import BudgetInputSet from './BudgetInputSet.vue';
import {
  BudgetTableProps,
  BudgetInfoProps,
  SiOfferCustomerVo,
  BudgetKufriProps,
  CustomerProps,
  SmallTableProps,
  BudgetTableDataCell
} from './types';
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css';
import GC from '@grapecity/spread-sheets';
import './licensen';
import { initOutlineColumn } from '../BudgetReport/utils';
import { budgetGenerate } from '@/API/program';
import {
  getBudgetInfo,
  getBudgetTableData,
  updateInputData,
  getBudgetKufriData,
  findbudgetDataOverview,
  getVersion,
  updateVersion,
  getFillInMonthlyData,
  getCancelFillInMonthlyData,
  fleetBudgetDataSummary
} from '@/API/budget';
import {
  handlerSheetsData,
  ssHotTop,
  bindDataTable,
  setHeader,
  inputEventToFormula,
  autoHeaderFitColumn,
  setHeaderCellTypeTip,
  ssHostSmall,
  isVisibleSpecialIndex
} from './utils';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import deepClone from '@/utils/deepClone';
import { toThousands } from '@/utils/approve';
import Navbar from '@/views/Program/components/NavigationBar.vue';
import useNavBar from '@/hooks/Program/useNavBar';
// 不同的offerType, inputMonth和按钮copy kufri，setVolume base显示否，都有brand,没有model year.只有overview会有customer
const visibleOfferList = [
  'BMBS Initiated Fleet',
  'BMBS Oversea Returnee',
  'BMBS Initiated Fleet - Special',
  'Dealer Initiated Fleet',
  'Dealer Initiated Fleet - Special',
  'Dealer service support'
];
const companyCar = ['Company Car'];
// PMT, Demo Car 是通用的，因判断了有没有Model Year
const tableHeaderListSummery = ['Period', 'Brand', 'Type Class', 'Model Year', 'Model'];
// fleet overview
const tableHeaderListSummery2 = ['Make', 'Customer', 'Brand', 'Type Class', 'Model Year', 'Model'];

import { message, Modal } from 'ant-design-vue';
// 只要在这个里面的，就是tableHeaderListSummery1， 在这个里面并且有overview的，就是tableHeaderListSummery2
// overview的小表格
import fixTable from '@/hooks/checking/useFixTable';
export default defineComponent({
  components: { BudgetInputSet, Navbar },
  setup() {
    const route = useRoute();
    const store = useStore();
    const currentProgramId = computed(() => store.state.pgmData.programId);
    const { state } = useStore();
    const visible = ref<boolean>(false);
    const active = ref<boolean>(false); // 标识Input By Month是否选中

    const { navArr, getTabStatus } = useNavBar();
    getTabStatus();

    const offerTypeValue = ref<string>('');
    const siOfferValue = ref<string>('');
    const currentTab = ref<string>('budget');

    const fleetCustomerList = ref<CustomerProps[]>([]);

    //是否是最新的version
    const latestVersion = ref<boolean | null>(null);
    // 是否是overview fleet

    const isOverview = ref<string>('');
    const budgetPeriod = ref<string>('');
    const spinFlag = ref<boolean>(false);
    const spinFlagSave = ref<boolean>(false);
    const tableHeight = fixTable(420);
    let oldTableData: string, newTableData: string;

    // sheet当前表格的初始化
    let worksheet: GC.Spread.Sheets.Worksheet;
    let workbook: GC.Spread.Sheets.Workbook;
    let worksheetSmall: GC.Spread.Sheets.Worksheet;
    let workbookSmall: GC.Spread.Sheets.Workbook;
    //budgetInfo
    const budgetInfoData = reactive<BudgetInfoProps>({
      offerType: [],
      programInfoVo: {
        programId: '',
        programCode: '',
        programName: '',
        status: '',
        kufriVersion: '',
        bu: ''
      }
    });

    const dataSmall = ref<SmallTableProps[]>([]);

    // 定义kufriVersion
    const kufriVersion = ref('');
    let latestKufriVersion = '';

    //siOffer
    const siOfferData = ref<SiOfferCustomerVo[]>([]);

    //budget table data
    const budgetTableData = reactive<BudgetTableProps>({
      header: [],
      inputTableCell: []
    });

    //当前的sioffer下make
    const activeMakeValue = ref<string>('');

    //budget kufri data
    const budgetKufriData = reactive<BudgetKufriProps>({
      cbuKufri: '',
      cbuBudget: '',
      pbpKufri: '',
      pbpBudget: '',
      vanKufri: '',
      vanBudget: '',
      denzaKufri: '',
      denzaBudget: ''
    });

    // customer选中值
    const customerValue = ref<string>('');

    const budgetTableDataH = ref<number>(400);

    const budgetAllocationTableDataH = ref<number>(100);

    // 根据不同的offerType不同，需要处理的header也不同
    const headerArr = (): string[] => {
      let arr: string[];
      if (isVisibleSpecialIndex(visibleOfferList, offerTypeValue.value, budgetInfoData.offerType) > -1) {
        arr = tableHeaderListSummery2;
      } else {
        arr = tableHeaderListSummery;
      }
      return arr;
    };
    const getAllDirtyCells = (data: BudgetTableDataCell[][]) => {
      const sels = worksheet.getSelections();
      const s = sels[0];
      let cells = '';
      for (let row = 0; row < data.length; row++) {
        for (let col = 0; col < data[row].length; col++) {
          /* worksheet.getDirtyCells获取脏数据，
                        返回结构：[{newValue: 0, oldValue: undefined, row:0, col:0}]
                        拼接成字符串，去判断前后是否相等
                         */
          // 处理有时为空的情况
          if (worksheet.getDirtyCells(row, col, s.rowCount, s.colCount).length) {
            cells += worksheet.getDirtyCells(row, col, s.rowCount, s.colCount)[0].newValue;
          }
        }
      }
      return cells;
    };

    // 初始第一个表格
    const ssHotTopInit = () => {
      const spread = new GC.Spread.Sheets.Workbook(document.getElementById('ssHost') as HTMLElement);
      const sheet = spread.getActiveSheet();
      worksheet = sheet;
      workbook = spread;
      ssHotTop(
        spread,
        budgetTableData,
        budgetTableData.inputTableCell,
        budgetTableData.header,
        false,
        true,
        budgetInfoData.programInfoVo.bu
      );
      inputEventToFormula(spread, budgetTableData, budgetTableData.header);
      autoHeaderFitColumn(spread, budgetTableData.header);
      if (budgetTableData.inputTableCell.length > 0 && isOverview.value === 'Overview') {
        initOutlineColumn(sheet);
      }
      // 初始拿到脏数据
      oldTableData = getAllDirtyCells(budgetTableData.inputTableCell);
    };

    const ssHotSmallInit = () => {
      const spreadSmall = new GC.Spread.Sheets.Workbook(document.getElementById('ssHostSmall') as HTMLElement);
      const sheetSmall = spreadSmall.getActiveSheet();
      worksheetSmall = sheetSmall;
      workbookSmall = spreadSmall;
      ssHostSmall(spreadSmall, sheetSmall, dataSmall.value);
    };
    //根据bu获取version最新版本
    const fetchLastVersion = (make: string) => {
      getVersion(make).then((data) => {
        latestKufriVersion = data;
        data == kufriVersion.value ? (latestVersion.value = true) : (latestVersion.value = false);
      });
    };

    //获取budgetInfo的信息
    const fetchBudGetInfo = (callback: any): void => {
      getBudgetInfo(route.query.pgmId).then((data) => {
        if (data?.offerType?.length) {
          Object.assign(budgetInfoData, data);
          offerTypeValue.value = data.offerType[0].offerTypeId;
          callback && callback();
        } else {
          spinFlagSave.value = false;
        }
      });
    };
    const getBudgetId = (siofferId: string): string => {
      const siOfferList = budgetInfoData.offerType.filter((item) => {
        return item.offerTypeId == offerTypeValue.value;
      });
      const budgetIdList = siOfferList[0].siOfferCustomerVo.find((item) => {
        return item.siOfferId === siofferId;
      });
      let budgetId = '';
      if (budgetIdList) {
        if (budgetIdList.fleetCustomerList) {
          budgetId = customerValue.value;
        } else {
          budgetId = budgetIdList.budgetId as string;
        }
      }
      return budgetId;
    };
    //根据offerId获取budget table 主数据
    const fetchBudgetTableData = (budgetId: string): void => {
      spinFlagSave.value = true;
      if (workbook) {
        if (worksheet) {
          // worksheet.clearPendingChanges()
          worksheet.unbindAll();
        }
        workbook.unbindAll();
        workbook.destroy();
      }
      if (workbookSmall) {
        if (worksheetSmall) {
          // worksheetSmall.clearPendingChanges()
          worksheetSmall.unbindAll();
        }
        workbookSmall.unbindAll();
        workbookSmall.destroy();
      }
      if (budgetId === undefined) {
        findbudgetDataOverview(route.query.pgmId, offerTypeValue.value)
          .then((data) => {
            budgetTableDataH.value = (data.inputTableCell.length + 1) * 40;
            Object.assign(budgetTableData, handlerSheetsData(data, active.value, headerArr()), true);
            //如果初始化后直接调用bindDataTable 更新数据而不是初始化
            ssHotTopInit();
          })
          .catch(() => {
            spinFlagSave.value = false;
          });
        fleetBudgetDataSummary(route.query.pgmId, offerTypeValue.value).then((data) => {
          dataSmall.value = data;
          ssHotSmallInit();
          spinFlagSave.value = false;
        });
      } else {
        getBudgetTableData(budgetId)
          .then((data) => {
            if (data.inputTableCell.length < 1) {
              message.warning('No Data!');
              spinFlagSave.value = false;
              return;
            }
            budgetTableDataH.value = (data.inputTableCell.length + 1) * 40;
            Object.assign(budgetTableData, handlerSheetsData(data, active.value, headerArr()));
            //如果初始化后直接调用bindDataTable 更新数据而不是初始化
            ssHotTopInit();
            inputEventToFormula(workbook, budgetTableData, budgetTableData.header);
            spinFlagSave.value = false;
          })
          .catch(() => {
            spinFlagSave.value = false;
          });
      }
    };

    //获取kufri的数据
    const fetchBudgetKufriData = (): void => {
      getBudgetKufriData(getBudgetId(siOfferValue.value)).then((data) => {
        Object.assign(budgetKufriData, data);
      });
    };

    // copy kufri  需求 copy kufri字段的值到Pipeline Override
    const handlerCopyKufriClick = (sheet: GC.Spread.Sheets.Worksheet): void => {
      //汇总行的type
      const summaryTypes = ['TYPECLASSSUMMARY', 'MODELYEARSUMMARY'];
      //kufri Pipeline Override的索引
      const index: number[] = [];
      //根据header确认着两个字段的索引
      for (const item of budgetTableData.header) {
        if (item.name.indexOf('Kufri') > -1) {
          index.push(item.columnNumber);
        }
        if (item.name.indexOf('Pipeline Override') > -1) {
          index.push(item.columnNumber);
        }
      }

      for (let row = 0; row < budgetTableData.inputTableCell.length; row++) {
        //排除汇总行
        if (summaryTypes.includes(budgetTableData.inputTableCell[row][0].type)) {
          continue;
        }
        for (let cell = 0; cell < budgetTableData.inputTableCell[row].length; cell++) {
          //判断Pipeline Override 是 WRITE 在赋值
          if (budgetTableData.inputTableCell[row][index[1]].type === 'WRITE') {
            budgetTableData.inputTableCell[row][index[1]].contents =
              budgetTableData.inputTableCell[row][index[0]].contents;
          }
        }
      }
      bindDataTable(workbook, budgetTableData.inputTableCell);
      autoHeaderFitColumn(workbook, budgetTableData.header);
    };

    // 显示月份的具体信息
    const handlerCheckboxChange = (): void => {
      spinFlagSave.value = true;
      // 返回数据后
      if (active.value) {
        getFillInMonthlyData(getBudgetId(siOfferValue.value)).then((res) => {
          fetchBudGetInfo(() => {
            spinFlagSave.value = false;
          });
          Object.assign(budgetTableData, handlerSheetsData(res, active.value, headerArr()));
          if (workbook) {
            inputEventToFormula(workbook, budgetTableData, budgetTableData.header);
            setHeader(workbook, budgetTableData.header);
            bindDataTable(workbook, budgetTableData.inputTableCell);
            autoHeaderFitColumn(workbook, budgetTableData.header);
            setHeaderCellTypeTip(workbook, budgetTableData.header);
          }
        });
      } else {
        getCancelFillInMonthlyData(getBudgetId(siOfferValue.value)).then((res) => {
          fetchBudGetInfo(() => {
            spinFlagSave.value = false;
          });
          Object.assign(budgetTableData, handlerSheetsData(res, active.value, headerArr()));
          if (workbook) {
            inputEventToFormula(workbook, budgetTableData, budgetTableData.header);
            setHeader(workbook, budgetTableData.header);
            bindDataTable(workbook, budgetTableData.inputTableCell);
            autoHeaderFitColumn(workbook, budgetTableData.header);
            setHeaderCellTypeTip(workbook, budgetTableData.header);
          }
        });
      }
    };

    // 刷新数据
    const handleRefresh = () => {
      return new Promise((resolve, reject) => {
        if (latestVersion.value) {
          fetchBudgetKufriData();
          fetchBudgetTableData(getBudgetId(siOfferValue.value));
        }
        resolve(true);
      });
    };

    //处理保存
    const handlerSaveClick = () => {
      spinFlagSave.value = true;
      return new Promise((resolve, reject) => {
        const closeWindowEventHandler = (event: any) => {
          // Cancel the event as stated by the standard.
          event.preventDefault();

          // Chrome requires returnValue to be set.
          event.returnValue = "Budget not finish Calculating, Please don't leave or close current page.";
          return "Budget not finish Calculating, Please don't leave or close current page.";
        };
        window.addEventListener('beforeunload', closeWindowEventHandler);

        const _budgetTableData = deepClone(budgetTableData);
        _budgetTableData.inputTableCell.forEach((row) => {
          row.forEach((cell) => {
            delete cell.contentsType;
            delete cell.trigger;
            delete cell.visible;
            delete cell.align;
            delete cell.width;
          });
        });
        updateInputData(_budgetTableData)
          .then((res) => {
            window.removeEventListener('beforeunload', closeWindowEventHandler);

            handleRefresh().then(() => {
              resolve(res);
              spinFlagSave.value = false;
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    };

    //切换显示
    const handleSetVolumeBaseClick = (): void => {
      visible.value = !visible.value;
    };

    //处理切换Volume Base
    const handlerOk = (type: string): void => {
      spinFlagSave.value = true;
      //顺序是固定的 所以找到的索引肯定是Kufri  和 Pipeline Override
      const volumeBaseKeys: string[] = ['Kufri', 'Pipeline Override'];
      const volumeBaseIndex: number[] = [];
      for (let i = 0; i < budgetTableData.header.length; i++) {
        if (
          budgetTableData.header[i].name.indexOf('Kufri') > -1 ||
          budgetTableData.header[i].name.indexOf('Pipeline Override') > -1
        ) {
          volumeBaseIndex.push(i);
        }
      }
      const _budgetTableData = deepClone(budgetTableData);
      _budgetTableData.header[volumeBaseIndex[0]].name = `${type} ${volumeBaseKeys[0]}`;
      _budgetTableData.header[volumeBaseIndex[1]].name = `${type} ${volumeBaseKeys[1]}`;
      _budgetTableData.inputTableCell.forEach((row) => {
        row.forEach((cell) => {
          delete cell.contentsType;
          delete cell.trigger;
          delete cell.visible;
          delete cell.align;
          delete cell.width;
        });
      });

      updateInputData(_budgetTableData).then((data) => {
        handleRefresh().then((res) => {
          spinFlagSave.value = false;
        });
      });
    };

    const ToggleOfferType = (offerTypeId: string) => {
      offerTypeValue.value = offerTypeId;
      const activeOfferType = budgetInfoData.offerType.filter((item) => {
        item.offerTypeId == offerTypeId;
      });
      if (activeOfferType.length > 0) {
        siOfferData.value = activeOfferType[0].siOfferCustomerVo || [];
        siOfferValue.value = siOfferData.value[0].siOfferId;
      }
    };
    //处理切换offerType
    const handlerToggleOfferType = (offerTypeId: string): void => {
      // 拿到最新的脏数据，去对比是否相等，不相等就证明修改过，就提示用户是否保存
      newTableData = getAllDirtyCells(budgetTableData.inputTableCell);
      if (oldTableData !== newTableData && budgetInfoData.programInfoVo.status === 'Saved') {
        Modal.confirm({
          title: 'Note',
          content: 'Do you want to save?',
          onOk() {
            handlerSaveClick().then(() => {
              ToggleOfferType(offerTypeId);
            });
          },
          onCancel() {
            ToggleOfferType(offerTypeId);
          }
        });
      } else {
        ToggleOfferType(offerTypeId);
      }
    };
    const ToggleSiOffer = (
      siOfferId: string,
      make: string,
      byMonthStatus: boolean,
      kufriVersionn: string,
      siOffer: SiOfferCustomerVo
    ) => {
      isOverview.value = siOffer.viewTag;
      budgetPeriod.value = siOffer.period;
      siOfferValue.value = siOfferId;
      activeMakeValue.value = make;
      active.value = byMonthStatus;
      if (siOffer.fleetCustomerList) {
        kufriVersion.value = siOffer.fleetCustomerList[0].kufriVersion;
      } else {
        kufriVersion.value = kufriVersionn;
      }
      if (siOfferId !== undefined) fetchLastVersion(activeMakeValue.value);
      const siOfferList = budgetInfoData.offerType.filter((item) => item.offerTypeId === offerTypeValue.value)[0]
        .siOfferCustomerVo;
      // 如果有fleetCustomer,fleetCustomerList的第一个，没有才取siOfferId 的第一个的budgetId
      // 给customer下拉赋值
      const CustomerList = siOfferList.find((item) => item.siOfferId === siOfferValue.value);
      if (CustomerList && CustomerList.fleetCustomerList) {
        fleetCustomerList.value = CustomerList.fleetCustomerList;
        // 默认取第一个
        customerValue.value = fleetCustomerList.value[0].budgetId;
      }
    };

    //处理切换siOffer
    const handlerToggleSiOffer = (
      siOfferId: string,
      make: string,
      byMonthStatus: boolean,
      kufriVersionn: string,
      siOffer: SiOfferCustomerVo
    ): void => {
      // 拿到最新的脏数据，去对比是否相等，不相等就证明修改过，就提示用户是否保存
      newTableData = getAllDirtyCells(budgetTableData.inputTableCell);
      // 如果当时是overview也不需要判断，overview只是展示
      if (
        isOverview.value !== 'Overview' &&
        oldTableData !== newTableData &&
        budgetInfoData.programInfoVo.status === 'Saved'
      ) {
        Modal.confirm({
          title: 'Note',
          content: 'Do you want to save?',
          onOk() {
            handlerSaveClick().then(() => {
              ToggleSiOffer(siOfferId, make, byMonthStatus, kufriVersionn, siOffer);
            });
          },
          onCancel() {
            ToggleSiOffer(siOfferId, make, byMonthStatus, kufriVersionn, siOffer);
          }
        });
      } else {
        ToggleSiOffer(siOfferId, make, byMonthStatus, kufriVersionn, siOffer);
      }
    };

    // 切换customer事件
    const changeCustomer = () => {
      fetchBudgetTableData(customerValue.value);
      // fleet的kufriVersion放到了fleetCustomerList下面
      const objCustomer = fleetCustomerList.value.find((item: CustomerProps) => item.budgetId === customerValue.value);
      if (objCustomer !== undefined && Object.keys(objCustomer).length > 0 && 'kufriVersion' in objCustomer) {
        kufriVersion.value = objCustomer.kufriVersion;
      }
    };

    //根据offerTypeID 获取对应的siOffer
    watch(offerTypeValue, (value) => {
      if (value === '') return;
      const siOfferList = budgetInfoData.offerType.filter((item) => {
        return item.offerTypeId == value;
      })[0].siOfferCustomerVo;
      // siOffer
      siOfferData.value = siOfferList;
      siOfferValue.value = siOfferList[0].siOfferId;
      // 如果有fleetCustomer,fleetCustomerList的第一个，没有才取siOfferId 的第一个的budgetId
      // 给customer下拉赋值
      if (siOfferList[0].fleetCustomerList) {
        fleetCustomerList.value = siOfferList[0].fleetCustomerList;
        // 默认取第一个
        customerValue.value = fleetCustomerList.value[0].budgetId;
      }

      activeMakeValue.value = siOfferList[0].make;
      active.value = siOfferList[0].byMonthStatus;
      if (siOfferList[0].fleetCustomerList && siOfferList[0].fleetCustomerList.length > 0) {
        kufriVersion.value = siOfferList[0].fleetCustomerList[0].kufriVersion;
      } else {
        kufriVersion.value = siOfferList[0].kufriVersion as string;
      }
      fetchLastVersion(activeMakeValue.value);
    });

    //根据offerTypeID 获取对应的siOffer
    watch(siOfferValue, (value) => {
      if (value === '') return;
      // 切换input by month需要重新赋值
      active.value = (
        (
          budgetInfoData.offerType.find((item: any) => item.offerTypeId === offerTypeValue.value) as any
        ).siOfferCustomerVo.find((item: any) => item.siOfferId === value) as any
      ).byMonthStatus;
      fetchBudgetTableData(getBudgetId(value));
      if (value !== undefined) fetchBudgetKufriData();
      //切换sioffer 清空spread js 事件
    });

    //处理侧边栏变化后刷新spread js
    watch(
      () => state.sideNavCollapsed,
      (value) => {
        nextTick(() => {
          workbook.refresh();
        });
      }
    );
    watch(
      () => activeMakeValue.value,
      (value) => {
        nextTick(() => {
          workbookSmall.refresh();
        });
      }
    );

    const handleMounted = () => {
      spinFlagSave.value = true;
      fetchBudGetInfo(() => {
        spinFlagSave.value = false;
      });
      ssHotSmallInit();
    };

    onMounted(() => {
      handleMounted();
    });

    onBeforeUnmount(() => {
      workbook?.destroy();
      workbookSmall?.destroy();
    });

    const updateKufriVersion = () => {
      if (latestVersion.value) {
        return;
      }
      Modal.confirm({
        title: 'Confirm',
        content: 'Confirm to Update',
        onOk: async () => {
          await updateVersion(activeMakeValue.value, getBudgetId(siOfferValue.value));
          kufriVersion.value = latestKufriVersion;
          latestVersion.value = true;
          fetchBudgetKufriData();
          fetchBudgetTableData(getBudgetId(siOfferValue.value));
        }
      });
    };

    const handleRegenerate = () => {
      Modal.confirm({
        title: 'Are you sure you want to regenerate program budget?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          budgetGenerate(currentProgramId.value).then((res: any) => {
            offerTypeValue.value = '';
            siOfferValue.value = '';
            if (res.code === '0') {
              handleMounted();
              message.success('Regenerate successfully');
            }
          });
        }
      });
    };

    return {
      //是否展示 弹窗
      visible,
      active,

      //budget data
      ...toRefs(budgetInfoData),
      siOfferData,
      ...toRefs(budgetKufriData),

      //当前的value
      offerTypeValue,
      siOfferValue,
      activeMakeValue,
      kufriVersion,

      //当前的tab
      currentTab,

      budgetTableDataH,
      budgetAllocationTableDataH,
      budgetTableData,

      //事件处理
      handlerCopyKufriClick,
      handlerSaveClick,
      handleSetVolumeBaseClick,
      handlerOk,
      handlerCheckboxChange,
      handleRefresh,
      handlerToggleOfferType,
      handlerToggleSiOffer,

      //是否是最新的version
      latestVersion,

      //转换千分数
      toThousands,

      // customer
      customerValue,
      fleetCustomerList,
      // 特殊offerType的处理
      visibleOfferList,
      isVisibleSpecialIndex,
      changeCustomer,
      isOverview,
      budgetPeriod,
      spinFlag,
      tableHeight,
      spinFlagSave,
      updateKufriVersion,
      navArr,
      handleRegenerate
    };
  }
});
