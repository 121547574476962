
import { defineComponent, onMounted, ref } from 'vue'
import Navbar from '@/views/Budget/index.vue'
import { GetbudgetTemplate, getbudgetTemplateFleet } from '@/API/budget'
import { BudgetTemplateProps, overviewAttr, ShowBudgetTemplate } from '@/views/Budget/types'
import deepClone from '@/utils/deepClone'
import { othersData } from './data'
interface CloumnsProps {
    title: string;
    dataIndex: string;
    width?: string;
    slots?: { customRender: string };
}
export default defineComponent({
    components: {
        Navbar
    },
    name: 'BudgetTemplate',
    setup() {
        const activeKey = ref([1])
        const activeFleetKey = ref([1])
        const switchvalue = ref('a')
        const activeTabKey = ref('1')
        const pageChange = (page: number, pageSize: number) => {
            console.log(page,pageSize);
        }
        const sizeChange = (page: number, pageSize: number) => {
            console.log(page,pageSize,'----');
        }
        
        const tableData = ref<ShowBudgetTemplate[]>([])
        // 切换单选按钮
        const onChange = () => {
            console.log(switchvalue.value);
        }
        const columns = ref<CloumnsProps[]>([])
        const settingColumns = (data: BudgetTemplateProps) => {
            data.title.forEach((item, index) => {
                if (index === 0) {
                    columns.value.push({
                        title: item.name,
                        dataIndex: 'name',
                        width: '12%'
                    })
                } else {
                    columns.value.push({
                        title: item.name,
                        dataIndex: item.name,
                        slots: { customRender: 'content'}
                    })
                }
            })
        }
        const asTableData = (overview: overviewAttr[]) => {
            const updatedArr = deepClone(overview)
            updatedArr.forEach((item) => {
                const obj: ShowBudgetTemplate = {}
                for (const key in item) {
                    if (key === 'attribute') {
                        if (item[key].length > 0) {
                            item.attribute.forEach(attr => {
                                obj[attr.name] = attr.enabled
                            })
                        }
                    } else {
                        obj[key] = item[key]
                    } 
                }
               tableData.value.push(obj) 
               console.log(tableData.value);

            })
            
        }

        const formula = ref()
        onMounted(() => {
            GetbudgetTemplate().then(res => {
                formula.value = res.formula
                asTableData(res.overview)
                settingColumns(res)                
            }) 
        })

        
        return {
            activeTabKey,
            activeFleetKey,
            activeKey,
            tableData,
            formula,
            columns,
            switchvalue,
            pageChange,
            sizeChange,
            onChange,
            othersData,

        }

    }
 });
