
import { defineComponent, ref, onMounted } from 'vue'
import { layoutListProps } from './types'
import { stringToBoolean } from '@/utils/index'
import { BudgetFormLayout, ConfimFormLayout } from '@/API/budget'
import deepClone from '@/utils/deepClone';
import { useRoute } from 'vue-router'
import useEventbus from "@/hooks/claim/useEventbus"
export default defineComponent({
    setup () {
        const route = useRoute()
        const offerTypeId = route.params.id
        const layoutList = ref<layoutListProps>({form: [], removed:[]})
        // layoutList.value = layoutList
        const leftIcon = ref(false)
        const rightIcon = ref(false)
        let clickElement: string
        let currentElementIndex: number
        // 初始化数据 
        const initLayouData = () => {
            BudgetFormLayout(offerTypeId).then((res) => {
                layoutList.value = res
            })
        }
       
        // 点击右侧layout元素， isLeft: 是否是左侧，idLocal:是哪个元素
        const clickColumn = (e: MouseEvent, isLeft: string, idLocal: string) => {
            const target = e && (e.target as HTMLElement)
            clickElement = target.innerHTML
            // 得到当前的元素的index
            currentElementIndex = isLeft ? layoutList.value.form.findIndex(item => item.name === clickElement) : layoutList.value.removed.findIndex(item => item.name === clickElement)
            const query = document.querySelectorAll<HTMLElement>(idLocal)
            for (let i = 0; i < query.length; i++) {
                query[i].style.background = ''
            }
            if (target) {
                target.style.background = '#F8FAFD';
                // isLeft ? leftIcon.value = true : rightIcon.value = true
                if (isLeft) {
                  leftIcon.value = true
                  rightIcon.value = false
                } else {
                  leftIcon.value = false
                  rightIcon.value = true
                }
            }
            
        }
        // 点击右移，清掉左侧的，右侧增加
        // 点击右移
        const canMoveLeft = (e: MouseEvent, isLeft: boolean) => {
            // 没有移动的左侧的原始数据数组
            const rawArr = deepClone(isLeft ? layoutList.value.form : layoutList.value.removed)
            // 没有移动的左侧的原始数据对象，点击的当前对象
            const rawObj = rawArr.filter(item => item.name === clickElement)[0]
            // 没有移动的左侧的原始数据对象，点击的当前对象的索引
            // 把左侧的点击当前项目删除
            if (currentElementIndex !== -1) isLeft ? layoutList.value.form.splice(currentElementIndex, 1) : layoutList.value.removed.splice(currentElementIndex, 1)
            // 把左侧移动的数据加到右侧
            !isLeft ? layoutList.value.form.push(rawObj) : layoutList.value.removed.push(rawObj)
            // 把中间的按钮重置
            isLeft ? leftIcon.value = false : rightIcon.value = false
        }
        // 当前行上移,当前的元素已经是索引为0时，证明已经是第一个，不能再上移
        const upRemove = () => {
            if (currentElementIndex === 0) return
            // 得到删除的元素
            const removeObj = layoutList.value.form.splice(currentElementIndex, 1)
            // 把删除的元素插入到上一个索引的位置
            layoutList.value.form.splice(currentElementIndex - 1, 0, removeObj[0])
            // 更新移动的当前点击的index
            currentElementIndex = layoutList.value.form.findIndex(item => item.name === clickElement)
        }
        // 当前行下移,当前的元素已经是整个数组长度-1，证明已经是最后一个，不能再下移
        const downRemove = () => {
            if (currentElementIndex === layoutList.value.form.length - 1) return
            // 得到删除的元素
            const removeObj = layoutList.value.form.splice(currentElementIndex, 1)
            // 把删除的元素插入到上一个索引的位置
            layoutList.value.form.splice(currentElementIndex + 1, 0, removeObj[0])
            // 更新移动的当前点击的index
            currentElementIndex = layoutList.value.form.findIndex(item => item.name === clickElement)
        }
        const startRemove = () => {
            if (currentElementIndex === 0) return
            // 得到删除的元素
            const removeObj = layoutList.value.form.splice(currentElementIndex, 1)
            // 把删除的元素插入到上一个索引的位置
            layoutList.value.form.splice(0, 0, removeObj[0])
            // 更新移动的当前点击的index
            currentElementIndex = layoutList.value.form.findIndex(item => item.name === clickElement)
        }
        const endRemove = () => {
            const maxIndex = deepClone(layoutList.value.form).length - 1
            if (currentElementIndex === maxIndex) return
            // 得到删除的元素
            const removeObj = layoutList.value.form.splice(currentElementIndex, 1)
            // 把删除的元素插入到上一个索引的位置
            layoutList.value.form.splice(maxIndex, 0, removeObj[0])
            // 更新移动的当前点击的index
            currentElementIndex = layoutList.value.form.findIndex(item => item.name === clickElement)
        }

        // 确定layout
        const confimLayout = () => {
            ConfimFormLayout(layoutList.value, offerTypeId).then(() => {
                initLayouData()
            })
        }
        // 响应兄弟元素
        const { customOn } = useEventbus()
        customOn("updatelayout", () => {
            initLayouData()
        })

        onMounted(() => {
            initLayouData()
        })
        return {
            stringToBoolean,
            layoutList,
            clickColumn,
            leftIcon,
            rightIcon,
            canMoveLeft,
            upRemove,
            downRemove,
            startRemove,
            endRemove,
            confimLayout,
        }
    }
})
