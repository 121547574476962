import { fetchGet, fetchPost, fetchPut } from './index'
import { ResProps } from './types'
import {
    overviewProps,
    budgetDataProps,
    layoutListProps,
    independentProps,
    BudgetTemplateProps,
} from '@/views/Budget/types';
import {
    BudgetInfoProps,
    BudgetTableProps,
    BudgetKufriProps,
    SmallTableProps
} from '@/views/Budget/BudgetInput/types';
import { BudgetAllocationTableProps } from '@/views/Budget/BudgetAllocation/types';
import { QueryParams, BudgetReportProps } from '@/views/Budget/BudgetReport/types';
import { QueryParams as QueryParamsGuideline } from '@/views/Reporting/types';
import { GuideLineConfig, GuideLineData, GuideLineDataParam } from '@/views/Approve/ControllingRoleApprove/ProgramHome/types'


// 获取Overview全量--------------------
export const OverviewFindall = (): Promise<overviewProps[]> => {
    return fetchGet('/bgapi/system_setting/program_type')
}


// 获取全部 Offer type
export const settingGetOfferType = (): Promise<overviewProps[]> => {
    return fetchGet('/pgapi/program/setting/get_offer_type')
}

export const getProgramType = (): Promise<overviewProps[]> => {
    return fetchGet('/pgapi/program/setting/get_program_type')
}
// 检查是否重复
export const CheckExisted = (params = {}): Promise<boolean> => {
    return fetchPost(`/bgapi/system_setting/is_existing`, params)
    // return fetchPost(`/pgapi/program/setting/is_existing_program_type`, params)
}

// 添加Offer Type
export const AddOverview = (params = {}): Promise<overviewProps> => {
    return fetchPost('/pgapi/program/setting/add_program_type', params)
}

// 添加si offer
export const AddSioffer = (params = {}, offerTypeId: string): Promise<overviewProps> => {
    return fetchPost(`/bgapi/system_setting/program_type/${offerTypeId}/si_offer`, params)
}
export const systemSettingProgramType = (params = {}): Promise<overviewProps> => {
    return fetchPost('/bgapi/system_setting/program_type', params)
}

// 更改Offer status状态
export const UpdateOfferStatus = (params = {}, offerTypeId: string) => {
    return fetchPut(`/bgapi/system_setting/program_type/${offerTypeId}`, params)
}


// 更改si status状态
export const UpdateSiStatus = (params = {}, offerTypeId: string): Promise<overviewProps> => {
    return fetchPut(`/bgapi/system_setting/program_type/${offerTypeId}/si_offer`, params)
}

// 修改si offer
export const UpdateSiOffer = (params = {}, offerTypeId: string, siofferId: string): Promise<overviewProps> => {
    return fetchPut(`/bgapi/system_setting/program_type/${offerTypeId}/si_offer/${siofferId}`, params)
}

// 导出excel
export const ExportToexcel = (): Promise<ResProps> => {
    return fetchGet('/bgapi/system_setting/program_type/excel')
}
// ------------------------------

// budget template
// 获取全量
export const BudgettpFindall = (offerTypeId: string | string[]): Promise<budgetDataProps> => {
    return fetchGet(`/bgapi/system_setting/program_type/${offerTypeId}/budget_template`)
}

// 修改budget template
export const Savebudgettp = (params = {}, offerTypeId: string | string[]): Promise<budgetDataProps[]> => {
    return fetchPut(`/bgapi/system_setting/program_type/${offerTypeId}/budget_template`, params)
}

// 添加budget template的属性
export const AddbudgettpAttr = (params = {}, offerTypeId: string | string[]): Promise<independentProps> => {
    return fetchPost(`/bgapi/system_setting/program_type/${offerTypeId}/budget_template/attribute`, params)
}

// 快速添加设置
export const QuickReplace = (params = {}, offerTypeId: string | string[]): Promise<budgetDataProps[]> => {
    return fetchPost(`/bgapi/system_setting/program_type/${offerTypeId}/budget_template/replace`, params)
}

// 获取budget template layout全量
export const BudgetFormLayout = (offerTypeId: string | string[]): Promise<layoutListProps> => {
    return fetchGet(`/bgapi/system_setting/program_type/${offerTypeId}/budget_template/form`)
}

// 确认budget template layout

export const ConfimFormLayout = (params = {}, offerTypeId: string | string[]): Promise<layoutListProps> => {
    return fetchPut(`/bgapi/system_setting/program_type/${offerTypeId}/budget_template/form`, params)
}

// 获取budget template的数据
export const GetbudgetTemplate = (): Promise<BudgetTemplateProps> => {
    return fetchGet('/bgapi/system_setting/program_type/budget_template/overview')
}

export const getbudgetTemplateFleet = (): Promise<BudgetTemplateProps> => {
    return fetchGet('/bgapi/budget/fleet/overview')
}


//budget input 和 detail

//获取budget页面下所以的offerType
export const getBudgetInfo = (offerTypeID: string | (string | null)[] | null): Promise<BudgetInfoProps> => {
    return fetchGet(`/bgapi/budget/findbudget_info/${offerTypeID}`);
}

//获取budget input table data
export const getBudgetTableData = (siofferId: string | number): Promise<BudgetTableProps> => {
    return fetchGet(`/bgapi/budget/findbudget_data/${siofferId}`);
}
// 查询fleetBudget Overview汇总数据
export const findbudgetDataOverview = (programId:  string | (string | null)[] | null , offerTypeId: string | number): Promise<BudgetTableProps> => {
    return fetchGet(`/bgapi/budget/findbudget_data/${programId}/${offerTypeId}`);
}
// 查询fleetBudget Overview汇总数据
export const fleetBudgetDataSummary = (programId:  string | (string | null)[] | null , offerTypeId: string | number): Promise<SmallTableProps[]> => {
    return fetchGet(`/bgapi/budget/fleet_budget_data/summary/${programId}/${offerTypeId}`);
}

//更新budget input 区域的cell
export const updateInputData = (data: BudgetTableProps): Promise<{}> => {
    return fetchPost('/bgapi/budget/update_inputdata', data);
}

//获取budget header信息
export const getBudgetKufriData = (siofferId: string): Promise<BudgetKufriProps> => {
    return fetchPost(`/bgapi/budget/sum_kufri_budget/${siofferId}`);
}


//获取分摊budget input 数据
export const getBudgetAllocationData = (psiOfferCustomerId: string): Promise<BudgetAllocationTableProps> => {
    return fetchGet(`bgapi/allocation/${psiOfferCustomerId}`);
}

//获取最新的version
export const getVersion = (bu: string): Promise<string> => {
    return fetchGet(`/bgapi/budget/kufri_version_latest/${bu}`);
}

//更新version
export const updateVersion = (make: string, programId: string): Promise<string> => {
    return fetchPost(`/bgapi/budget/update_kufri_version/${make}/${programId}`);
}

//budget report
export const getBudgetReportData = (data: any): Promise<BudgetReportProps> => {
    return fetchPost(`/bgapi/budget/exportGuideline`, data);
}

//为了budgetReport 使用不关心结果，在budgetInput更新后调用
export const updateProgramView = (siOfferId: string): Promise<any> => {
    return fetchPut(`/bgapi/budget/update/program/view/${siOfferId}`);
}

//为了budgetReport 使用不关心结果，在budget 分摊后后调用
export const budgetExportView = (siOfferId: string): Promise<any> => {
    return fetchPut(`/bgapi/allocation/budget/export/view/${siOfferId}`);
}

// input by month
export const getFillInMonthlyData = (siOfferId: string): Promise<BudgetTableProps> => {
    return fetchGet(`/bgapi/budget/budget/fillInMonthly/${siOfferId}`);
}

export const getCancelFillInMonthlyData = (siOfferId: string): Promise<BudgetTableProps> => {
    return fetchGet(`/bgapi/budget/budget/cancelFillInMonthly/${siOfferId}`);
}

export const findbudgetExist = (id: string): Promise<boolean> => {
    return fetchGet(`/bgapi/budget/findbudget_exist/${id}`);
}
// Fleet
export const getOfferFleetTable = (): Promise<BudgetTableProps> => {
    return fetchGet(`/pgapi/program/setting/get_program_type?programTypeKind=Fleet`);
}

export const getFleetTable = (): Promise<BudgetTableProps> => {
    return fetchGet(`/pgapi/program/setting/get_customer_type?customerCategory=Fleet`);
}

// 添加offer type
export const addOfferFleetTable = (params = {}): Promise<BudgetTableProps> => {
    return fetchPost(`/pgapi/program/setting/add_program_type`, params);
}

// 添加Fleet Customer Category  
export const addFleetCustomerCategory = (params = {}): Promise<BudgetTableProps> => {
    return fetchPost(`/pgapi/program/setting/add_fleet_customer_category`, params);
}

// 获取fleet_customer_category
export const getCustomerCategoryData = (params = {}): Promise<BudgetTableProps> => {
    return fetchGet(`/pgapi/program/setting/get_fleet_customer_category`, params);
}

// 添加fleet_customer_category
export const addCustomerType = (params = {}): Promise<BudgetTableProps> => {
    return fetchPost(`/pgapi/program/setting/add_customer_type`, params);
}

export const editCustomerType = (params = {}): Promise<BudgetTableProps> => {
    return fetchPost(`/pgapi/program/setting/edit_customer_type`, params);
}

// fleet Configuration
export const getFleetByOffetId = (offerId: string): Promise<BudgetTableProps> => {
    return fetchGet(`/bgapi/budget/fleet/${offerId}`);
}

// 更改状态
export const changeFleetStatus = (params = {}): Promise<BudgetTableProps> => {
    return fetchPost(`/pgapi/program/setting/active_customer_type`, params);
}

// 更改状态Offer type

export const changeFleetOfferStatus = (params = {}): Promise<BudgetTableProps> => {
    return fetchPost(`/pgapi/program/setting/active_program_type`, params);
}
// 添加多个customer
export const changeFleetStatus1 = (params = {}): Promise<BudgetTableProps> => {
    return fetchPost(`/pgapi/program/setting/active_customer_type`, params);
}

export const getGuideLineConfig = (): Promise<Record<string, GuideLineConfig>> => {
    return fetchGet('/bgapi/budget/guideLine/config')
}

export const saveGuideLineConfig = (config: GuideLineConfig) => {
    return fetchPost('/bgapi/budget/guideLine/config/save', config)
}

export const getGuideLineTodoCount = (): Promise<number> => {
    return fetchGet('/bgapi/budget/guideLine/data/todo/counts')
}

export const getGuideLineDataList = (make: string, buId: string, brandList: string[], typeClassList: string[]): Promise<GuideLineData[]> => {
  return fetchGet('/bgapi/budget/guideLine/data/list', {
    params: {
      make,
      buId,
      brandList,
      typeClassList
    }
  })
}

export const saveGuideLineData = (params: GuideLineDataParam[]) => {
  return fetchPost('/bgapi/budget/guideLine/data/save', params)
}